import dayjs from "dayjs";
import React, { useState } from "react";
import { Select, SelectItem, Input, Image } from "@nextui-org/react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EyeIcon from '../../assets/icons/Eye'
import "./profile.css";
import Countries from "../../utils/countries";
import UploadIcon from "../../assets/icons/UploadIcon";
import { deleteRecord, getMyRecords, updateUserData, uploadMyRecord } from "../../utils/axios";
import { fileNameSeprator } from "../../components/Booking/Step3";
import { toast } from "react-toastify";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function EditDoctorProfile({ genders = [], data = {}, states = [], countries = [] }) {
  const [disabledEdit, setDisableEdit] = React.useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = React.useState(null)
  const [patientData, setPatientData] = React.useState(data || {})
  const [displayImage, setDisplayImage] = React.useState(null)
  const [documents, setDocuments] = React.useState([])
  const [dragging, setDragging] = useState(false);
  const inputRef = React.useRef(null)
  const [openAlert, setOpenAlert] = React.useState(false);
  const [certId, setCertId] = React.useState(false);

  const handleAlertOpen = (id) => {
    setCertId(id)
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', e.target.id);
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const onFieldChange = (event, type, name) => {
    console.log(event.currentKey)
    if (type === 'select') {
      setPatientData({ ...patientData, [name]: event.currentKey })
    } else if (type === 'date') {
      setPatientData({ ...patientData, [name]: event.format("YYYY-MM-DD") })
    } else {
      const { target } = event
      setPatientData({ ...patientData, [target.name]: target.value })
    }
  }
  const handleDrop = (e) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text/plain');
    const draggedElement = document.getElementById(data);
    e.target.appendChild(draggedElement);
    setDragging(false);
  };

  const fetchData = async () => {
    const res = await getMyRecords()
    if (res) {
      setDocuments(res)
    } else {
      setDocuments([])
    }
  }

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  const imageConversion = (input) => {

    if (input) {
      const reader = new FileReader();

      reader.onload = function (e) {
        // Create a new image element
        const blob = dataURLtoBlob(e.target.result);

        // Create a blob:http URL
        const blobUrl = URL.createObjectURL(blob);
        setPatientData({ ...patientData, display_image_url: blobUrl });
        // Set the blob URL as the source for the preview image
      }
      // Read the selected file as a data URL
      reader.readAsDataURL(input);
    }
  }

  const deleteDoc = async () => {
    if (!certId) return
    try {
      const res = await deleteRecord(certId)
      if (res) {
        toast.success("Document Deleted!");
        window.location.reload();
      } else {
        toast.error("Error while deleteing document!");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message || "Error while deleteing document!");
      setOpenAlert(false)
    }

  }

  const uploadDocHandler = async () => {
    if (!selected?.name) {
      toast.error("No File selected!")
      return
    }
    const { ext, fileName } = fileNameSeprator(selected.name)
    const response = await uploadMyRecord({ name: fileName + "." + ext, file: selected })
    if (response) {
      toast.success("Document has been Uploaded!")
    } else {
      toast.error("Error while uploading document!")
    }
    setSelected(null)
    fetchData()
    onClose()
  }

  const updateRecord = async () => {
    const formData = new FormData()
    formData.append('first_name', patientData.first_name)
    formData.append('last_name', patientData.last_name)
    formData.append('gender', patientData.gender)
    formData.append('date_of_birth', patientData.date_of_birth)
    formData.append('country', patientData.country)
    formData.append('street_address', patientData.street_address)
    formData.append('zip_code', patientData.zip_code)
    formData.append('city', patientData.city)
    formData.append('location_id', patientData.location_id)
    if (displayImage) {
      formData.append("display_image", displayImage);
    }
    const response = await updateUserData(formData)
    if (response) {
      toast.success("Data has been Updated!")
    } else {
      toast.error("Error while updating data!")
    }
    setDisplayImage(null)
    fetchData()
  }

  React.useEffect(() => {

    fetchData()
  }, [])

  return (
    <div className="grid grid-cols-12 profile">
      <div
        style={{ padding: "25px" }}
        className="col-span-12 xl:col-span-2 flex xl:flex-col flex-row gap-4 items-center justify-center xl:justify-start"
      >
        <div className={'image-upload-container relative w-full'}>
          {!disabledEdit &&
            <div onClick={() => inputRef.current.click()} className={'upload-dp w-full h-full z-50 absolute top-0 left-0 glass-effect flex justify-center items-center cursor-pointer'}>
              <input ref={inputRef} className={'hidden'} type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => {
                if (e.target.files) {
                  const file = e.target.files[0]
                  imageConversion(file)
                  setDisplayImage(file)
                }
              }} />
              <CloudUploadIcon color={'white'} />
            </div>
          }
          <Image
            width={200}
            src={patientData.display_image_url}
            alt="NextUI Album Cover"
          />
        </div>
        {disabledEdit ?
          <button
            onClick={() => setDisableEdit(false)}
            className="bg-primary w-fit px-8 mx-4 text-xl py-4 text-white rounded-xl"
          >
            Edit
          </button>
          :
          <button
            onClick={() => {
              setDisableEdit(true);
              updateRecord()
            }}
            className="bg-primary px-8 mx-4 w-full text-xl py-4 text-white rounded-xl"
          >
            Save
          </button>
        }
      </div>
      <div className="col-span-12 sm:col-span-6 xl:col-span-5 flex flex-col" style={{ padding: "25px" }}>
        <div className="basic-info">
          <div
            style={{
              color: "#0A4E54",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
            className="mb-8"
          >
            Basic Information
          </div>
          <div className="mt-4 grid grid-cols-2 gap-2">
            <div className="col-span-1">
              <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>First Name</label>
              <Input
                type="text"
                id="first_name"
                name="first_name"
                placeholder="First Name *"
                variant="bordered"
                isDisabled={disabledEdit}
                value={patientData.first_name}
                onInput={onFieldChange}
                className="w-full text-base"
              />
            </div>
            <div className="col-span-1">
              <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Last Name</label>
              <Input
                type="text"
                id="last_name"
                name="last_name"
                placeholder="Last Name *"
                variant="bordered"
                isDisabled={disabledEdit}
                value={patientData.last_name}
                onInput={onFieldChange}
                className="w-full text-base"
              />
            </div>
            <div className="col-span-1">
              <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Gender</label>
              <Select
                variant="bordered"
                id="gender"
                name="gender"
                placeholder="Select Gender"
                isDisabled={disabledEdit}
                selectedKeys={[patientData.gender]}
                onSelectionChange={(event) => onFieldChange(event, 'select', 'gender')}
                className="max-w-xs select capitalize"
              >
                {Object.keys(genders).map((gender) => (
                  <SelectItem className={'capitalize'} key={gender} value={gender}>
                    {gender}
                  </SelectItem>
                ))}
              </Select>
            </div>
            <div className="col-span-1">
              <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Date Of Birth</label>
              <LocalizationProvider className="p-0" dateAdapter={AdapterDayjs}>
                <DemoContainer className="p-0" components={["DatePicker"]}>
                  <DatePicker
                    sx={{
                      input: {
                        color: "!formik.values.date_of_birth"
                          ? "#5E5E5E"
                          : "var(--bg-secondary)",
                      },
                      fieldset: {
                        borderColor: "var(--primary) !important",
                      },
                    }}
                    value={dayjs(patientData.date_of_birth)}
                    onChange={(event) => {
                      onFieldChange(event, 'date', 'date_of_birth')
                    }}
                    disabled={disabledEdit}
                    error={false}
                    name="date_of_birth"
                    id="date_of_birth"
                    maxDate={dayjs().subtract(18, "year")}
                    className="w-full"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="basic-info">
          <div
            style={{
              color: "#0A4E54",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "normal",
            }}
            className="mb-[6px]"
          >
            Location
          </div>
          <div className="mt-4 grid grid-cols-1 gap-2">
            <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
              htmlFor="country">Full Address</label>
            <div className="col-span-1">
              <Select
                variant="bordered"
                id="country"
                name="country"
                placeholder="Select Country"
                isDisabled={disabledEdit}
                selectedKeys={[patientData.country]}
                onSelectionChange={(event) => onFieldChange(event, "select", "country")}
                className="select"
              >
                {Countries.map((country) => (
                  <SelectItem key={country.alpha2Code} value={country.alpha2Code}>
                    {country.name}
                  </SelectItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-2">
            <div className={`${patientData?.country === 'US' ? 'col-span-1' : 'col-span-2'}`}>
              <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>
                {patientData?.country === "US" ? "Street Address" : "Address"}
              </label>
              <Input
                type="text"
                id="street_address"
                name="street_address"
                placeholder="Street Address *"
                variant="bordered"
                isDisabled={disabledEdit}
                value={patientData.street_address}
                onInput={onFieldChange}
                className="w-full text-base"
              />
            </div>
            {
              patientData?.country === "US" && (
                <>
                  <div className="col-span-1">
                    <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>City</label>
                    <Input
                      type="text"
                      id="city"
                      name="city"
                      placeholder="City *"
                      value={patientData.city}
                      onInput={onFieldChange}
                      variant="bordered"
                      isDisabled={disabledEdit}
                      className="w-full text-base"
                    />
                  </div>
                  <div className="col-span-1">
                    <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>State</label>
                    <Select
                      variant="bordered"
                      id="location_id"
                      name="location_id"
                      selectedKeys={[String(patientData.location_id)]}
                      onSelectionChange={(event) => onFieldChange(event, 'select', 'location_id')}
                      placeholder="Select State"
                      isDisabled={disabledEdit}
                      className="max-w-xs select"
                    >
                      {states.map((state) => (
                        <SelectItem key={state.id} value={state.id}>
                          {state.state}
                        </SelectItem>
                      ))}
                    </Select>
                  </div>
                  <div className="col-span-1">
                    <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Zip Code</label>
                    <Input
                      type="text"
                      id="zip_code"
                      name="zip_code"
                      placeholder="Zip Code *"
                      variant="bordered"
                      value={patientData.zip_code}
                      onInput={onFieldChange}
                      isDisabled={disabledEdit}
                      className="w-full text-base"
                    />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-6 xl:col-span-5" style={{ padding: "25px" }}>
        <div
          style={{
            color: "#0A4E54",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "normal",
          }}
          className="mb-8 flex justify-between items-center"
        >
          Uploaded Documents
          <button onClick={onOpen} className={'border border-secondary btn-round'} style={{ height: '30px', width: '30px', fontSize: '16px' }}>+</button>
        </div>
        <div className={'flex flex-col gap-1 overflow-x-hidden overflow-y-auto max-h-[500px]'}>
          {documents.length === 0 && (
            <div className={'w-full text-center font-semibold text-xl text-secondary'}>
              No File Uploaded
            </div>
          )}
          {documents.map((obj) => {
            return (
              <div key={obj.id + "_" + obj.name}>
                <span className={'text-[#707070] text-[12px] line-height-[18px]'}>{dayjs(obj.created_at).format('DD MMM YYYY     hh:mm a')}</span>
                <div className="mb-4 grid grid-cols-12 gap-2">
                  <div className="col-span-10">
                    <Input
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="Document Name *"
                      value={obj.name}
                      variant="bordered"
                      isDisabled={true}
                      className="w-full text-base"
                    />
                  </div>
                  <div className="col-span-2">
                    {disabledEdit ? (
                      <a
                        href={obj.file_url}
                        target={"_blank"}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "10px",
                          background: "#8181810D",
                          width: "50px",
                          height: "50px"
                        }}
                      >
                        <EyeIcon color={"black"} />
                      </a>
                    ) : (
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"
                        onClick={() => handleAlertOpen(obj.id)} className={'cursor-pointer'}>
                        <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                        <path
                          d="M23.4472 19.6951C23.6735 19.0549 24.284 18.5976 25 18.5976C25.716 18.5976 26.3266 19.0549 26.5528 19.6951C26.6538 19.9808 26.9673 20.1306 27.2531 20.0296C27.5389 19.9286 27.6886 19.6151 27.5877 19.3293C27.2112 18.2643 26.1956 17.5 25 17.5C23.8044 17.5 22.7888 18.2643 22.4124 19.3293C22.3114 19.6151 22.4611 19.9286 22.7469 20.0296C23.0327 20.1306 23.3462 19.9808 23.4472 19.6951Z"
                          fill="#EE2348" />
                        <path
                          d="M18.2324 20.9757C18.2324 20.6726 18.4781 20.4269 18.7812 20.4269H31.2203C31.5234 20.4269 31.7691 20.6726 31.7691 20.9757C31.7691 21.2787 31.5234 21.5244 31.2203 21.5244H18.7812C18.4781 21.5244 18.2324 21.2787 18.2324 20.9757Z"
                          fill="#EE2348" />
                        <path
                          d="M19.9635 22.2573C20.2659 22.2372 20.5274 22.466 20.5475 22.7684L20.8841 27.8165C20.9498 28.8028 20.9967 29.489 21.0995 30.0053C21.1993 30.5061 21.3386 30.7712 21.5386 30.9584C21.7387 31.1455 22.0125 31.2669 22.5188 31.3331C23.0408 31.4014 23.7286 31.4025 24.717 31.4025H25.2829C26.2713 31.4025 26.9591 31.4014 27.4811 31.3331C27.9875 31.2669 28.2613 31.1455 28.4613 30.9584C28.6614 30.7712 28.8006 30.5061 28.9004 30.0053C29.0032 29.489 29.0501 28.8028 29.1158 27.8165L29.4524 22.7684C29.4726 22.466 29.7341 22.2372 30.0365 22.2573C30.3389 22.2775 30.5677 22.539 30.5476 22.8414L30.2084 27.928C30.1459 28.8667 30.0953 29.6248 29.9768 30.2197C29.8536 30.8382 29.6441 31.3549 29.2112 31.7598C28.7783 32.1648 28.2488 32.3396 27.6235 32.4214C27.022 32.5 26.2622 32.5 25.3215 32.5H24.6785C23.7378 32.5 22.9779 32.5 22.3764 32.4214C21.7511 32.3396 21.2217 32.1648 20.7888 31.7598C20.3559 31.3549 20.1463 30.8382 20.0231 30.2197C19.9046 29.6248 19.8541 28.8667 19.7915 27.928L19.4524 22.8414C19.4322 22.539 19.6611 22.2775 19.9635 22.2573Z"
                          fill="#EE2348" />
                      </svg>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <Modal
        size={"lg"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Add Your Report</ModalHeader>
              <ModalBody>
                <div className={"relative"}>
                  <Input
                    type="text"
                    value={selected?.name}
                    disabled
                    placeholder="Upload Document *"
                    variant="bordered"
                    accept="application/pdf"
                    className="bg-primary-dark-1 w-full"
                  />
                  <UploadIcon
                    className="absolute cursor-pointer top-0 right-0 m-3"
                    onClick={() => {
                      const ele = document.getElementById("w9_tax_form");
                      if (ele) {
                        ele.click();
                      }
                    }}
                  />
                  <Input
                    type="file"
                    id="w9_tax_form"
                    name="w9_tax_form"
                    placeholder="w9_tax_form *"
                    variant="bordered"
                    onInput={async (event) => {
                      const fileInput = event.target;
                      setSelected(fileInput.files[0]);
                    }}
                    className="w-full text-base hidden"
                  />
                </div>
                <div className={"flex w-full justify-center"}>
                  <div style={{
                    borderRadius: "5px",
                    width: "380px",
                    height: "80px",
                    background: "#10C1D01A",
                    padding: "19px",
                    display: "flex",
                    gap: "10px"
                  }}>
                    <div className={"col-span-2"}>
                      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.4375 5L10.4375 12" stroke="#10C1D0" strokeWidth="1.4" strokeLinecap="round" />
                        <path d="M10.4375 14L10.4375 15" stroke="#10C1D0" strokeWidth="1.4" strokeLinecap="round" />
                        <path
                          d="M19.6346 10C19.6346 15.1432 15.5106 19.3 10.4388 19.3C5.36698 19.3 1.24297 15.1432 1.24297 10C1.24297 4.85682 5.36698 0.7 10.4388 0.7C15.5106 0.7 19.6346 4.85682 19.6346 10Z"
                          stroke="#10C1D0" strokeWidth="1.4" />
                      </svg>
                    </div>
                    <div className={'col-span-10'} style={{ fontWeight: 400, fontSize: '14px' }}>
                      Drop files here to Upload Allowed Type PDF Max file 50 MB,
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className={'flex justify-center'}>
                <button
                  onClick={uploadDocHandler}
                  className={"flex px-4 py-2 btn-round border border-secondary bg-secondary text-white"}>
                  Upload
                </button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAlertClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Deletion Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You are going to delete the document.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className={'px-4 py-2 border border-secondary btn-round text-secondary'} onClick={handleAlertClose}>Disagree</button>
          <button className={'px-4 py-2 bg-primary btn-round text-white'} onClick={deleteDoc}>Agree</button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
