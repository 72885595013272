import React from "react";

export default function TermsAndConditions() {
    return (
        <div className="flex flex-col items-center justify-center w-full h-screen">
            <iframe
                src="https://docs.google.com/document/d/e/2PACX-1vRn-YDNn01Dmjq_dsodvsAYzShZy-DMjnba3PgkwcscSEqad0XLIa0vX2PMUKVnvg/pub?embedded=true"
                className="flex justify-center w-full h-full"
                title="Terms and Conditions"
                allowFullScreen
                style={{ maxWidth: '800px' }}
            ></iframe>
        </div>
    );
}