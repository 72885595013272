import React, {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import {addPaymentInfo} from "../utils/axios";
import { toast } from 'react-toastify';

const AddCard = (props) => {
    const [stripe, setStripe] = useState(null);
    const [card, setCard] = useState(null);

    const addNewCard = async () => {
        const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
        const stripeInstance = await stripePromise;

        const elements = stripeInstance.elements({
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                },
            ]
        });

        const cardInstance = elements.create('card');
        cardInstance.mount('#card-element');

        setStripe(stripeInstance);
        setCard(cardInstance);
    };

    const addCardPayment = async () => {
        if (!stripe || !card) {
            console.error('Stripe or card object is not initialized');
            return;
        }
        
        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        });

        console.log(paymentMethod);
    
        if (error) {
            toast.error('Enter valid card details');
            console.error(error);
        } else {
     
            // if (paymentMethod.card && paymentMethod.card.checks && paymentMethod.card.checks['cvc_check'] === 'pass' && paymentMethod.card.checks['address_line1_check'] === 'pass') {
                const last_four = paymentMethod.card.last4;
                const expiry = `${paymentMethod.card.exp_year}/${paymentMethod.card.exp_month}`;
                const payment_token = paymentMethod.id;
                const category = paymentMethod.card.brand;
                await addPaymentInfo({ last_four, expiry, payment_token, category });
                toast.success('Card Data Saved.');
                props.onNewCardCreated();
            // } else {
            //     toast.error('Enter a valid card details'); // Display a generic error message
            // }
        }
    };

    useEffect(() => {
        addNewCard();
    }, []);

    return (
        <div className='flex container px-12 py-5 gap-2 items-center justify-between'>
            <div id="card-element" className='min-w-unit-96'>
                {/* Stripe Element will be mounted here */}
            </div>
            <button className='btn-round bg-secondary text-white px-2 py-1' onClick={addCardPayment}>Save</button>
        </div>
    );
}

export default AddCard;