import ReactOnRails from 'react-on-rails';
import HelloWorld from '../bundles/HelloWorld/components/HelloWorld';
import NavBar from '../src/components/Navbar'
import Login from '../src/views/Login'
import PhysicianRegistration from '../src/views/registration/physician/index'
import PatientRegistration from '../src/views/registration/patient/index'
import ResetPassword from '../src/views/ResetPassword'
import DoctorProfile from '../src/views/profile/Doctor'
import DoctorListing from "../src/views/DoctorListing"
import Dashboard from '../src/views/Dashboard'
import EditDoctor from '../src/views/profile/EditDoctor'
import PatientDashboard from '../src/views/PatientDashboard';
import EditPatient from '../src/views/profile/EditPatient'
import SettingPatient from '../src/views/Settings'
import Specialities from '../src/components/Specialities'
import ReviewsCardHome from '../src/components/ReviewsCardHome'
import FAQ from '../src/components/FAQ'
import LetUsHelpYou from '../src/components/LetUsHelpYou'
import ReviewRecord from '../src/views/doctor_app/ReviewRecord'
import ReviewRecordPatient from '../src/views/patient_app/ReviewRecord'
import SearchSpecialityDropDown from "../src/components/SearchSpeciality";
import HowItWorks from "../src/components/HowItWorks"
import TermsAndConditions from '../src/views/TermsAndConditions';
// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  HelloWorld,
  NavBar,
  Login,
  PhysicianRegistration,
  ResetPassword,
  DoctorProfile,
  DoctorListing,
  Dashboard,
  EditDoctor,
  PatientRegistration,
  PatientDashboard,
  EditPatient,
  SettingPatient,
  Specialities,
  ReviewsCardHome,
  LetUsHelpYou,
  FAQ,
  HowItWorks,
  ReviewRecord,
  ReviewRecordPatient,
  SearchSpecialityDropDown,
  TermsAndConditions
});
