import React from 'react';

function Star(props) {
    return (
      <svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
      <path d="M8.57341 1.39058C9.02244 0.00861216 10.9776 0.00861001 11.4266 1.39058L12.5819 4.9463C12.7827 5.56434 13.3587 5.98278 14.0085 5.98278H17.7472C19.2003 5.98278 19.8045 7.8422 18.6289 8.6963L15.6042 10.8939C15.0785 11.2758 14.8585 11.9529 15.0593 12.5709L16.2146 16.1266C16.6637 17.5086 15.0819 18.6578 13.9064 17.8037L10.8817 15.6061C10.3559 15.2242 9.64405 15.2242 9.11832 15.6061L6.09364 17.8037C4.91807 18.6578 3.33635 17.5086 3.78538 16.1266L4.9407 12.5709C5.14151 11.9529 4.92153 11.2758 4.3958 10.8939L1.37111 8.69631C0.195544 7.8422 0.799706 5.98278 2.25279 5.98278H5.9915C6.64134 5.98278 7.21728 5.56434 7.41809 4.9463L8.57341 1.39058Z" fill="#FFDD86"/>
    </svg>
    )
}

export default Star