import React, { useEffect } from 'react';
import ImageBG from '../../../assets/physician-registration-screen-image.png';
import UploadIcon from '../../../assets/icons/UploadIcon'
import { Button, Tabs, Tab } from '@nextui-org/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Basic from './basic';
import Speciality from './speciality';
import About from './about';
import Certification from './certificates';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import axios from 'axios';
import { enabledTabs } from './validations';

export default function PhysicianRegistration({ specialities = [], locations = [] }) {
  const [activeIndex, setActiveIndex] = React.useState('one');
  const [display_image, set_display_image] = React.useState(null)
  const [display_image_url, set_display_image_url] = React.useState(ImageBG)
  const [disabledKeys, setDisabledKeys] = React.useState(['two', 'three', 'four'])
  const inputFile = React.useRef(null)



  const handleMoveToHome = () => {
    window.location.href = "/";
  };

  function imageConversion(input) {

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function (e) {
        // Create a new image element
        const blob = dataURLtoBlob(e.target.result);

        // Create a blob:http URL
        const blobUrl = URL.createObjectURL(blob);
        set_display_image_url(blobUrl);
        // Set the blob URL as the source for the preview image
      }
      // Read the selected file as a data URL
      reader.readAsDataURL(input.files[0]);
    }
  }

  // Function to convert data URL to Blob
  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }
  const handleSelectionChange = (key) => {
    setActiveIndex(key);
  };

  const moveNextTab = () => {
    const activeTab = enabledTabs(formik, activeIndex)
    if (activeTab === "one") {
      setDisabledKeys(['two', 'three', 'four'])
    } else if (activeTab === "two") {
      setDisabledKeys(['three', 'four'])
    } else if (activeTab === "three") {
      setDisabledKeys(['four'])
    } else if (activeTab === "four") {
      setDisabledKeys([])
    }
    setActiveIndex(activeTab)
    //else if (activeIndex === 'two') {
    //   setActiveIndex('three');
    // } else if (activeIndex === 'three') {
    //   setActiveIndex('four');
    // }
  };

  const movePrevTab = () => {
    if (activeIndex === 'four') {
      setActiveIndex('three');
    } else if (activeIndex === 'three') {
      setActiveIndex('two');
    } else if (activeIndex === 'two') {
      setActiveIndex('one');
    }
  };

  const SignupSchema = Yup.object().shape({
    // experience: Yup.number().min(1, 'Experience Can not less than 1!'),
    // nin: Yup.string(),
    // contact_number: Yup.string(),
    first_name: Yup.string()
      .min(2, 'First name too short!')
      .max(50, 'First name too long!')
      .required('First name required'),
    last_name: Yup.string()
      .min(2, 'Last name too short!')
      .max(50, 'Last name too long!')
      .required('Last name required'),
    email: Yup.string().email('Invalid email').required('Email required'),
    date_of_birth: Yup.string().required('Date of birth required'),
    gender: Yup.string()
      .required('Gender is required')
      .oneOf(['male', 'female', 'other'], 'Invalid gender'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'At least one lowercase letter is required in password')
      .matches(/[A-Z]/, 'At least one uppercase letter is required in password')
      .matches(/\d/, 'At least one number is required in password')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'At least one special character is required in password'
      )
      .required('Password is required'),
    confirm_password: Yup.string()
      .min(8, 'Password must be at least 8 characters long in password')
      .matches(/[a-z]/, 'At least one lowercase letter is required in password')
      .matches(/[A-Z]/, 'At least one uppercase letter is required in password')
      .matches(/\d/, 'At least one number is required in password')
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        'At least one special character is required in password'
      )
      .required('Password is required'),
    // street_address: Yup.string()
    //   .min(6, 'Street address too short!'),
    // zip_code: Yup.string(),
    // city: Yup.string(),
    // location_id: Yup.string(),
    // specialities: Yup.array().of(
    //   Yup.object().shape({
    //     speciality_id: Yup.string(),
    //     location_id: Yup.string(),
    //     licence_number: Yup.string(),
    //     exp_date: Yup.string(),
    //   })
    // ),
    // board_certifications: Yup.array().of(
    //   Yup.object().shape({
    //     certification: Yup.string(),
    //     exp_date: Yup.date(),
    //   })
    // ),
    // bio: Yup.mixed(),
    // w9_tax_form: Yup.mixed(),
    // pli: Yup.mixed(),
    // about: Yup.string()
    //   .min(10, 'About text is too short!'),
    // agreement_accepted: Yup.boolean().oneOf(
    //   [true],
    //   'Agreement must be accepted.'
    // ),
  });

  const resetImage = () => {
    inputFile.current.value = ''
    set_display_image_url(ImageBG)
    set_display_image(null)
  }
  // Initializing Form Attributes
  const formik = useFormik({
    initialValues: {
      role: 'admin',
      category: 'doctor',
      first_name: '',
      last_name: '',
      gender: '',
      email: "",
      password: '',
      date_of_birth: '',
      confirm_password: '',
    },
    validateOnMount: true,
    validationSchema: SignupSchema,
    onSubmit: async (data) => {

      // if (!display_image) {
      //   toast.error("Display Image is required!")
      //   return
      // }
      if (display_image) {
        data.display_image = display_image
      }
      const response = await fetchData(data);
      if (response) {
        setActiveIndex('one')
        toast.success("Registration Successful! Please check your email for verification.");
        setTimeout(() => {
          window.location.href = "/login"
        }, 3000);
      }
    },
  });

  useEffect(() => {
    formik.validateForm().then(() => {
      console.log('form validation loaded succesfully!')
    });
  }, [])

  return (
    <div className='grid grid-cols-12 h-full'>
      <div className='col-span-12 md:col-span-6 flex justify-center lg:justify-start mt-8 md:12 xl:mt-20 p-8 sm:p-0'>
        <div className='relative flex flex-col justify-center items-center'>

          <img
            src={display_image_url}
            className='max-w-[20rem] sm:max-w-[32.25rem] max-h-[45.5rem] md:w-auto md:h-5/6 xl:h-auto xl:w-full'
            alt='physician login screen image'
          />

          {!display_image &&
            (
              <>
                <div onClick={() => {
                  inputFile.current.click()
                }} className='absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center gap-4 cursor-pointer'>
                  <div>
                    <UploadIcon />
                  </div>
                  <div>
                    Upload Your Profile Picture
                  </div>
                </div>
              </>
            )
          }
          {display_image?.name ?
            <div className='w-full flex justify-end mt-4'>
              <Button color='danger' className='cursor-pointer pl-4' onClick={resetImage}>Reset Image</Button>
            </div>
            : <></>}
          <input
            ref={inputFile}
            type='file'
            accept="image/png, image/gif, image/jpeg"
            onInput={(event) => {
              const fileInput = event.target;
              imageConversion(fileInput)
              set_display_image(fileInput.files?.[0]);
            }}
            className='w-full text-base hidden'
          />
        </div>

      </div>
      <div className='w-full col-span-12 md:col-span-6 grid grid-cols-6'>
        <div className='col-span-6 lg:col-span-5 mt-8 md:12 xl:mt-20 p-6 pt-0 flex flex-col justify-between'>
          <div>
            <h1 className='text-primary text-center text-4xl font-medium'>
              Physician Sign Up Form
            </h1>
            <p className='text-sm text-secondary font-normal text-center' style={{ paddingTop: '20px' }}>
              Please complete to create your account
            </p>
            <form
              onSubmit={formik.handleSubmit}
              className='registration-form mt-6'
            >
              <AnimatePresence mode='wait'>
                <motion.div
                  key={activeIndex ? activeIndex : 'empty'}
                  initial={{ y: 10, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -10, opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Basic key={activeIndex} formik={formik} />
                  {/* {activeIndex === 'one' && <Basic key={activeIndex} formik={formik} />}
                  {activeIndex === 'two' && <Speciality key={activeIndex} locations={locations} data={specialities} formik={formik} />}
                  {activeIndex === 'three' && (
                    <Certification formik={formik} key={activeIndex} />
                  )}
                  {activeIndex === 'four' && <About formik={formik} key={activeIndex} />} */}
                </motion.div>
              </AnimatePresence>
              <div className='flex justify-between mt-8 xl:mt-12'>

                {/* <button
                  type='button'
                  aria-label='back-button'
                  className='btn-round border border-gray-400 bg-primary-light text-gray-400 py-2 px-6 w-fit self-center hover:bg-primary hover:text-white'
                  onClick={activeIndex === 'one' ? handleMoveToHome : movePrevTab}
                >
                  {activeIndex === 'one' ? 'Cancel' : 'Back'}
                </button> */}
                {/* <button
                  type='submit'
                  className='cursor-pointer btn-round bg-white border-2 border-primary text-black py-2 px-6 w-fit self-center hover:bg-primary-light hover:text-gray-400'
                  // onClick={moveNextTab}
                >
                  Next
                </button> */}
                <input
                  type='button'
                  onClick={() => window.location.replace('/')}
                  className='cursor-pointer btn-round bg-white border-[1px] border-black text-black py-2 px-6 w-fit self-center hover:bg-primary-light hover:text-gray-400'
                  value='Cancel'
                />
                <input
                  type='submit'
                  className='cursor-pointer btn-round bg-primary border-2 border-primary text-white py-2 px-6 w-fit self-center hover:bg-primary-light hover:text-gray-400'
                  value='Submit'
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const fetchData = async (userData) => {
  try {
    // Create a FormData object
    const formData = new FormData()

    let stringifyUserData = {
      ...userData,
      // specialities_attributes: JSON.stringify(userData.specialities),
      // board_certifications_attributes: JSON.stringify(userData.board_certifications)
    };

    Object.keys(stringifyUserData).forEach((key) => {
      formData.append(key, stringifyUserData[key]);
    });

    const response = await axios({
      method: "post",
      url: "/physician",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })

    return response.data
    // Handle the data as needed
  } catch (error) {
    const msg = error?.response?.data?.errors?.[0];
    toast.error(msg || "Error while Sign-up!");
    return
  }
};
