import React, { useEffect, useState } from "react";
import Countries from "../../../utils/countries";
import UploadIcon from "../../../assets/icons/UploadIcon";
import { Select, SelectItem, Input } from "@nextui-org/react";
import { validator } from "./validations";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function Contact({ formik, genders = [], locations = [] }) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [state, setState] = useState(null);

  useEffect(() => {
    const country_code = formik.values.country;
    const selected = Countries.filter(
      (country) => country.alpha2Code === country_code,
    );
    setSelectedCountry(selected.length && selected[0]);
  }, [formik.values.country]);

  return (
    <>
      <div className="mt-8 text-sm">Address</div>
      <div className="mt-2 grid grid-cols-1">
        <div className="col-span-1">
          <Select
            variant="bordered"
            id="country"
            name="country"
            placeholder="Select Country"
            selectedKeys={formik.values.country ? [formik.values.country] : []}
            isInvalid={validator(formik, "country")}
                      onChange={formik.handleChange}
                      defaultSelectedKeys={[formik.values.country]}
            onBlur={formik.handleBlur}
            className="select"
          >
            {Countries.map((country) => (
              <SelectItem key={country.alpha2Code} value={country.alpha2Code}>
                {country.name}
              </SelectItem>
            ))}
          </Select>
          {validator(formik, "country") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.country}
            </div>
          )}
        </div>
      </div>
      {selectedCountry ? (
        <div
          className={`mt-4 grid gap-4 grid-cols-${selectedCountry.alpha2Code == "US" ? "4" : "1"}`}
        >
          <div className="col-span-1">
            <Input
              value={formik.values.street_address}
              type="text"
              id="street_address"
              name="street_address"
              placeholder="Street Address *"
              variant="bordered"
              isInvalid={validator(formik, "street_address")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full text-base"
              aria-label="Street Address"
            />
            {validator(formik, "street_address") && (
              <div className="w-full mt-1 text-sm text-danger">
                {formik.errors.street_address}
              </div>
            )}
          </div>
          {selectedCountry.alpha2Code == "US" && (
            <>
              <div className="col-span-1">
                <Input
                  value={formik.values.zip_code}
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  placeholder="Zip Code *"
                  variant="bordered"
                  aria-label="Zip Code"
                  isInvalid={validator(formik, "zip_code")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full"
                />
                {validator(formik, "zip_code") && (
                  <div className="w-full mt-1 text-sm text-danger">
                    {formik.errors.zip_code}
                  </div>
                )}
              </div>
              <div className="col-span-1" style={{fontSize: '11px'}} >
                <Select
                  variant="bordered"
                  id="location_id"
                  name="location_id"
                  placeholder="Select State"
                  value={formik.values.state}
                  isInvalid={validator(formik, "location_id")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="select"
                  aria-label="State"
                  style={{padding: '11px'}} 
                  // placeholderStyle={{ fontSize: '11px' }}
                >
                  {locations.map((object) => (
                    <SelectItem key={object.id} value={object.id}>
                      {object.state}
                    </SelectItem>
                  ))}
                </Select>
                {validator(formik, "location_id") && (
                  <div className="w-full mt-1 text-sm text-danger">
                    {formik.errors.location_id}
                  </div>
                )}
              </div>
              <div className="col-span-1">
                <Input
                  value={formik.values.city}
                  type="text"
                  id="city"
                  name="city"
                  aria-label="City"
                  placeholder="City *"
                  variant="bordered"
                  isInvalid={validator(formik, "city")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full"
                />
                {validator(formik, "city") && (
                  <div className="w-full mt-1 text-sm text-danger">
                    {formik.errors.city}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="mt-8 text-sm">Phone Number</div>
      <div className="grid grid-cols-1 mt-2">
        <div className="col-span-1">
                  <PhoneInput
                      className={validator(formik, "contact_number") && 'invalid-border'}
            inputProps={{
              id: "contact_number",
                          name: "contact_number",
              class: validator(formik, "contact_number") ? 'form-control !border-danger' : 'form-control',
              required: true,
              autoFocus: true,
            }}
            country={formik.values.country.toLowerCase() || "us"}
            value={formik.values.contact_number}
            onChange={(phone) => formik.setFieldValue("contact_number", phone)}
          />
          {validator(formik, "contact_number") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.contact_number}
            </div>
          )}
        </div>
      </div>
      <div className="mt-8 text-sm">Add Your Medical Record</div>
      <div className="grid mt-2 grid-cols-12 gap-4">
        <div className="col-span-12 relative">
          <div className="disabled-input">
            <Input
              value={formik.values.records?.name}
              type="text"
              disabled
              aria-label={"Document"}
              placeholder="Document Record *"
              variant="bordered"
              isInvalid={validator(formik, "records")}
              className="bg-primary-dark-1 w-full"
            />
            <UploadIcon
              className="absolute cursor-pointer top-0 right-0 m-3"
              onClick={() => {
                const ele = document.getElementById("records");
                if (ele) {
                  ele.click();
                }
              }}
            />
            <Input
              type="file"
              id="records"
              aria-label={"Record"}
              name="records"
              accept="application/pdf"
              placeholder="Document Record *"
              variant="bordered"
              isInvalid={validator(formik, "records")}
              onInput={(event) => {
                const fileInput = event.target;
                formik.setFieldValue("records", fileInput.files?.[0]);
              }}
              onBlur={formik.handleBlur}
              className="w-full text-base hidden"
            />
          </div>
        </div>
      </div>
    </>
  );
}
