/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import EventDetailCard from './EventDetail'
import dayjs from "dayjs";
import CardComponent from "./Card";
import PropTypes from "prop-types";
import "./index.css";
import { useSpring, animated } from "@react-spring/web";
import axios from 'axios'
import { getAppointments, getAppointmentsNotBooked } from "../utils/axios";
import ClockIcon from '../assets/clock.png';

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25.625rem",
  height: "37.5rem",
  flexShrink: 0,
  borderRadius: "0.625rem",
  padding: '0.88rem 1.12rem'
};

const WeekDates = ({ selectedDate, onWeekChange }) => {
  const [slotData, setSlotData] = useState([]);
  const [slots, setSlots] = useState([])
  const [currentEvent, setCurrentEvent] = useState({});
  const [currentWeek, setCurrentWeek] = useState(dayjs(selectedDate));
  const [open, setOpen] = React.useState(false);
  const [currentPosition, setCurrentPosition] = React.useState("0%")
  const [unbookedSlots, setUnbookedSlots] = React.useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const getAllSlots = () => axios.get('/slots').then((response) => {
    setSlots(response.data)
  }).catch((e) => {
  })

  const renderWeekDates = () => {
    const dates = [];
    const middleDateIndex = 2; // Index for the middle date

    for (let i = 0; i < 7; i++) {
      const date = currentWeek
        .add(i - middleDateIndex, "day")
        .format("YYYY-MM-DD");
      dates.push(date);
    }

    return dates;
  };

  const weekChangeHandler = () => {
    if (onWeekChange) onWeekChange(currentWeek);
  };

  const handlePreviousWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek.subtract(1, "week"));
  };

  const handleNextWeek = () => {
    setCurrentWeek((prevWeek) => prevWeek.add(1, "week"));
  };

  //   const handleCurrentWeek = () => {
  //     setCurrentWeek(dayjs().startOf('week'));
  //   };

  const viewEvent = (event) => {
    if (!event) return
    setCurrentEvent(event)
    handleOpen();
  };

  const createNewEvent = (date, slot) => {
    console.log(date, slot);
  };

  const findEvent = (date, slot) => {
    return slotData.find((appointment) => appointment.app_date == date && appointment?.available_slot?.slot_id === slot.id) ||
      unbookedSlots.find((unbooked) => unbooked[1] === slot.name && unbooked[0] === date && (dayjs().isBefore(dayjs(`${unbooked[0]} ${unbooked[1]}`, 'YYYY-MM-DD HH:mm a'))));
  };

  const checkTodayDate = (date) => {
    return date === dayjs().format("YYYY-MM-DD");
  };

  const timeInterval = () => {
    const table = document.getElementById('tbl')
    const TOP = table.lastChild.firstChild.offsetHeight + table.firstChild.offsetHeight
    const singleSlot = (table.offsetHeight - TOP) / 48
    const singleMinute = singleSlot / 30
    const now = dayjs();
    const startOfDay = now.startOf('day');
    const totalMinutes = now.diff(startOfDay, 'minute')
    setCurrentPosition((singleMinute * totalMinutes) + TOP)
  }

  const fetchRecord = async () => {
    const res = await getAppointments(renderWeekDates().join(","));
    const unbookedSlots = await getAppointmentsNotBooked(renderWeekDates().join(","));
    setUnbookedSlots(unbookedSlots);
    setSlotData(res)
  }

  React.useEffect(() => {

  }, [])

  useEffect(() => {
    getAllSlots();
    timeInterval()
    const id = setInterval(timeInterval, 60000)
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    setCurrentWeek(dayjs(selectedDate))
  }, [selectedDate])

  useEffect(() => {
    weekChangeHandler();
    fetchRecord()
  }, [currentWeek]);

  return (
    <div className="sb flex justify-start items-start table-container-main relative">
      <div style={{ top: currentPosition }} className={"live-time-track"}></div>
      <table id={"tbl"} className="calendar-table floatThead-table">
        <thead>
          <tr>
            <th className="cell"></th>
            <th className="extra !border-none">
              <svg
                className="prev"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                onClick={handlePreviousWeek}
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="30"
                  y="30"
                  width="30"
                  height="30"
                  rx="10"
                  transform="rotate(180 30 30)"
                  fill="#F3FEFF"
                />
                <path
                  d="M17.25 19.5L12.75 15L17.25 10.5"
                  stroke="#0A4E54"
                  strokeLinecap="round"
                />
              </svg>
            </th>
            {renderWeekDates().map((date, idx) => {
              return (
                <th
                  key={date + "-" + idx}
                  className={`cell ${checkTodayDate(date) && "current-date"}`}
                >
                  <div className="date">{date}</div>
                </th>
              );
            })}
            <th className="extra !border-none">
              <svg
                onClick={handleNextWeek}
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="next"
              >
                <rect width="30" height="30" rx="10" fill="#F3FEFF" />
                <path
                  d="M12.75 10.5L17.25 15L12.75 19.5"
                  stroke="#0A4E54"
                  strokeLinecap="round"
                />
              </svg>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="!border-none">&nbsp;</td>
            <td className="extra"></td>
            {renderWeekDates().map((date, idx) => {
              return (
                <td
                  key={date + "-" + idx}
                  className={`date-label ${checkTodayDate(date) && "current-date"}`}
                ></td>
              );
            })}
          </tr>
          {slots.map((slot, idx) => {
            return (
              <tr key={slot.id + "-" + idx}>
                <td className="!border-none cell">
                  <span className="slot-label">{slot.name}</span>
                </td>
                <td className="extra"></td>
                {renderWeekDates().map((date) => {
                  const event = findEvent(date, slot);
                  return (
                    <td
                      key={date + "-" + idx + "-" + slot.id}
                      className={`cell ${checkTodayDate(date) && "current-date"}`}
                    >
                      <div
                        className={`${event?.category ? "g " + event.category : "w-full h-full"}`}
                      >
                        {event ? (
                          event?.category ?
                            <CardComponent
                              slot={slot}
                              onClick={() => viewEvent(event)}
                              data={event}
                            /> :
                            <div className="calendar-card bg-[#EEF4F4]">
                              <div className="content z1">
                                <span className="name-span text-nowrap pr-2">Available Slot</span>
                              </div>
                              <div className="time-slots z1">
                                <img src={ClockIcon} height={18} width={18} />
                                <span className="time-span">{slot.name}</span>
                              </div>
                            </div>
                        ) : (
                          <div
                            onClick={() => createNewEvent(date, slot)}
                            className="w-full h-full"
                          ></div>
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <EventDetailCard show={true} event={currentEvent} handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

WeekDates.propTypes = {
  onWeekChange: PropTypes.func,
  data: PropTypes.array,
  selectedDate: PropTypes.object,
  slots: PropTypes.array,
};
export default WeekDates;
