import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CalendarIcon from "../assets/icons/Calendar";
import PatientsIcon from "../assets/icons/Patients";
import VideoIcon from "../assets/icons/Video";
import Upcoming from "./patient_app/upcoming";
import Appointment from "./patient_app/appointments"
import History from "./patient_app/history"
function Dashboard() {
  const [selectedTab, setSelectedTab] = useState('upcoming');
  const tabs = [
    {
      icon: CalendarIcon,
      label: 'Upcoming',
      value: 'upcoming'
    },
    {
      icon: VideoIcon,
      label: 'Appointments',
      value: 'appointments'
    },
    {
      icon: PatientsIcon,
      label: 'Physician History',
      value: 'history'
    }
  ]

  const View = () => {
    switch (selectedTab) {
      case "upcoming":
        return <Upcoming />;
      case "appointments":
        return <Appointment />
      case "history":
        return <History />
      default:
        return <Upcoming />
    }
  }

  React.useEffect(()=> {
    var url = new URL(window.location.href);
    var selectedTab = url.searchParams.get("tab");
    const existance = ["upcoming","appointments","history"].includes(selectedTab?.toLowerCase())
    if(existance){
      setSelectedTab(selectedTab)
    }
  }, [])
  return (
    <>
      <div className="window">
        <nav>
          <ul className="tab-list sbwo">
            {tabs.map((item) => (
              <li
                key={item.value}
                className={item.value === selectedTab ? "selected tab" : "tab"}
                onClick={() => setSelectedTab(item.value)}
              >
                <div className='backgroung-svg-tab'>
                  <item.icon />
                  {`${item.label}`}
                  {item.value === selectedTab ? (
                    <motion.div className="underline" layoutId="underline" />
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <main style={{
          background: "white",
          width: '100%',
          minHeight: 'calc(42rem + 8rem)',
          height: 'fit-content',
          paddingBottom: '2rem',
          borderBottomLeftRadius: '2rem',
          borderBottomRightRadius: '2rem',
        }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedTab ? selectedTab.label : "empty"}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className={'overflow-x-auto overflow-y-hidden sbwo'}
            >
              {selectedTab ? View() : "no tab selected"}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </>
  );
}

export default Dashboard;
