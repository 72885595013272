import React from "react"
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { specialities } from "../utils/axios";

export default function SearchSpecialityDropDown({ setSpeciality, speciality, home }){
  const [specialityInner, setSpecialityInner] = React.useState([]);
  const [data, setData] = React.useState([]);

  const fetchData = async () =>{
    const response = await specialities()
    if(response){
      setData(response.sort((a, b) => a.name.localeCompare(b.name)))
    }
  }

  React.useEffect(()=>{
    setSpecialityInner(speciality)
  }, [speciality])

  React.useEffect(()=>{
    if(home){
      if(setSpeciality) setSpeciality(specialityInner)
    }
  }, [specialityInner])

  React.useEffect(()=>{
    fetchData()
  }, [])
  return (
    <>
      <div id={'spec-val'} className={'hidden'}>{specialityInner}</div>
      <Autocomplete
        id="speiciality"
        name="speiciality"
        placeholder="Search a specialty"
        labelPlacement="outside"
        className={`max-w-md select ${home ? 'home' : ''}`}
        selectedKey={specialityInner}
        onSelectionChange={(...args) => {
          if(home) {
            setSpecialityInner(...args)
          } else {
            setSpeciality(...args)
          }
        }}
      >
        {data?.map((speciality) => (
          <AutocompleteItem className="flex w-full" key={speciality.slug} textValue={speciality.name}>
            <div className="flex gap-4 items-center text-base w-full">
              <div dangerouslySetInnerHTML={{ __html: speciality.icon }} />
              {speciality.name}
            </div>
          </AutocompleteItem>
        ))}
      </Autocomplete>
    </>
  )
}