import React from "react";
import Star from "../assets/icons/Star";
import FAQ from "../components/FAQ";
import {
  Pagination,
  Autocomplete,
  AutocompleteItem,
  Image,
} from "@nextui-org/react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { Fade } from "./doctor_app/CalenderView";
import Box from "@mui/material/Box";
import AppointmentBooking from "../components/AppointmentBooking";
import SearchSpecialityDropDown from "../components/SearchSpeciality";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  minHeight: "37.5rem",
  height: 'fit-content',
  flexShrink: 0,
  borderRadius: "0.625rem",
  padding: '0.88rem 1.12rem'
};

function DoctorListing({
  specialities = [],
  params = {},
  users = [],
  session = null,
  total_pages = 1,
  is_logged_in
}) {
  const [open, setOpen] = React.useState(false)
  const [speciality, setSpeciality] = React.useState(params.speciality);
  const [currentPage, setCurrentPage] = React.useState(1)
  const [specialitiesData, setSpecialities] = React.useState(specialities);
  const basePath = "/physicians/profile/";
  const [user, setUser] = React.useState({})

  const bookAppointmentHandler = (_user_) => {
      setUser(_user_);
      setOpen(true);
  }
  const redirectTo = (_user_) => {
    
    window.location.href = "/login?redirect_to=" + (basePath + _user_?.slug)
  }
  function updateSpecialityData() {
    setSpecialities(specialities);
  }
  React.useEffect(updateSpecialityData, [specialities]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paramValue = queryParams.get('page');
    const sortedSpecialties = specialities.sort((a, b) => a.name.localeCompare(b.name));
    setSpecialities(sortedSpecialties);
  }, []);

  // Function to update or add query parameter to current URL
  function updateOrAddQueryParam(param, value) {
    let url = window.location.href;
    let separator = url.indexOf('?') !== -1 ? '&' : '?';

    // Regular expression to find and replace query parameters
    let regex = new RegExp('([?&])' + param + '=[^&]*');

    // Check if the parameter already exists in the URL
    if (regex.test(url)) {
      // Parameter exists, update its value
      window.location.href = url.replace(regex, '$1' + param + '=' + encodeURIComponent(value));
    } else {
      // Parameter doesn't exist, add it to the URL
      window.location.href = url + separator + param + '=' + encodeURIComponent(value);
    }
  }
  const onPageChange = (pg)=>{
    updateOrAddQueryParam('page', pg)
  }
  const filterHandler = () => {
    if(speciality){
      window.location.href = "/physicians/search/" + speciality;
    } else {
      window.location.href = "/physicians"
    }
  };
  console.log(users);
  return (
    <div className={'p-4'}>
      <div className="flex gap-4 justify-center mt-12">
        <SearchSpecialityDropDown setSpeciality={setSpeciality} speciality={speciality} specialities={specialitiesData} />
        <button
          onClick={filterHandler}
          className="bg-secondary px-4 py-3 btn-round"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.5 2C4.91014 2 2 4.91014 2 8.5C2 12.0898 4.91015 15 8.5 15C12.0898 15 15 12.0898 15 8.5C15 4.91015 12.0898 2 8.5 2ZM0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80557 17 8.5C17 10.4903 16.3159 12.3209 15.17 13.7694L19.706 18.2918C20.0972 18.6818 20.0981 19.3149 19.7082 19.706C19.3182 20.0972 18.6851 20.0981 18.294 19.7082L13.7542 15.182C12.3081 16.3207 10.4834 17 8.5 17C3.80557 17 0 13.1944 0 8.5Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      <div className="flex justify-center w-full">
        <div
          style={{
            maxWidth: "76.375rem",
          }}
          className="flex flex-col align-start w-full"
        >
          <div className="text-primary font-semibold mt-4 mb-8">
            Our Services
          </div>
          <div
            className="font-normal text-secondary mt-8 text-[30px] sm:text-[40px]"
          >
            Best {params.speciality && <span className="text-primary capitalize">{params.speciality}</span>} Doctors
          </div>
          <p className={'w-full sm:w-[50%]'}>
          Simply register and set up a video appointment with a physician of
            your choice or request a review of your medical records to get a
            written opinion.
          </p>
        </div>
      </div>
      <div className="w-full flex justify-center md:p-4 mt-4">
        <ul
          className="w-full flex flex-col gap-4"
          style={{
            maxWidth: "76.375rem",
            height: "fit-content",
          }}
        >
          {users.map((reviews, idx) => (
            <li
              key={idx}
              className="w-full h-full doctor-list-item flex flex-col sm:flex-row justify-between items-star px-2 sm:px-10 py-8"
            >
              <div className="flex gap-2">
                <div className={'pt-5 pb-5 h-full flex justify-center items-center'}>
                <Image
                  className="h-40 w-40 object-contain"
                  src={reviews.display_image_url}
                  alt="NextUI Album Cover"
                />
                </div>
                <div className="pt-5 pb-5 flex flex-col gap-3 m-0 sm:ml-4 md:ml-11">
                  <div className="flex flex-col">
                    <div className="text-2xl font-semibold flex gap-2 justify-start items-center">
                      {reviews.first_name + " " + reviews.last_name}
                    </div>
                    <div className="flex flex-col md:flex-row gap-4 justify-start">
                      <span>
                        <span className="text-sm text-custom-gray">
                          Experience:{" "}
                        </span>
                        <span className="text-lg text-custom-gray">
                          {reviews.experience ? `${reviews.experience} Year(s)` : "NA"}
                        </span>
                      </span>
                      <span className="items-center flex gap-2">
                        <Star /> <span>{reviews.having_reviews}</span>
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-4">
                    {reviews.specialities.map((speciality, index) => {
                      return (
                        <div
                          key={index}
                          className="py-2.5 px-6 bg-primary-dark gap-2.5 btn-round"
                        >
                          {speciality.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-4">
                {!session && (
                <div
                  className={`${!is_logged_in && 'slide-box'} cursor-pointer h-fit btn-round w-full bg-black text-white py-3.5 px-8 text-center self-center ${session && "opacity-0"}`}
                  onClick={() => is_logged_in ? bookAppointmentHandler(reviews) : ''}
                >
                  <div
                    className={`${!is_logged_in && 'hide-txt'} w-full user-select-none`}
                  >
                    Book Now
                  </div>
                  {!is_logged_in &&
                  <div className="hid-box user-select-none" onClick={() => redirectTo(reviews)}>
                    Login
                  </div>
                  }
                </div>
                )}
                <a
                  href={basePath + reviews.slug}
                  className="btn-round border-secondary border text-secondary py-3.5 px-8 w-full text-center self-center"
                >
                  View Profile
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-center w-full mt-4">
        <Pagination size={'lg'} showControls total={total_pages} page={currentPage} setPage={setCurrentPage} onChange={onPageChange} initialPage={1} />
      </div>
      <div className="flex justify-center w-full">
        <FAQ />
      </div>

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <AppointmentBooking onClose={() => setOpen(false)} user={user} initialStep={1}  isLoggedIn={true} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default DoctorListing;
