import React from 'react';
import Step1 from "../assets/how-it-works/1.png"
import Step2 from "../assets/how-it-works/2.png"
import Step3 from "../assets/how-it-works/3.png"
import Step4 from "../assets/how-it-works/4.png"
import Step5 from "../assets/how-it-works/5.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


const style = {
  width: 5,
  height: 50,
  borderLeft: '1px solid var(--secondary)',
  borderRight: '1px solid var(--secondary)'
}
export default function HowItWorks(){
  const [screenWidth, setScreenWidth] = React.useState(1800);

  // Function to update screenWidth state when the window is resized
  const handleResize = () => {
    setScreenWidth(window?.innerWidth);
  };

  // Add event listener when component mounts
  React.useEffect(() => {
    handleResize()
    window?.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window?.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect will only run once after initial render

  return (
    <>
      <Carousel
        className={'cursor-grab'}
        showArrows={false}
        showThumbs={false}
        axis="vertical"
        emulateTouch={true}
        showIndicators={screenWidth >= 768}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...style, backgroundColor: 'var(--secondary)', borderTop: index === 0 ? '1px solid var(--secondary)' : '' }}
                aria-label={`Selected: ${label} ${index + 1}`}
                title={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={{ ...style, borderTop: index === 0 ? '1px solid var(--secondary)' : '', borderBottom: index === 4 ? '1px solid var(--secondary)' : '', borderTopRightRadius: index === 0 ? 10 : 0, borderTopLeftRadius: index === 0 ? 10 : 0, borderBottomRightRadius: index === 0 ? 10 : 0, borderBottomLeftRadius: index === 0 ? 10 : 0}}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
            />
          );
        }}
      >
        <div className={"w-full h-full grid grid-cols-2"}>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <img src={Step1} className={'STep - 1 how to use connectMD'} />
          </div>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <div className="content px-4 !items-start">
              <div className="step text-secondary text-left">
                Step 01
              </div>
              <div className="step-value text-left">
                <span className="text-secondary">Register at the site by</span> <span className="text-primary">entering basic information</span>.
              </div>
            </div>
          </div>
        </div>
        <div className={"w-full h-full grid grid-cols-2"}>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <img src={Step2} className={"STep - 2 how to register on ConnectMD"} />
          </div>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <div className="content px-4 !items-start">
              <div className="step text-secondary text-left">
                Step 02
              </div>
              <div className="step-value text-left">
                <span className="text-secondary">Choose a specialty and view a </span> <span className="text-primary">list of available physicians.</span>.
              </div>
            </div>
          </div>
        </div>
        <div className={"w-full h-full grid grid-cols-2"}>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <img src={Step3} className={"STep - 3 Booking flow on ConnectMD"} />
          </div>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <div className="content px-4 !items-start">
              <div className="step text-secondary text-left">
                Step 03
              </div>
              <div className="step-value text-left">
                <span className="text-secondary">Book a </span> <span className="text-primary">video appointment and upload your medical records</span><span
                className="text-secondary"> at least 48 hours prior to the
appointment.</span>
                <br /><br />
                <span className="text-secondary">Alternatively, upload your medical records </span> <span
                className="text-primary">for review without a video appointment.</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"w-full h-full grid grid-cols-2"}>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <img src={Step4} className={"STep - 2 how to register on ConnectMD"} />
          </div>
          <div className={"col-span-1 !grid !place-items-center px-4"}>
            <div className={"col-span-2 sm:col-span-1 px-4"}>
              <div className="step text-secondary text-left">
                Step 04
              </div>
              <div className="step-value text-left">
                <span className="text-secondary">Choose a specialty and view a </span> <span className="text-primary">list of available physicians.</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"w-full h-full grid grid-cols-2"}>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <img src={Step5} className={"STep - 2 how to register on ConnectMD"} />
          </div>
          <div className={"col-span-2 sm:col-span-1 !grid !place-items-center px-4"}>
            <div className="content px-4 !items-start">
              <div className="step text-secondary text-left">
                Step 05
              </div>
              <div className="step-value text-left">
                <span className="text-secondary">Join the video </span> <span className="text-primary">visit by clicking the visit icon.</span>
              </div>
              <br />
              <div className="step text-secondary text-left">
                Step 06
              </div>
              <div className="step-value text-left">
                <span className="text-secondary">Complete the appointment and download the </span> <span
                className="text-primary">physician’s report that is typically available within 48 hours.</span>
              </div>
            </div>
          </div>
        </div>
      </Carousel>
    </>
  )
}