import React from 'react'
import { Pagination } from "@nextui-org/react";
function SortArrows({type, id}) {
  return (
    <svg data-id={id} width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 10L5 14L9 10" stroke={(type === 'ASC' || (!type || type === 'no-filter')) ? "#07383D" : "lightgray"} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M9 6L5 2L1 6" stroke={(type === 'DESC' || (!type || type === 'no-filter')) ? "#07383D" : "lightgray"} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  )
}
export default function Table({ className, page, headers = [], data = [], total_pages = 1, setPage, limit = 10 }) {
  const [heads, setHeads] = React.useState(headers || []);
  const [currentPage, setCurrentPage] = React.useState(1)
  const [bodyData, setBodyData] = React.useState(data || []);
  const [sort, setSort] = React.useState({});

  const onPageChange = (pg)=>{
    setPage(pg)
  }
  const switchHandler = (value, setValue) => {
    if (value === "no-filter" || !value) {
      setValue("ASC");
    } else if (value === "ASC") {
      setValue("DESC");
    } else if (value === "DESC") {
      setValue("no-filter");
    }
  };

  const labels = () => heads.map(i => i.id) || [];
  const sortFinder = (id) => sort[id];

  const isFilterApplicable = (column) => {
    const copiedData = { ...sort };
    copiedData[column.id] = column.filter;
    setSort(copiedData);
  };
  function sortData(data, column, ascending = true) {
    return data.slice().sort((a, b) => {
      let valA = a[column];
      let valB = b[column];

      // Handling special cases like dates
      if (column === 'app_date') {
        valA = new Date(valA);
        valB = new Date(valB);
      }

      if (ascending) {
        return valA > valB ? 1 : -1;
      } else {
        return valA < valB ? 1 : -1;
      }
    });
  }

  const handleSort = (column) => {
    switchHandler(column.filter, (type) => {
      column.filter = type;
    });
    isFilterApplicable(column);
    const sortedData = sortData(bodyData, column.id, column.filter === "ASC");
    setBodyData(sortedData)
  };

  const templateFinder = (id) => heads.filter(head => head.id === id)[0];

  React.useEffect(() => {
    setCurrentPage(page)
  }, [page]);

  React.useEffect(()=>{
    setBodyData(data)
  }, [data])

  return (
    <>
    <table className={["w-full", className].join(' ')}>
      <thead>
      <tr>
        {
          heads.map((head, idx) => {
            return (
              <th onClick={() => !head.hideSort && handleSort(head)} key={head.id+"_"+idx+"_"+head.label} data-id={head.id}
                  className={`user-select-none ${!head.hideSort && "cursor-pointer"}`}>
                <div className={["flex gap-2 items-center", head.centerAlign && "flex justify-center"].join(' ')}>
                  {!head.hideSort && <SortArrows id={head.id} type={sortFinder(head.id) || "no-filter"} />}
                  {!head.hideHeader && head.label}
                </div>
              </th>
            );
          })
        }
      </tr>
      </thead>
      <tbody>
      {bodyData.length === 0 ? <tr>
        <td colSpan={10} className={'relative w-full'}>
          <div className={"text-danger font-semibold text-center text-base"}>No Data Available</div>
        </td>
      </tr> : <></>}
      {bodyData.map(row => {
        return (
          <tr key={row.id}>
            {labels().map(key => {
              const HEAD = templateFinder(key);
              if (HEAD?.template) {
                return (
                  <td key={key+"_"+row.id}>
                    <div className={["w-full", "h-full", HEAD.centerAlign && "flex justify-center"].join(" ")}>
                      {HEAD?.template(row[key], row, key) || ""}
                    </div>
                  </td>
                );
              } else {
                return (
                  <td key={key+"_"+row.id}>
                    <div className={["w-full", "h-full", HEAD.centerAlign && "flex justify-center"].join(" ")}>
                      {row[key]}
                    </div>
                  </td>
                );
              }
            })}
          </tr>
        );
      })}
      </tbody>
    </table>
      <br />
      {
        bodyData.length !== 0 && (
          <div className="flex justify-center w-full">
            <Pagination size={'lg'} showControls total={Math.ceil(total_pages / limit)} page={currentPage} onChange={onPageChange}
                        initialPage={1} />
          </div>
        )
      }
    </>
  )
}