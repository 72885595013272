import React from 'react';
export default function CheckIcon() {
    return (
        <div className="calendar-card-icon check">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M9.42549 1.87879C9.74711 1.5634 9.74711 1.05199 9.42549 0.736566C9.10388 0.421145 8.58253 0.421145 8.26091 0.736566L3.35299 5.55003L1.73923 3.96738C1.41762 3.65195 0.896184 3.65195 0.574578 3.96738C0.252973 4.28281 0.252973 4.79414 0.574578 5.10957L2.77066 7.26343C3.09231 7.57886 3.61366 7.57886 3.93528 7.26343L9.42549 1.87879Z" stroke="white" fill="white"/>
  
            </svg>
            </div>
    )
}