import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { draftReport, publishReport } from "../../utils/axios";
import {toast} from "react-toastify"

export default function ReviewRecord({url}) {
  return(
    <>
      {url ? (
        <>
          <iframe src={url} className={'w-full min-h-[800px]'}/>
        </>
      ) : (
        <div className={'w-full text-center text-gray font-semibold capitalize'}>No Report Found!</div>
      )}
    </>
  );
}