import EyeSlashFilledIcon from "../../../assets/icons/EyeSlashFilledIcon";
import EyeFilledIcon from "../../../assets/icons/EyeFilledIcon";
import React from "react";
import dayjs from "dayjs";
import { Select, SelectItem, Input } from "@nextui-org/react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { validator } from "./validations";

const PhysicianRegistration = ({ formik }) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [isCVisible, setIsCVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleCVisibility = () => setIsCVisible(!isCVisible);

  const minDate = () => {
    // Get the current date
    let currentDate = dayjs();
    // Subtract 18 years from the current date
    let eighteenYearsAgo = currentDate.subtract(18, "year");
    return eighteenYearsAgo;
  };

  const genders = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" },
  ];
  return (
    <>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-1">
          <Input
            value={formik.values.first_name}
            type="text"
            id="first_name"
            name="first_name"
            placeholder="First Name *"
            variant="bordered"
            isInvalid={validator(formik, "first_name")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full text-base"
          />
          {validator(formik, "first_name") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.first_name}
            </div>
          )}
        </div>
        <div className="col-span-1">
          <Input
            value={formik.values.last_name}
            type="text"
            id="last_name"
            name="last_name"
            placeholder="Last Name *"
            variant="bordered"
            isInvalid={validator(formik, "last_name")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full"
          />
          {validator(formik, "last_name") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.last_name}
            </div>
          )}
        </div>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2">
        <div className="col-span-1">
          <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Gender</label>
          <Select
            variant="bordered"
            id="gender"
            name="gender"
            placeholder="Select Gender"
            selectedKeys={formik.values.gender ? [formik.values.gender] : []}
            isInvalid={validator(formik, "gender")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="max-w-xs select"
          >
            {genders.map((gender) => (
              <SelectItem key={gender.value} value={gender.value}>
                {gender.label}
              </SelectItem>
            ))}
          </Select>
          {validator(formik, "gender") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.gender}
            </div>
          )}
        </div>
        <div className="col-span-1">
          <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Date Of Birth</label>
          <LocalizationProvider className="p-0" dateAdapter={AdapterDayjs}>
            <DemoContainer className="p-0" components={["DatePicker"]}>
              <DatePicker
                sx={{
                  input: {
                    color: !formik.values.date_of_birth
                      ? "#5E5E5E"
                      : "var(--bg-secondary)",
                  },
                  fieldset: {
                    borderColor: "var(--primary) !important",
                  },
                }}
                error={false}
                name="date_of_birth"
                id="date_of_birth"
                maxDate={minDate()}
                value={dayjs(formik.values.date_of_birth)}
                className={`${validator(formik, "date_of_birth") && "error-border"} w-full`}
                onChange={(value) =>
                  formik.setFieldValue("date_of_birth", value)
                }
              />
            </DemoContainer>
          </LocalizationProvider>

          {validator(formik, "date_of_birth") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.date_of_birth}
            </div>
          )}
        </div>
      </div>
      <div>
        <Input
          value={formik.values.email}
          type="email"
          id="email"
          name="email"
          placeholder="Email *"
          variant="bordered"
          isInvalid={validator(formik, "email")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="w-full mt-4 "
        />
        {validator(formik, "email") && (
          <div className="w-full mt-1 text-sm text-danger">
            {formik.errors.email}
          </div>
        )}
      </div>
      <Input
        id="password"
        name="password"
        variant="bordered"
        placeholder="Password *"
        endContent={
          <button
            className="focus:outline-none"
            type="button"
            onClick={toggleVisibility}
          >
            {isVisible ? (
              <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
            ) : (
              <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
            )}
          </button>
        }
        type={isVisible ? "text" : "password"}
        className="w-full mt-4"
        isInvalid={validator(formik, "password")}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.values.password && <div className="w-full mt-1 text-sm text-danger">
        {formik.errors.password}
      </div>}
      {/* {validator(formik, "password") && (
        <div className="w-full mt-1 text-sm text-danger">
          {formik.errors.password}
        </div>
      )} */}
      <Input
        id="confirm_password"
        name="confirm_password"
        variant="bordered"
        placeholder="Confirm Password *"
        endContent={
          <button
            className="focus:outline-none"
            type="button"
            onClick={toggleCVisibility}
          >
            {isCVisible ? (
              <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
            ) : (
              <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
            )}
          </button>
        }
        type={isCVisible ? "text" : "password"}
        className="w-full mt-4"
        isInvalid={validator(formik, "confirm_password")}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {!formik.values.confirm_password ||
        (formik.values.password !== formik.values.confirm_password && (
          <div className="w-full mt-1 text-sm text-danger">
            Password not match!
          </div>
        ))}

      {/* <div className="flex flex-col gap-1 w-full mt-4 text-sm text-danger">
        <span>* Password is Required!</span>
        <span>* Password must be at least 8 characters long!</span>
        <span>* At least one lowercase and one uppercase letter is required in password!</span>
        <span>* At least one number is required in password!</span>
      </div> */}
    </>
  );
};

export default PhysicianRegistration;
