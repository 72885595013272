import React from "react";
import { Accordion, AccordionItem } from "@nextui-org/react";
import DownIcon from "../assets/icons/Down"

const data = [
  {
    question: "What services are provided by ConnectMD?",
    answer: "ConnectMD provides online consultation in a range of specialties. You may be seeking an initial appointment or a second opinion. ConnectMD may also provide consultation to other physicians seeking an expert opinion."
  },
  {
    question: "Does ConnectMD provide my continued medical care?",
    answer: "No. ConnectMD physician is not your treating physician. They will provide an opinion about your condition to help develop a better medical insight. No doctor-patient relationship is established by using this service. Your local physician will be your treating physician who will provide ongoing care."
  },
  {
    question: "Who are ConnectMD physicians?",
    answer: "ConnectMD physicians are US trained doctors who are nationally and internationally recognized experts in their field."
  },
  {
    question: "How much ConnectMD services cost?",
    answer: "The cost of ConnectMD service varies depending on the type of appointment. The cost will be displayed when you book your appointment."
  },
  {
    question: "Why do I need to upload my medical records?",
    answer: "We ask you to upload your medical records including your doctor’s assessment, laboratory test results and imaging reports prior to your appointment. Our physician will review your records to have a more meaningful interaction with you."
  },
  {
    question: "What are my payment options?",
    answer: "ConnectMD accepts major credit cards."
  },
  {
    question: "How does ConnectMD protect my privacy?",
    answer: "Your medical records are stored in a HIPAA-compliant, encrypted server that can be accessed only by you and by your physician."
  }
]
export default function FAQ() {
  return (
    <div style={{ maxWidth: "76.375rem", }} className="mt-20 w-full">
      <h2 className="pl-[25px] text-primary">FAQs</h2>
      <Accordion className="grid grid-cols-2 mb-8 mt-4" variant="splitted">
        {data.map((faq, idx) => {
          return (
            <AccordionItem key={idx} indicator={<DownIcon />} className={`accordion-${idx} col-span-2 md:col-span-1 bg-white !shadow-none`} aria-label="Accordion 1" title={faq.question}>
              {faq.answer}
            </AccordionItem>
          )
        })}
      </Accordion>
    </div>
  );
}
