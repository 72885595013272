import React from 'react';
import { Breadcrumbs, BreadcrumbItem, Image } from '@nextui-org/react';
import VerifiedIcon from '../../assets/icons/Verified';
import Calendly from '../../components/Calendly';
import Star from '../../assets/icons/Star';
import axios from 'axios'

function DoctorProfile({ data, recomended_doctors, is_logged_in, session }) {
  const [breadCrumbs, setBreadCrumbs] = React.useState([]);
  const [page, setPage] = React.useState(1)
  const [reviews, setReviews] = React.useState([])
  const [showLoadMore, setShowMore] = React.useState(false)
  // Function to extract breadcrumbs from URL
  function getBreadcrumbs() {
    // Remove protocol and domain
    const path = window.location.href.replace(/^https?:\/\/[^/]+/, '');
    // Split path into segments
    const segments = path.replace('profile', 'search').split('/').filter((segment) => segment !== '');

    // Extract breadcrumbs from segments
    const breadcrumbs = segments.map((segment, index) => {
      // Capitalize the first letter of each segment
      const capitalizedSegment =
        segment.charAt(0).toUpperCase() + segment.slice(1);

      // Create a breadcrumb object
      return {
        text: capitalizedSegment,
        link: '/' + segments.slice(0, index + 1).join('/'),
      };
    });
    const full_name = data?.first_name + " " + data?.last_name
    const bCrumbs = breadcrumbs.map(i => {
      const slug = i.text.toLowerCase()
      if (slug.search("ph-") >= 0) {
        return { text: full_name, link: i.link }
      } else {
        return i
      }
    })
    setBreadCrumbs(bCrumbs);
  }

  const loadMoreHandler = async () => {
    const pageNumber = (reviews.length / 2) + 1;
    console.log(reviews.length);
    console.log("Reviews present");
    console.log(reviews);

    await axios.get(`/reviews?id=${data.id}&page=${pageNumber}&limit=2`).then(response => {
      const newReviews = response.data;

      // Check if there are no new reviews
      if (newReviews.length === 0) {
        setShowMore(false);
        return;
      }

      // Check if any of the new reviews are different from the existing ones
      const areAnyNewReviewsDifferent = newReviews.some(newReview =>
        !reviews.some(existingReview => existingReview.id === newReview.id)
      );

      if (areAnyNewReviewsDifferent) {
        setReviews([...reviews, ...newReviews]);
        setPage(pageNumber);
      } else {
        setShowMore(false);
      }
    }).catch(() => {
      setShowMore(false);
    });
  }



  React.useEffect(() => {
    getBreadcrumbs();
    loadMoreHandler()
  }, []);
  console.log(data);
  return (
    <>
      <div className='grid grid-cols-10 w-full gap-8 '>
        <div className={`col-span-12 ${is_logged_in && 'lg:col-span-6'}`}>
          <div className='w-full'>
            <Breadcrumbs>
              {breadCrumbs.map((object, index) => {
                return (
                  <BreadcrumbItem key={index} href={object.link}>
                    {object.text}
                  </BreadcrumbItem>
                );
              })}
            </Breadcrumbs>
          </div>
          <div className='flex md:flex-row flex-col mt-8'>
            <Image
              className='h-40 w-40'
              src={data.display_image_url}
              alt='NextUI Album Cover'
            />
            <div className='pt-4 pb-4 flex flex-col gap-3 md:ml-6 ml-0'>
              <div className='flex flex-col'>
                <div className='text-3xl font-[500] flex gap-2 justify-start items-center mb-2'>
                  {data.first_name + " " + data.last_name}
                  <VerifiedIcon />
                </div>
                <div className='flex gap-6 items-center'>
                  <span>
                    <span className='text-sm text-custom-gray'>
                      Experience&nbsp;&nbsp;&nbsp;
                    </span>
                    <span className='text-lg text-custom-gray'>{data.experience ? `${data.experience} Year(s)` : "NA"}</span>
                  </span>
                  <span className='items-center flex gap-2'>
                    <Star /> <span>{data.having_reviews || 0}</span>
                  </span>
                </div>
              </div>
              <div className='flex gap-4 flex-wrap'>
                {data.specialities.map((speciality, index) => {
                  return (
                    <div
                      key={index}
                      className='py-2.5 px-6 bg-primary-dark gap-2.5 btn-round'
                    >
                      {speciality.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className='mt-4 bg-white md:p-12 p-6 roundex-xl'>
            <h1 className='font-[400] text-lg'>
              About {data.first_name + " " + data.last_name}
            </h1>
            <br />
            <p className='text-sm text-custom-gray'>
              {data.about}
            </p>
            <br />
            {data.board_certifications.length ? (
              <>
                <h1 className='text-secondary font-semibold text-base'>
                  Certifications
                </h1>
                <div className='p-3'>

                  {data.board_certifications.map((certificate, idx) => {
                    return <ul
                      style={{
                        display: 'list-item',
                      }}
                      className='w-full list-disc ml-5 pr-20'
                    >
                      <li key={idx} className="text-sm text-custom-gray flex justify-between w-full custom-li">
                        <div>{certificate.certification}</div>
                        <div>{certificate.exp_date}</div>
                      </li>
                    </ul>
                  })}
                </div>
              </>
            ) :
              <></>
            }
          </div>
          <div className='mt-4 bg-white md:p-12 p-6 roundex-xl'>
            <h1 className="font-[400] text-lg">Reviews</h1>
            {reviews.length ? (
              <>
                <div>
                  <ul className="flex m-0 p-0 flex-col gap-4">
                    {reviews.map((reviews, idx) => (
                      <li key={idx} className="py-4 flex justify-between items-start border-b-2 border-gray-200">
                        <div className="flex gap-3">
                          <Image
                            className="h-[70px] w-[70px] max-w-[70px]"
                            src={reviews?.image_url}
                            alt="NextUI Album Cover"
                          />
                          <div className="flex flex-col py-2">
                            <div
                              className="font-semibold text-lg">{reviews.patient_name} </div>
                            <div className="text-sm text-gray-600">
                              {reviews.comment}
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-center items-start">
                          <Star /> &nbsp; <span>{reviews.rating}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                {showLoadMore && (reviews.length % 2 === 0) ?
                  <div className="flex justify-center items-center p-6">
                    <span className="text-primary cursor-pointer" onClick={loadMoreHandler}>Load More</span>
                  </div>
                  : (
                    <>
                    </>
                  )
                }
              </>
            ) : (
              <>
                <div className={'text-gray font-semibold text-sm text-center'}>
                  No Reviews to show!
                </div>
              </>
            )}

          </div>
          <div className="mt-4 p-6 roundex-xl">
            <h1 className="text-secondary font-semibold text-base">
              Doctor Recommendations in Hepotology
            </h1>
            <ul className="flex flex-wrap md:flex-row gap-8 mt-8 overflow-auto justify-between">
              {recomended_doctors.map((i, idx) =>
                <a key={i.id + "-" + idx} href={"/physicians/profile/" + i.slug}>
                  <li className="flex gap-4">
                    <Image
                      className="h-20 w-20"
                      src={i.display_image_url}
                      alt="NextUI Album Cover"
                    />
                    <div className="flex flex-col gap-3 ml-6">
                      <div className="flex flex-col">
                        <div className="text-lg font-semibold flex gap-2 justify-start items-center">
                          {i.first_name} {i.last_name}
                          <VerifiedIcon width="30px" height="30px" />
                        </div>
                        <div className='flex gap-4 items-center'>
                          <span>
                            <span className='text-sm font-semibold text-custom-gray'>
                              Experience:{' '}
                            </span>
                            <span className='text-sm text-custom-gray'>
                              {i.experience ? `${i.experience} Year(s)` : 'NA'}
                            </span>
                          </span>
                        </div>
                        <span className='items-center flex gap-2'>
                          <Star width="20px" height="20px" /> <span className='text-sm'>{i.review_counts} Reviews</span>
                        </span>
                      </div>
                    </div>
                  </li>
                </a>
              )}
            </ul>
          </div>
        </div>
        <div className='col-span-12 lg:col-span-4 relative'>
          <Calendly user={data} isLoggedIn={is_logged_in} is_doctor_logged={session} />
        </div>
      </div>
    </>
  );
}

export default DoctorProfile;
