import React from 'react'
import { dateFormatter } from "../utils/helpers";
import Vector53 from '../assets/eventViewAssets/vector-53.svg'
import Review from '../assets/eventViewAssets/review.svg'
import Group59 from '../assets/eventViewAssets/group-59.svg'
import Group57 from '../assets/eventViewAssets/group-57.svg'
import Vector from '../assets/eventViewAssets/vector.svg'
import Vector1 from '../assets/eventViewAssets/vector-1.svg'
import Vector2 from '../assets/eventViewAssets/vector-2.svg'
import Vector52 from '../assets/eventViewAssets/vector-52.svg'
import Group60 from '../assets/eventViewAssets/group-60.svg'
import Group62 from '../assets/eventViewAssets/group-62.svg'
import { cancelAppointment, getMyData, getRecordById } from "../utils/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from '@mui/material/Slide';
import IconButton from "@mui/material/Button";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EventDetailCard = ({ show = false, className, event, shadow = true }) => {
  const [record, setRecord] = React.useState({})
  const [user, setUser] = React.useState({})
  const [isPassed, setIsPassed] = React.useState(false)
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleAlertOpen = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };
  const fetchRecord = async (id) => {
    const response = await getRecordById(id)
    if (response) {
      setRecord(response)
    }
  }

  const fetchMe = async () => {
    const response = await getMyData()
    if (response) {
      setUser(response)
    }
  }

  const linkOpener = (link) => {
    if (link) {
      window.open(link, "_blank")
    } else {
      toast.error("No Zoom Link Present!")
    }
  }
  const joinURL = () => {
    if (user.category === 'doctor') {
      setTimeout(() => {
        window.location.href = `/reviews-on-record/${event?.id}/${event?.patient_id}`
      }, 3000)
      if (event.category === 'video') {
        linkOpener(event.start_url)
      }
    } else {
      linkOpener(event.join_url)
    }
  }

  const linkHandler = (e) => {
    e.stopPropagation();
    const is_event_passed = dayjs(event.app_date + " " + event.available_slot?.slot?.name).isBefore(dayjs(), 'day')
    if (event.status === "completed" || event.status === "noshow" || is_event_passed) {
      if (event.category === "video") {
        toast.warn("Link Expired!")
        return;
      } else {
        window.location.href = "/reviews-on-record/" + event.id + "/" + event.patient.id
        return;
      }
    };
    if (event.category === 'video') {
      if (event.start_url) {
        window.open(event.start_url)
      } else {
        toast.error("No Zoom Link Present!")
      }
    } else {
      window.location.href = "/reviews-on-record/" + event.id + "/" + event.patient.id
    }
  }

  const cancelAppointmentHandler = async () => {
    if (isPassed) {
      toast.error("The appointment has passed!")
      return
    }
    const differenceInHours = timeRemaining()
    if ((differenceInHours >= 0 && user.category === 'doctor') || (differenceInHours > 48 && user.category === 'patient')) {
      const response = await cancelAppointment(event.id)
      if (response) {
        toast.success("Appointment is cancelled!");
        window.location.reload();
      } else {
        toast.success("Error while cancelling appointment!");
      }
    } else {
      toast.error("Appointments cannot be cancelled within 48 hours!")
    }
  }

  const timeRemaining = () => {
    if (!event) return;
    const endDatetime = dayjs(event.app_date + ' ' + event?.available_slot?.slot?.name);
    const startDatetime = dayjs();

    // Calculate the difference in hours
    return endDatetime.diff(startDatetime, 'hour');
  }

  React.useEffect(() => {
    if (event && event.record_id) {
      fetchRecord(event.record_id);

      const differenceInHours = timeRemaining()
      setIsPassed(differenceInHours <= 0)
    }
    fetchMe()
  }, [event])
  return (
    <div
      className={"event-view-card " + className}
      style={{
        width: "100%",
        position: "relative",
        minHeight: '610px',
        boxShadow: shadow && "0px 4px 20px rgba(7, 56, 61, 0.5)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        letterSpacing: "normal",
        backgroundColor: event ? event?.category === "video" ? "#edfdff" : "#EDFFFA" : "#edfdff",
      }}
    >
      {(timeRemaining() >= 0 && (event.status === 'pending' || event.status === null) && user.category === "doctor") ||
        (timeRemaining() >= 48 && (event.status === 'pending' || event.status === null) && user.category === "patient") ? (
        <PopupState variant="popover" popupId="event-card-popup-menu" id={"event-card-popup-menu"}>
          {(popupState) => (
            <React.Fragment>
              <IconButton id={"event-card-popup-menu"} style={{ minWidth: 20, width: 20 }} {...bindTrigger(popupState)}>
                <MoreVertIcon />
              </IconButton>
              <Menu sx={{
                '.MuiList-root': {
                  padding: 0,
                }
              }} {...bindMenu(popupState)}>
                <MenuItem
                  sx={{
                    background: '#ee2348 !important'
                  }}
                  onClick={() => {
                    popupState.close();
                    handleAlertOpen()
                  }}
                >
                  <span className={'text-white'}>Cancel</span>
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      ) : (
        <></>
      )}

      {(event && (show || !isPassed)) ?
        <section
          style={{
            flex: "1",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0.88rem 1.25rem 1.44rem 1.13rem",
            boxSizing: "border-box",
            gap: "1.38rem",
            maxWidth: "100%",
            textAlign: "left",
            fontSize: "0.75rem",
            color: "#63999d",
          }}
        >
          <div
            style={{
              width: "25.63rem",
              height: "37.5rem",
              position: "relative",
              borderRadius: "10px",
              backgroundColor: "#edfdff",
              display: "none",
              maxWidth: "100%",
            }}
          />
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              gap: "0.75rem",
              maxWidth: "100%",
            }}
          >
            <header
              style={{
                alignSelf: "stretch",
                height: "2.13rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 0.13rem 0rem 0rem",
                boxSizing: "border-box",
                position: "relative",
                gap: "0.81rem",
                textAlign: "left",
                fontSize: "0.75rem",
                color: "#07383d",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontWeight: "500",
                  whiteSpace: "nowrap",
                  zIndex: "1",
                }}
              >
                Appointment Details
              </div>
              <img
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                  zIndex: "1",
                }}
                loading="eager"
                alt=""
                src={Vector53}
              />
            </header>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 1.25rem 0rem 0.75rem",
                boxSizing: "border-box",
                maxWidth: "100%",
                flexShrink: "0",
                fontSize: "0.63rem",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4.38rem",
                  }}
                >
                  <img
                    style={{
                      width: "0.64rem",
                      height: "0.7rem",
                      position: "relative",
                      zIndex: "1",
                    }}
                    loading="eager"
                    alt=""
                    src={Group59}
                  />
                  <img
                    style={{
                      width: "0.62rem",
                      height: "0.76rem",
                      position: "relative",
                      zIndex: "1",
                    }}
                    loading="eager"
                    alt=""
                    src={Group57}
                  />
                </div>
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0rem 1.81rem 0rem 0rem",
                    boxSizing: "border-box",
                    position: "relative",
                    gap: "1.19rem",
                    minWidth: "8.06rem",
                  }}
                >
                  <div
                    style={{
                      width: "4.31rem",
                      position: "relative",
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                      zIndex: "1",
                    }}
                  >
                    Serial No.
                  </div>
                  <div
                    style={{
                      height: "1.94rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0rem 0rem 0.44rem",
                      boxSizing: "border-box",
                      fontSize: "1.25rem",
                      color: "#000",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        position: "relative",
                        fontWeight: "500",
                        display: "flex",
                        alignItems: "center",
                        zIndex: "1",
                      }}
                    >
                      {event.id}
                    </div>
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "0.69rem",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        fontWeight: "500",
                        zIndex: "1",
                      }}
                    >
                      Physician
                    </div>
                    <h2
                      style={{
                        margin: "0",
                        height: "1.88rem",
                        position: "relative",
                        fontSize: "1.25rem",
                        fontWeight: "500",
                        fontFamily: "inherit",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        zIndex: "1",
                        width: '300px'
                      }}
                    >
                      {event?.doctor?.first_name + " " + event?.doctor?.last_name}
                    </h2>
                  </div>
                  <img
                    style={{
                      width: "0.64rem",
                      height: "0.7rem",
                      position: "absolute",
                      margin: "0",
                      top: "0.13rem",
                      right: "0.11rem",
                      zIndex: "1",
                    }}
                    loading="eager"
                    alt=""
                    src={Group59}
                  />
                </div>
                <div
                  style={{
                    width: "7.25rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "1.56rem",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      fontWeight: "500",
                      zIndex: "1",
                    }}
                  >
                    Type of Interaction
                  </div>
                  <div
                    style={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0rem 0.06rem 0rem 0.31rem",
                    }}
                  >
                    {event.status !== "cancelled" && user.category === 'patient' && event.category !== 'video' &&
                      <div
                        style={{
                          border: "none",
                          padding: "0.75rem 1.13rem 0.75rem 1.25rem",
                          backgroundColor: event.category === "video" ? "#10c1d0" : "#70D3BA",
                          borderRadius: "8px",
                          overflow: "hidden",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "0.69rem",
                          zIndex: "1"
                        }}
                      >
                        <img src={Review} alt='Review for patient' />
                      </div>
                    }
                    {event.status !== 'cancelled' && ((event.category !== 'video' && user.category === 'doctor') || event.category === "video") && (
                      <div
                        onClick={joinURL}
                        style={{
                          cursor: "pointer",
                          border: "none",
                          padding: "0.75rem 1.13rem 0.75rem 1.25rem",
                          backgroundColor: event.category === "video" ? "#10c1d0" : "#70D3BA",
                          flex: "1",
                          borderRadius: "8px",
                          overflow: "hidden",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "0.69rem",
                          zIndex: "1"
                        }}
                      >
                        <img
                          style={{
                            height: "1rem",
                            width: "1.25rem",
                            position: "relative"
                          }}
                          alt=""
                          src={event.category === "video" ? Vector : Review}
                        />
                        <div
                          onClick={linkHandler}
                          style={{
                            height: "0.75rem",
                            flex: "1",
                            position: "relative",
                            fontSize: "0.88rem",
                            color: "#fff",
                            textAlign: "left",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          {event.category === "video" ? ["completed", "noshow"].includes(event.status) ? "Expired" : "Join" : ["completed", "noshow"].includes(event.status) ? "View" : "Add"}
                        </div>
                      </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: "0.5rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 0rem 0.5rem 0.13rem",
                boxSizing: "border-box",
                maxWidth: "100%"
              }}
            >
              <img
                style={{
                  flex: "1",
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                  zIndex: "1"
                }}
                loading="eager"
                alt=""
                src={Vector53}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: "5.56rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 0rem 0rem 0.13rem",
                boxSizing: "border-box",
                gap: "0.63rem"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  padding: "0rem 0.63rem"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.69rem",
                  }}
                >
                  <img
                    style={{
                      height: "0.78rem",
                      width: "0.8rem",
                      position: "relative",
                      zIndex: "1",
                    }}
                    loading="eager"
                    alt=""
                    src={Group60}
                  />
                  <div
                    style={{
                      position: "relative",
                      fontWeight: "500",
                      zIndex: "1",
                    }}
                  >{`Date & Time`}</div>
                </div>
              </div>
              <div
                style={{
                  height: "2.56rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  boxSizing: "border-box",
                  fontSize: "1rem",
                  color: "#303939",
                }}
                className={'pl-8 md:pl-[2.69rem]'}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                    gap: "1.25rem",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      lineHeight: "2.25rem",
                      fontWeight: "500",
                      zIndex: "1",
                    }}
                    className={'text-[12px] md:text-[14px]'}
                  >
                    {dateFormatter(event.app_date)}
                  </div>
                  <button
                    style={{
                      cursor: "pointer",
                      border: "none",
                      padding: "0.63rem 1.19rem 0.56rem 1.38rem",
                      backgroundColor: event.category === 'video' ? "#10c1d0" : '#70D3BA',
                      borderRadius: "10px",
                      overflow: "hidden",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      whiteSpace: "nowrap",
                      zIndex: "1",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        fontSize: "0.88rem",
                        fontWeight: "500",
                        color: "#07383d",
                        textAlign: "left",
                      }}
                    >
                      {event?.available_slot?.slot?.name}
                    </div>
                  </button>
                </div>
              </div>
              <img
                style={{
                  alignSelf: "stretch",
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                  zIndex: "1",
                }}
                loading="eager"
                alt=""
                src={Vector53}
              />
            </div>
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 2.5rem 0rem 0.81rem",
                boxSizing: "border-box",
                minHeight: "4.81rem",
                flexShrink: "0",
              }}
            >
              <div
                style={{
                  width: "19.94rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                  gap: "0.31rem",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    gap: "1.25rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "0.88rem",
                    }}
                  >
                    <img
                      style={{
                        height: "0.76rem",
                        width: "0.62rem",
                        position: "relative",
                        zIndex: "1",
                      }}
                      loading="eager"
                      alt=""
                      src={Group62}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "0.63rem",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          fontWeight: "500",
                          zIndex: "1",
                        }}
                      >
                        Patient Deatils
                      </div>
                      <div
                        style={{
                          position: "relative",
                          fontSize: "0.5rem",
                          fontWeight: "500",
                          color: "#07383d",
                          zIndex: "1",
                        }}
                      >
                        Full Name
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "4.63rem",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "2rem",
                      fontSize: "0.5rem",
                      color: "#07383d",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        fontWeight: "500",
                        zIndex: "1",
                      }}
                    >
                      Age
                    </div>
                    <div
                      style={{
                        position: "relative",
                        fontWeight: "500",
                        zIndex: "1",
                      }}
                    >
                      Gender
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0rem 0.44rem",
                    boxSizing: "border-box",
                    fontSize: "1.13rem",
                    color: "#000",
                  }}
                  className={'w-[16rem] md:w-[19.06rem]'}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      padding: "0rem 0.06rem 0rem 0rem",
                      gap: "1.25rem",
                    }}
                  >
                    <h4
                      style={{
                        margin: "0",
                        height: "1.69rem",
                        position: "relative",
                        fontSize: "inherit",
                        fontWeight: "500",
                        fontFamily: "inherit",
                        display: "flex",
                        alignItems: "center",
                        zIndex: "1",
                      }}
                    >
                      {event.patient?.first_name + " " + event.patient?.last_name}
                    </h4>
                    <div
                      style={{
                        width: "4.38rem",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        gap: "2.38rem",
                      }}
                    >
                      <div
                        style={{
                          height: "1.69rem",
                          position: "relative",
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                          zIndex: "1",
                        }}
                      >
                        {event.patient?.age || event?.patient?.date_of_birth?.getAge()}
                      </div>
                      <h3
                        style={{
                          margin: "0",
                          height: "1.69rem",
                          position: "relative",
                          fontSize: "inherit",
                          fontWeight: "500",
                          fontFamily: "inherit",
                          display: "flex",
                          alignItems: "center",
                          zIndex: "1",
                        }}
                      >
                        {event.patient?.gender?.[0].toUpperCase()}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img
              style={{
                alignSelf: "stretch",
                position: "relative",
                maxWidth: "100%",
                overflow: "hidden",
                maxHeight: "100%",
                flexShrink: "0",
                zIndex: "1",
              }}
              loading="eager"
              alt=""
              src={Vector53}
            />
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0rem 0.06rem",
              boxSizing: "border-box",
              gap: "1.06rem",
              maxWidth: "100%",
            }}
          >
            <div
              style={{
                height: "0.69rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 0.69rem",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.63rem",
                }}
              >
                <img
                  style={{
                    height: "0.7rem",
                    width: "0.64rem",
                    position: "relative",
                    minHeight: "0.69rem",
                    zIndex: "1",
                  }}
                  loading="eager"
                  alt=""
                  src={Group59}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    zIndex: "1",
                  }}
                >
                  Records
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 3.25rem",
                boxSizing: "border-box",
                maxWidth: "100%",
                fontSize: "0.88rem",
                color: "#000",
              }}
            >
              {
                record?.name ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "0.5rem",
                    }}
                  >
                    <img
                      style={{
                        height: "0.94rem",
                        width: "0.69rem",
                        position: "relative",
                        zIndex: "1",
                      }}
                      alt=""
                      src={Vector1}
                    />
                    <div style={{ position: "relative", zIndex: "1" }}>
                      <a className={'truncated-text'} href={record?.file_url} target={"_blank"}>
                        {record?.name?.slice(0, 20) + "..."}
                      </a>
                    </div>
                  </div>

                ) : (
                  <p>No Record Available</p>
                )
              }
            </div>
            <div
              style={{
                alignSelf: "stretch",
                height: "0.31rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 0rem 0.31rem",
                boxSizing: "border-box",
                maxWidth: "100%",
              }}
            >
              <img
                style={{
                  flex: "1",
                  position: "relative",
                  maxWidth: "100%",
                  overflow: "hidden",
                  maxHeight: "100%",
                  zIndex: "1",
                }}
                loading="eager"
                alt=""
                src={Vector53}
              />
            </div>
            <div
              style={{
                width: "22.5rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0rem 0.56rem",
                boxSizing: "border-box",
                maxWidth: "100%",
              }}
            >
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.44rem",
                  maxWidth: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                  }}
                >
                  <img
                    style={{
                      height: "0.63rem",
                      width: "0.87rem",
                      position: "relative",
                      zIndex: "1",
                    }}
                    alt=""
                    src={Vector2}
                  />
                  <div
                    style={{
                      position: "relative",
                      fontWeight: "500",
                      zIndex: "1",
                    }}
                  >
                    Payment
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "0rem 0rem 0rem 1.38rem",
                    fontSize: "0.5rem",
                    color: "#07383d",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "0.75rem",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        fontWeight: "500",
                        zIndex: "1",
                        width: 100
                      }}
                    >
                      Total Amount
                    </div>
                    <img
                      style={{
                        height: "0.03rem",
                        width: "8.88rem",
                        position: "relative",
                        zIndex: "1",
                      }}
                      loading="eager"
                      alt=""
                      src={Vector52}
                    />
                    <div
                      style={{
                        height: "1.69rem",
                        position: "relative",
                        fontSize: "1.13rem",
                        fontWeight: "500",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        whiteSpace: "nowrap",
                        zIndex: "1",
                      }}
                    >
                      {event.price || 0} $
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        : (
          <div className={"text-secondary font-semibold"}>{
            isPassed ? "Appointment Time has passed!" : "No Appointment Available!"
          }</div>
        )}

      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAlertClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Appointment Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You are going to cancel the appointment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className={'px-4 py-2 border border-secondary btn-round text-secondary'} onClick={handleAlertClose}>Disagree</button>
          <button className={'px-4 py-2 bg-primary btn-round text-white'} onClick={cancelAppointmentHandler}>Agree</button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

export default EventDetailCard;