import React from 'react'
import { Input } from "@nextui-org/react";
import { updateUserData } from "../../utils/axios";

export default function SecondaryEmail({onClose, onChange}){
  const [email, setEmail] = React.useState('')
  const emailId = React.useId()
  const addHandler = async () => {
    if (onChange){
      try {
        const response = await updateUserData({secondary_email: email})
        if(!response) {
          
        }
        if(response){
          onChange(email)
  
          onClose()
        }
        
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  }
  return (
    <div className={'absolute z-10 rounded-b-3xl top-0 left-0 bg-white px-20 w-full h-full flex flex-col items-center justify-center'}>
      <p className={'text-center'}>Your recovery email is used to reach you in case we detect unusual activity in your account</p>
      <Input
        type="email"
        id={emailId}
        name="email"
        placeholder="Enter Email Address"
        variant='bordered'
        value={email}
        onInput={(e) => setEmail(e.target?.value)}
        required
        className='w-full mt-12'
      />
      <div className={'flex gap-4 mt-4'}>
        <button className={'px-8 py-2 btn-round bg-primary border border-primary text-white'} onClick={addHandler}>Add</button>
        <button className={'px-8 py-2 btn-round bg-white border border-secondary text-secondary'} onClick={onClose}>Cancel</button>
      </div>
    </div>
  )
}