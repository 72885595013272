import React from 'react'

export default function LetUsHelpYou(){
  return (
    <section className="review-section flex flex-col lg:mt-[12rem] mt-[2rem]">
      <div
        className="text-secondary text-[2.5rem] line-height-[2.875rem] font-semibold w-full flex justify-center mb-8">
        Let us&nbsp;<span className="text-primary">Help you</span>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center gap-8">
        <div
          className="w-[20rem] sm:w-[27.5rem] h-[11.25rem] flex-shrink-0 bg-white btn-round flex flex-col gap-4 justify-center items-center">
          <div className="w-full flex gap-4 justify-center">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.60687 1.57598C3.58582 0.471708 5.20511 0.678603 6.01434 1.90488L7.0211 3.43047C7.66834 4.41133 7.61366 5.77498 6.84123 6.64634L6.6457 6.86686C6.64516 6.86838 6.64458 6.87003 6.64399 6.87182C6.63366 6.90292 6.60754 7.00196 6.63325 7.18994C6.68654 7.57958 6.96729 8.37143 8.10713 9.65717C9.25049 10.9469 9.94404 11.2509 10.2654 11.3068C10.4041 11.3309 10.4766 11.31 10.4997 11.3012L10.8259 10.9333C11.5235 10.1463 12.6112 9.99129 13.4882 10.5322L15.0135 11.4728C16.3172 12.2767 16.6206 14.2435 15.5787 15.4187L14.4447 16.698C14.0906 17.0973 13.608 17.4381 13.0116 17.5012C11.5554 17.6552 8.18218 17.4546 4.64585 13.4657C1.34603 9.7434 0.719155 6.50583 0.639916 4.92401C0.600764 4.14246 0.925919 3.47209 1.35374 2.98952L2.60687 1.57598ZM5.03649 2.64562C4.62744 2.02577 3.89259 1.98878 3.47517 2.45963L2.22204 3.87317C1.95746 4.17161 1.81865 4.51368 1.83576 4.85524C1.90046 6.14673 2.41955 9.09124 5.51415 12.5819C8.75893 16.2421 11.7482 16.3454 12.8939 16.2243C13.1205 16.2004 13.3535 16.0656 13.5763 15.8144L14.7104 14.5351C15.1997 13.9832 15.0715 12.9889 14.4161 12.5849L12.8908 11.6442C12.4814 11.3917 12.0019 11.4698 11.6942 11.8169L11.3306 12.227L10.9098 11.7987C11.3306 12.227 11.33 12.2276 11.3295 12.2283L11.3283 12.2295L11.326 12.2322L11.3209 12.2377L11.3095 12.2498C11.3013 12.2584 11.2918 12.2677 11.2811 12.2778C11.2596 12.298 11.233 12.321 11.2012 12.3452C11.1371 12.3938 11.0519 12.4472 10.9442 12.4927C10.7239 12.5859 10.4329 12.6358 10.0735 12.5733C9.3728 12.4514 8.45459 11.9123 7.23882 10.5408C6.01939 9.16532 5.55269 8.1391 5.44834 7.37595C5.39522 6.98754 5.43773 6.67652 5.51573 6.44166C5.55409 6.32615 5.59938 6.23406 5.64115 6.16412C5.66198 6.12925 5.68184 6.10005 5.69939 6.07629C5.70816 6.06441 5.71636 6.05388 5.72383 6.04468L5.73447 6.03186L5.73934 6.02619L5.74166 6.02353L5.74279 6.02225C5.74334 6.02162 5.74389 6.02101 6.16836 6.45297L5.74389 6.021L5.97288 5.76269C6.32859 5.36146 6.38027 4.68194 6.04324 4.17122L5.03649 2.64562Z"
                    fill="#10C1D0" />
              <path
                d="M9.42652 0.538757C9.47937 0.188994 9.78795 -0.0482679 10.1144 0.00835092C10.1346 0.0124996 10.1997 0.0255187 10.2337 0.033645C10.3019 0.0499061 10.3969 0.0749265 10.5153 0.111905C10.7523 0.185846 11.0833 0.307663 11.4806 0.502805C12.276 0.893516 13.3345 1.57698 14.4329 2.75384C15.5313 3.93069 16.1692 5.06479 16.5338 5.917C16.716 6.34266 16.8297 6.69731 16.8987 6.95118C16.9332 7.07813 16.9566 7.17995 16.9717 7.25293C16.9793 7.28943 16.9849 7.31873 16.9887 7.34038L16.9934 7.36707C17.0462 7.7168 16.8268 8.06118 16.5003 8.11781C16.1748 8.17427 15.8681 7.93809 15.814 7.59003C15.8123 7.58062 15.8077 7.55556 15.8028 7.53187C15.7929 7.48448 15.7759 7.40935 15.7489 7.30999C15.6949 7.11126 15.6011 6.81586 15.4452 6.45174C15.134 5.72438 14.5744 4.71999 13.5861 3.66112C12.5978 2.60226 11.6604 2.00263 10.9815 1.66917C10.6416 1.50223 10.366 1.4017 10.1804 1.34381C10.0877 1.31487 9.97117 1.2862 9.92694 1.27566C9.602 1.21764 9.37383 0.88752 9.42652 0.538757Z"
                fill="#10C1D0" />
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M9.60815 3.4889C9.699 3.14822 10.0304 2.95095 10.3484 3.04829L10.1839 3.66515C10.3484 3.04829 10.3481 3.0482 10.3484 3.04829L10.3495 3.04864L10.3507 3.04902L10.3534 3.04985L10.3596 3.05183L10.3754 3.0571C10.3874 3.06123 10.4025 3.0666 10.4204 3.07341C10.4563 3.08705 10.5036 3.10643 10.5617 3.13313C10.6781 3.18653 10.837 3.26901 11.0322 3.39279C11.423 3.64059 11.9565 4.05218 12.5831 4.72365C13.2099 5.39512 13.594 5.96668 13.8253 6.38537C13.9409 6.59452 14.0178 6.76481 14.0676 6.88942C14.0926 6.9517 14.1107 7.00247 14.1234 7.04089C14.1298 7.0601 14.1348 7.0762 14.1386 7.08909L14.1436 7.10602L14.1454 7.11265L14.1462 7.11551L14.1465 7.11682C14.1466 7.11713 14.1468 7.11805 13.5711 7.2943L14.1468 7.11805C14.2377 7.45874 14.0536 7.81379 13.7356 7.91113C13.4203 8.00771 13.0919 7.81456 12.9977 7.47919L12.9948 7.46997C12.9906 7.45711 12.9817 7.43181 12.9669 7.39486C12.9374 7.321 12.8838 7.20019 12.7947 7.03887C12.6167 6.71661 12.2952 6.22968 11.7364 5.63094C11.1775 5.0322 10.7231 4.68773 10.4223 4.497C10.2717 4.40152 10.1589 4.34413 10.09 4.31247C10.0556 4.29664 10.0319 4.2872 10.02 4.28264L10.0113 4.27948C9.69829 4.17865 9.51809 3.82669 9.60815 3.4889Z"
                    fill="#10C1D0" />
            </svg>
            <span className="text-primary flex-shrink-0 text-[1.02275rem] line-height-[1.096rem]">Help Line</span>
          </div>
          <div className="text-secondary font-semibold text-[1.625rem] line-height-[2.875rem] w-full text-center">
            +1 212 456 7890
          </div>
          <a href="tel:+12124567890"
             className="text-center btn-round bg-primary text-white w-[6.875rem] h-[2.5rem] p-[0.625rem]">
            Call Us
          </a>
        </div>
        <div
          className="w-[20rem] sm:w-[27.5rem] h-[11.25rem] flex-shrink-0 bg-white btn-round flex flex-col gap-4 justify-center items-center">
          <div className="w-full flex gap-4 justify-center">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd"
                    d="M14.3343 1.4005C13.976 1.26167 13.5861 1.22679 13.209 1.29984L12.9767 1.34483V2.73808C13.5831 2.62166 14.2098 2.67822 14.7859 2.90144C15.1434 3.03998 15.5318 3.07477 15.907 3.00289V1.60704C15.3994 1.67384 14.8814 1.6125 14.4005 1.42618L14.3343 1.4005ZM12.9767 4.02391L13.209 3.97892C13.5861 3.90587 13.976 3.94075 14.3343 4.07958C14.9603 4.32216 15.645 4.36748 16.2974 4.20952L16.3531 4.19602C16.8281 4.081 17.1628 3.65378 17.1628 3.16259V1.29268C17.1628 0.645119 16.5604 0.168031 15.9342 0.319662C15.5747 0.406739 15.1973 0.381757 14.8521 0.248038L14.7859 0.222357C14.208 -0.00150293 13.5794 -0.0577477 12.9714 0.0600449L12.5644 0.138882C12.0747 0.233746 11.7209 0.664597 11.7209 1.16596V4.11131H4.81395C4.77493 4.11131 4.73674 4.11489 4.69969 4.12174C4.59913 4.11482 4.49765 4.11131 4.39535 4.11131C1.96786 4.11131 0 6.08982 0 8.53047V12.9642C0 14.3507 1.11796 15.4748 2.49703 15.4748H7.11628V17.3687C7.11628 17.7173 7.39741 18 7.74419 18C8.09096 18 8.37209 17.7173 8.37209 17.3687V15.4748H10.4651V17.3687C10.4651 17.7173 10.7463 18 11.093 18C11.4398 18 11.7209 17.7173 11.7209 17.3687V15.4748H15.5245C16.8916 15.4748 18 14.3604 18 12.9859V8.53047C18 6.08982 16.0321 4.11131 13.6047 4.11131H12.9767V4.02391ZM11.7209 5.37392V8.1096C11.7209 8.45824 12.0021 8.7409 12.3488 8.7409C12.6956 8.7409 12.9767 8.45824 12.9767 8.1096V5.37392H13.6047C15.3386 5.37392 16.7442 6.78714 16.7442 8.53047V12.9859C16.7442 13.6631 16.1981 14.2122 15.5245 14.2122H8.7907V8.53047C8.7907 7.29387 8.28553 6.176 7.47142 5.37392H11.7209ZM7.53488 14.2122V8.53047C7.53488 6.78714 6.12927 5.37392 4.39535 5.37392C2.66143 5.37392 1.25581 6.78714 1.25581 8.53047V12.9642C1.25581 13.6534 1.81152 14.2122 2.49703 14.2122H7.53488ZM2.51163 12.3183C2.51163 11.9695 2.79275 11.687 3.13953 11.687H5.65116C5.99794 11.687 6.27907 11.9695 6.27907 12.3183C6.27907 12.6669 5.99794 12.9496 5.65116 12.9496H3.13953C2.79275 12.9496 2.51163 12.6669 2.51163 12.3183Z"
                    fill="#10C1D0" />
            </svg>
            <span className="text-primary flex-shrink-0 text-[1.02275rem] line-height-[1.096rem]">Email Address</span>
          </div>
          <div
            className="text-secondary font-semibold text-[0.725rem] sm:text-[1.625rem] line-height-[2.875rem] w-full text-center">
            Helpdesk@connectmd.com
          </div>
          <a href="mail:Helpdesk@connectmd.com"
             className="text-center btn-round bg-primary text-white w-[6.875rem] h-[2.5rem] p-[0.625rem]">
            Email
          </a>
        </div>
      </div>
    </section>
  )
}