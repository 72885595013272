import { Checkbox, Input } from "@nextui-org/react";
import React from 'react';
import { validator } from './validations';
import UploadIcon from "../../../assets/icons/UploadIcon";

const PhysicianRegistration = ({ formik }) => {
  return (
    <>

      <div className="mb-4 mt-8 text-sm">Documents</div>
      <div className="grid grid-cols-2 gap-2">
        <div className="col-span-1 relative">
          <div>
            <Input
              value={formik.values.bio?.name}
              type="text"
              disabled
              placeholder="Upload bio *"
              variant="bordered"
              isInvalid={validator(formik, "bio")}
              className="bg-primary-dark-1 w-full"
            />
            <UploadIcon
              className="absolute cursor-pointer top-0 right-0 m-3"
              onClick={() => {
                const ele = document.getElementById("bio");
                if (ele) {
                  ele.click();
                }
              }}
            />
            <Input
              type="file"
              id="bio"
              name="bio"
              placeholder="Bio *"
              variant="bordered"
              isInvalid={validator(formik, "bio")}
              onInput={(event) => {
                const fileInput = event.target;
                formik.setFieldValue("bio", fileInput.files?.[0]);
              }}
              onBlur={formik.handleBlur}
              className="w-full text-base hidden"
            />
          </div>
          {validator(formik, "bio") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.bio}
            </div>
          )}
        </div>
        <div className="col-span-1 relative">
          <div>
            <Input
              value={formik.values.w9_tax_form?.name}
              type="text"
              disabled
              placeholder="W-9 Tax Form *"
              variant="bordered"
              isInvalid={validator(formik, "w9_tax_form")}
              className="bg-primary-dark-1 w-full"
            />
            <UploadIcon
              className="absolute cursor-pointer top-0 right-0 m-3"
              onClick={() => {
                const ele = document.getElementById("w9_tax_form");
                if (ele) {
                  ele.click();
                }
              }}
            />
            <Input
              type="file"
              id="w9_tax_form"
              name="w9_tax_form"
              placeholder="w9_tax_form *"
              variant="bordered"
              isInvalid={validator(formik, "w9_tax_form")}
              onInput={(event) => {
                const fileInput = event.target;
                formik.setFieldValue("w9_tax_form", fileInput.files?.[0]);
              }}
              onBlur={formik.handleBlur}
              className="w-full text-base hidden"
            />
          </div>
          {validator(formik, "w9_tax_form") && (
            <div className="w-full mt-1 text-sm text-danger">
              {formik.errors.w9_tax_form}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1">
        <div className="col-span-1 relative">
          <Input
            value={formik.values.pli?.name}
            type="text"
            disabled
            placeholder="Professional Liability Insurance *"
            variant="bordered"
            isInvalid={validator(formik, "pli")}
            className="bg-primary-dark-1 w-full mt-4"
          />
          <UploadIcon
            className="absolute cursor-pointer top-0 right-0 m-4 mt-7"
            onClick={() => {
              const ele = document.getElementById("pli");
              if (ele) {
                ele.click();
              }
            }}
          />
          <Input
            type="file"
            id="pli"
            name="pli"
            placeholder="pli *"
            variant="bordered"
            isInvalid={validator(formik, "pli")}
            onInput={(event) => {
              const fileInput = event.target;
              formik.setFieldValue("pli", fileInput.files?.[0]);
            }}
            onBlur={formik.handleBlur}
            className="w-full text-base hidden"
          />
        </div>
        {validator(formik, "pli") && (
          <div className="w-full mt-1 text-sm text-danger">
            {formik.errors.pli}
          </div>
        )}
      </div>
      <br />
      <div className='grid grid-cols-1'>
        <textarea
          value={formik.values.about}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id='about'
          name='about'
          label='Description'
          variant='bordered'
          placeholder='Tell Us about Yourself'
          disableAnimation
          disableAutosize
          className={`w-full h-73 min-h-[18rem] outline-none !shadow-none rounded border ${validator(formik, "about") ? 'border-danger' : 'border-primary'}`}
        />
        {validator(formik, "about") && <div className='w-full mt-1 text-sm text-danger'>{formik.errors.about}</div>}
      </div>
      <div className='mt-4 grid grid-cols-1'>
        <Checkbox
          isInvalid={validator(formik, "agreement_accepted")}
          checked={formik.values.agreement_accepted}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          id='agreement_accepted'
          name='agreement_accepted'
        >
          I agree to the{' '}
          <span className='text-secondary'>Terms Of Use</span> and{' '}
          <span className='text-secondary'>Privacy Policy</span> of
          this website
        </Checkbox>
        {validator(formik, "agreement_accepted") &&
          <div className='w-full mt-1 text-sm text-danger'>{formik.errors.agreement_accepted}</div>}
      </div>
    </>
  );
};

export default PhysicianRegistration;
