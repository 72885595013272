import React from 'react';

export default function DeleteIcon(props) {
    return (
        <svg width="19" {...props} height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.0244 0.735509C9.88975 0.585468 9.69952 0.5 9.5 0.5C9.30049 0.5 9.11025 0.585468 8.97563 0.735509L5.18614 4.95965C4.92135 5.25481 4.94148 5.71285 5.23109 5.98271C5.52071 6.25257 5.97013 6.23206 6.23492 5.9369L8.78948 3.08932V13.7759C8.78948 14.1758 9.1076 14.5 9.5 14.5C9.8924 14.5 10.2105 14.1758 10.2105 13.7759V3.08932L12.7651 5.9369C13.0299 6.23206 13.4793 6.25257 13.7689 5.98271C14.0585 5.71285 14.0786 5.25481 13.8138 4.95965L10.0244 0.735509Z" fill="#0A4E54" />
            <path d="M1.46154 13.2C1.46154 12.8134 1.13437 12.5 0.73077 12.5C0.327181 12.5 5.10518e-07 12.8134 5.10518e-07 13.2V13.2512C-1.89767e-05 14.5277 -3.85336e-05 15.5565 0.113533 16.3657C0.23145 17.2058 0.483702 17.9131 1.07019 18.4748C1.65667 19.0367 2.39511 19.2783 3.27214 19.3913C4.11688 19.5 5.19094 19.5 6.52346 19.5H12.4766C13.8091 19.5 14.8831 19.5 15.7279 19.3913C16.6049 19.2783 17.3433 19.0367 17.9299 18.4748C18.5163 17.9131 18.7686 17.2058 18.8865 16.3657C19 15.5565 19 14.5277 19 13.2512V13.2C19 12.8134 18.6728 12.5 18.2692 12.5C17.8657 12.5 17.5385 12.8134 17.5385 13.2C17.5385 14.5397 17.5369 15.4741 17.438 16.1791C17.3418 16.864 17.1661 17.2266 16.8964 17.4849C16.6267 17.7433 16.2481 17.9117 15.5331 18.0038C14.7971 18.0985 13.8217 18.1 12.4231 18.1H6.57692C5.17832 18.1 4.20287 18.0985 3.46689 18.0038C2.75193 17.9117 2.37331 17.7433 2.10365 17.4849C1.83399 17.2266 1.65816 16.864 1.56204 16.1791C1.46309 15.4741 1.46154 14.5397 1.46154 13.2Z" fill="#0A4E54" />
        </svg>

    )
}