import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from "dayjs";

export default function CalendarMenu({children, value, setValue}) {
  const calendar = React.useRef(null)
  const [open, setOpen] = React.useState(false)
  const [calValue, setCalValue] = React.useState(dayjs())
  const openHandler = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    setCalValue(value)
  }, [value])
  return (
    <div ref={calendar} className={'w-full gap-4 text-center mt-4 p-4 relative flex justify-center'}>
      <button className={'z-20 flex gap-4 justify-center items-center'} onClick={openHandler}>
        <span>{children}</span> {open ? <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6569 16.2427L19.0711 14.8285L12.0001 7.75739L4.92896 14.8285L6.34317 16.2427L12.0001 10.5858L17.6569 16.2427Z"
          fill="currentColor"
        />
      </svg> : <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z"
          fill="currentColor"
        />
      </svg>}
      </button>
      {open && (
        <div className={"z-10 absolute top-[35px] p-4 shadow-1 bg-white h-fit left-0"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar minDate={dayjs()} value={calValue} onChange={(newValue) => setValue(newValue)} />
          </LocalizationProvider>
        </div>
      )}
    </div>
  );
}
