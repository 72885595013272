import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CalendarIcon from "../assets/icons/Calendar";
import PatientsIcon from "../assets/icons/Patients";
import ReviewIcon from "../assets/icons/Review";
import VideoIcon from "../assets/icons/Video";
import CalenderView from "./doctor_app/CalenderView";
import AppointmentsView from "./doctor_app/appointments"
import ReviewView from "./doctor_app/reviews"
import PatientView from "./doctor_app/history"
import { getMyData } from "../utils/axios";
import { Box, Button, Modal } from "@mui/material";

function Dashboard() {
  const [selectedTab, setSelectedTab] = useState('calendar');
  const [physicianData, setPhysicianData] = useState({
    is_active: true
  });
  const tabs = [{
    icon: CalendarIcon,
    label: 'My Calendar',
    value: 'calendar'
  }, {
    icon: VideoIcon,
    label: 'Video Consultancy',
    value: 'appointments'
  }, {
    icon: ReviewIcon,
    label: 'Review of Records',
    value: 'reviews'
  }, {
    icon: PatientsIcon,
    label: 'My Patients',
    value: 'patients'
  }]

  const View = () => {

    switch (selectedTab) {
      case "calendar":
        return <CalenderView />;
      case "appointments":
        return <AppointmentsView />;
      case "reviews":
        return <ReviewView />
      case "patients":
        return <PatientView />
      default:
        return selectedTab
    }
  }

  const getData = async () => {
    setPhysicianData(await getMyData());
  }

  useEffect(() => {
    getData();
  }, [])

  React.useEffect(() => {
    var url = new URL(window.location.href);
    var selectedTab = url.searchParams.get("tab");
    const existance = ["appointments", "reviews", "patients", "calendar"].includes(selectedTab?.toLowerCase())
    if (existance) {
      setSelectedTab(selectedTab)
    }
  }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  return (
    <>
      <Modal
        open={!physicianData?.is_active}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div style={{ ...style }} className="bg-white flex flex-col items-center w-full p-5 w-[90%] md:w-[600px] lg:w-[800px] gap-4 rounded-[16px]">
          <span className="text-[20px] font-bold">
            Profile Not Approved
          </span>
          <span>
            You Profile has not been approved by the admin yet. You can only use activity after your profile is approved.
          </span>
          <button className="p-3 bg-primary text-white rounded-lg" onClick={() => window.location.replace('/physicians/profile')} >Back to Profile</button>
        </div>
      </Modal>
      <div className="window">
        <nav>
          <ul className="tab-list sbwo">
            {tabs.map((item) => (
              <li
                key={item.value}
                className={item.value === selectedTab ? "selected tab" : "tab"}
                onClick={() => setSelectedTab(item.value)}
              >
                <div className='backgroung-svg-tab'>
                  <item.icon />
                  {`${item.label}`}
                  {item.value === selectedTab ? (
                    <motion.div className="underline" layoutId="underline" />
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <main style={{
          background: "white",
          width: '100%',
          height: 'calc(45rem + 8rem)',
          paddingBottom: '2rem',
          borderBottomLeftRadius: '2rem',
          borderBottomRightRadius: '2rem',
        }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedTab ? selectedTab.label : "empty"}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
              className={'overflow-x-auto overflow-y-hidden sbwo'}
            >
              {selectedTab ? View() : "no tab selected"}
            </motion.div>
          </AnimatePresence>
        </main>
      </div>
    </>
  );
}

export default Dashboard;
