import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Select, SelectItem, Input, Image } from "@nextui-org/react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDisclosure } from "@nextui-org/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./profile.css";
import Countries from "../../utils/countries";
import {
  deleteCertification,
  getMyRecords,
  updatePhysicianData,
  uploadMyRecord
} from "../../utils/axios";
import { fileNameSeprator } from "../../components/Booking/Step3";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import Joyride, { STATUS } from 'react-joyride';
import { LinearProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function EditDoctorProfile({ genders = [], data = {}, states = [], countries = [], specialties, user_specialities }) {
  const [disabledEdit, setDisableEdit] = React.useState(true);
  const [specialtiesData, setSpecialtiesData] = React.useState(specialties || []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = React.useState(null)
  const [patientData, setPatientData] = React.useState(data || {})
  const [displayImage, setDisplayImage] = React.useState(null)
  const [documents, setDocuments] = React.useState([])
  const inputRef = React.useRef(null)
  const [openAlert, setOpenAlert] = React.useState(false);
  const [certId, setCertId] = React.useState(false);
  const [run, setRun] = useState(true);
  const [completion, setCompletion] = useState(0);
  const [isFirstLogin, setIsFirstLogin] = useState(null);
  const [steps, setSteps] = useState([
    {
      target: '.professional-info-step',
      title: "1/6",
      content: 'Add Professional Info',
      disableBeacon: true,
    },
    {
      target: '.location-step',
      title: "2/6",
      content: 'Add Location Info',
      disableBeacon: true
    },
    {
      target: '.speciality-step',
      title: "3/6",
      content: 'Add atleast one Speciality',
      disableBeacon: true
    },
    {
      target: '.bio-step',
      title: "4/6",
      content: 'Add your Bio',
      disableBeacon: true,
      placement: "left"
    },
    {
      target: '.board-cert-step',
      title: "5/6",
      content: 'Add atleast one Board Certification',
      disableBeacon: true
    },
    {
      target: '.docs-step',
      title: "6/6",
      content: 'Add your documents',
      disableBeacon: true
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };

  const handleAlertOpen = (id) => {
    setCertId(id)
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };
  const onFieldChange = (event, type, name) => {
    if (type === 'select') {
      setPatientData({ ...patientData, [name]: event.currentKey })
    } else if (type === 'date') {
      setPatientData({ ...patientData, [name]: event.toDate() })
    } else {
      const { target } = event
      if (target.name === 'experience') {
        const value = parseInt(target.value, 10);
        if (isNaN(value) || value < 0 || value > 70) {
          return;
        }
      }
      setPatientData({ ...patientData, [target.name]: target.value })
    }
  }
  const fetchData = async () => {
    const res = await getMyRecords()
    if (res) {
      setDocuments(res)
    } else {
      setDocuments([])
    }
  }

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  const imageConversion = (input) => {

    if (input) {
      const reader = new FileReader();

      reader.onload = function (e) {
        // Create a new image element
        const blob = dataURLtoBlob(e.target.result);

        // Create a blob:http URL
        const blobUrl = URL.createObjectURL(blob);
        setPatientData({ ...patientData, display_image_url: blobUrl });
        // Set the blob URL as the source for the preview image
      }
      // Read the selected file as a data URL
      reader.readAsDataURL(input);
    }
  }

  const uploadDocHandler = async () => {
    const { ext, fileName } = fileNameSeprator(selected.name)
    const response = await uploadMyRecord({ name: fileName + "." + ext, selected })
    if (response) {
      toast.success("Document has been Uploaded!")
    } else {
      toast.error("Error while uploading document!")
    }
    setSelected(null)
    fetchData()
    onClose()
  }

  const updateRecord = async () => {
    const formData = new FormData()
    Object.keys(patientData).forEach((key) => {
      formData.append(key, patientData[key]);
    });
    formData.append('display_image', displayImage)
    formData.append(`user_specialities_attributes`, JSON.stringify(patientData.user_specialities))
    formData.append('certifications', JSON.stringify(patientData.board_certifications))
    const response = await updatePhysicianData(formData)
    if (response) {
      toast.success("Data has been Updated!")
    } else {
      toast.error("Error while updating data!")
    }
    setDisplayImage(null)
    !!isFirstLogin ? window.location.replace("/physicians/profile") : window.location.reload()
  }

  const calculateCompletion = (profile) => {
    let completedFields = 0;
    const totalFields = 7;
    if (profile.first_name && profile.last_name && profile.email) completedFields++;
    if (profile.nin && profile.experience) completedFields++;
    if (profile?.country &&
      profile.country !== "null" &&
      profile?.street_address &&
      profile?.street_address !== "null" &&
      profile?.city &&
      profile.city !== "null" &&
      profile.location_id &&
      profile.location_id !== "null" &&
      profile.zip_code &&
      profile.zip_code !== "null")
      completedFields++;
    if (profile.user_specialities
      && profile.user_specialities.length > 0 &&
      profile.user_specialities?.[0]?.speciality_id &&
      profile.user_specialities?.[0]?.location_id &&
      profile.user_specialities?.[0]?.licence_number &&
      profile.user_specialities?.[0]?.exp_date)
      completedFields++;
    if (profile.about && profile.about !== "null") completedFields++;
    if (profile.board_certifications &&
      profile.board_certifications.length > 0 &&
      profile.board_certifications?.[0]?.certification &&
      profile.board_certifications?.[0]?.speciality_id &&
      profile.board_certifications?.[0]?.exp_date)
      completedFields++;
    if (profile.bio_url && profile.w9_tax_form_url && profile.pli_url) completedFields++;

    return (completedFields / totalFields) * 100;
  };

  const deleteCertificationHandler = async () => {
    const res = await deleteCertification(certId)
    if (res) {
      toast.success("Certification Deleted!")
      removeCertification(certId)
    } else {
      toast.error("Error while deleting Certification!")
    }
  }
  const addCertificate = () => {
    setPatientData({ ...patientData, board_certifications: [...patientData.board_certifications, { id: "", certification: "", exp_date: new Date(), speciality_id: 1 }] })
  }

  const removeCertification = (id) => {
    const idx = patientData.board_certifications.findIndex(i => i.id === id)
    const certifications = patientData.board_certifications.filter((bc, index) => index !== idx)
    setCertId(null)
    handleAlertClose()
    setPatientData({ ...patientData, board_certifications: certifications })
  }

  const addSpecialty = () => {
    const updatedSpecialities = [...patientData.user_specialities, { id: "", name: "", location_id: "", exp_date: "" }];
    setPatientData({ ...patientData, user_specialities: updatedSpecialities })
  };


  const submissionHandler = (e) => {
    e.preventDefault()
    setDisableEdit(true);
    updateRecord()
  }
  React.useEffect(() => {
    const params = new URL(document.location.toString()).searchParams;
    setIsFirstLogin(params.get("is_first_login"));
    setDisableEdit(!(!!params.get("is_first_login")))
    setSpecialtiesData(specialties.sort((a, b) => a.name.localeCompare(b.name)))
    fetchData()
  }, [])

  useEffect(() => {
    if (!patientData?.user_specialities?.length) {
      addSpecialty();
    }
    if (!patientData.board_certifications.length) {
      addCertificate();
    }
    setCompletion(calculateCompletion(patientData));
  }, [patientData])

  return (
    <>
      <Joyride
        continuous
        steps={steps}
        run={isFirstLogin}
        spotlightClicks={true}
        showSkipButton={false}
        callback={handleJoyrideCallback}
        hideCloseButton
        styles={{
          buttonNext: {
            backgroundColor: "#10C1D0",
            border: "1px solid #10C1D0",
            borderRadius: "4px"
          },
          buttonBack: {
            backgroundColor: 'white',
            color: "black",
            border: '1px solid black',
            borderRadius: "4px"
          },
          tooltip: {
            display: 'flex',
            flexDirection: 'column',
          },
        }}
        locale={{
          back: "Previous",
          next: "Next",
          last: "Finish"
        }}
      />
      <div className="grid grid-cols-12 profile">
        <div
          style={{ padding: "25px" }}
          className="col-span-12 lg:col-span-2 flex flex-row lg:flex-col gap-4 items-start lg:items-center justify-center lg:justify-start"
        >
          <div className={"image-upload-container relative w-full"}>
            {!disabledEdit &&
              <div onClick={() => inputRef.current.click()}
                className={"upload-dp w-full h-full z-50 absolute top-0 left-0 glass-effect flex justify-center items-center cursor-pointer"}>
                <input ref={inputRef} className={"hidden"} type="file" accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    if (e.target.files) {
                      const file = e.target.files[0];
                      imageConversion(file);
                      setDisplayImage(file);
                    }
                  }} />
                <CloudUploadIcon color={"white"} />
              </div>
            }
            <div
              className={"w-[100%] min-w-[100px] h-[200px]"}
              style={{
                background: `url(${patientData.display_image_url})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: 'lightgray',
                borderRadius: '10px'
              }}
            >
            </div>
            <div className="flex flex-row justify-between mt-2">
              <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                htmlFor="nin">ID: {patientData.slug}</label>
              <span className="text-[12px]">{`${Math.round(completion)}%`}</span>
            </div>
            <LinearProgress
              sx={{
                width: "100%",
                height: 10,
                borderRadius: 7,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#07383D',
                  borderRadius: 7
                },
              }}
              variant="determinate" value={completion} />
          </div>
          <div className={'flex flex-col gap-4'}>
            {disabledEdit &&
              <button
                type={"button"}
                onClick={() => setDisableEdit(false)}
                className="bg-primary text-[14px] px-8 w-full text-xl py-4 text-white rounded-xl"
              >
                Edit
              </button>
            }
            {!disabledEdit &&
              <button
                type={"submit"}
                onClick={submissionHandler}
                className="bg-primary text-[14px] px-8 w-full text-xl py-4 text-white rounded-xl"
              >
                Save
              </button>
            }

            <a
              href={"/physicians/profile/" + patientData.slug}
              className="bg-white text-[14px] text-center border border-primary text-secondary px-8 w-full text-xl py-4 rounded-xl"
            >
              View Profile
            </a>
          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-5" style={{ padding: "25px" }}>
          <div className="mb-[24px] basic-info">
            <div
              style={{
                color: "#0A4E54",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal"
              }}
              className="mb-8"
            >
              Basic Information
            </div>
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>First Name</label>
                <Input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="First Name *"
                  variant="bordered"
                  isDisabled={disabledEdit}
                  value={patientData?.first_name}
                  onInput={onFieldChange}
                  className="w-full text-base"
                />
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Last Name</label>
                <Input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name *"
                  variant="bordered"
                  isDisabled={disabledEdit}
                  value={patientData?.last_name}
                  onInput={onFieldChange}
                  className="w-full text-base"
                />
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Gender</label>
                <Select
                  variant="bordered"
                  id="gender"
                  name="gender"
                  placeholder="Select Gender"
                  isDisabled={disabledEdit}
                  selectedKeys={[patientData?.gender]}
                  onSelectionChange={(event) => onFieldChange(event, "select", "gender")}
                  className="max-w-xs select capitalize"
                >
                  {Object.keys(genders).map((gender) => (
                    <SelectItem className={"capitalize"} key={gender} value={gender}>
                      {gender}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Date Of Birth</label>
                <LocalizationProvider className="p-0" dateAdapter={AdapterDayjs}>
                  <DemoContainer className="p-0" components={["DatePicker"]}>
                    <DatePicker
                      sx={{
                        input: {
                          color: "!formik.values.date_of_birth"
                            ? "#5E5E5E"
                            : "var(--bg-secondary)"
                        },
                        fieldset: {
                          borderColor: "var(--primary) !important"
                        }
                      }}
                      value={dayjs(patientData?.date_of_birth)}
                      onSelectionChange={(event) => onFieldChange(event, "select", "date")}
                      disabled={disabledEdit}
                      error={false}
                      name="date_of_birth"
                      id="date_of_birth"
                      maxDate={dayjs().subtract(18, "year")}
                      className="w-full"
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
          <div className="professional-info-step basic-info mb-[24px]">
            <div
              style={{
                color: "#0A4E54",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal"
              }}
              className="mb-[12px]"
            >
              Professional Information
            </div>
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"} htmlFor="nin">National Provider Identifier</label>
                <Input
                  type="text"
                  id="nin"
                  name="nin"
                  placeholder="National Provider Identifier *"
                  variant="bordered"
                  isDisabled={disabledEdit}
                  value={patientData?.nin !== "null" ? patientData?.nin : null}
                  onInput={onFieldChange}
                  className="w-full text-base"
                />
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                  htmlFor="experience">Years Of Experience</label>
                <Input
                  type="number"
                  id="experience"
                  min={0}
                  max={70}
                  name="experience"
                  placeholder="Expereience *"
                  variant="bordered"
                  isDisabled={disabledEdit}
                  value={patientData.experience}
                  onInput={onFieldChange}
                  className="w-full text-base"
                />
              </div>
            </div>
          </div>
          <div className="location-step basic-info mb-[24px]">
            <div
              style={{
                color: "#0A4E54",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal"
              }}
              className="mb-[5px] flex justify-between"
            >
              Location
            </div>
            <div className="mt-4 grid grid-cols-1 gap-2">
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                  htmlFor="country">Full Address</label>
                <Select
                  variant="bordered"
                  id="gender"
                  name="gender"
                  placeholder="Select Country"
                  isDisabled={disabledEdit}
                  selectedKeys={patientData?.country && patientData?.country !== "null" ? [patientData?.country] : null}
                  onSelectionChange={(event) => onFieldChange(event, "select", "country")}
                  className="select bg-[#D8F7FA]"
                >
                  <SelectItem key="US" value="US">United States of America</SelectItem>
                </Select>
              </div>
            </div>
            <div className="mt-4 grid grid-cols-2 gap-2">
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Street Address</label>
                <Input
                  type="text"
                  id="street_address"
                  name="street_address"
                  placeholder="Street Address *"
                  variant="bordered"
                  isDisabled={disabledEdit}
                  value={patientData?.street_address !== "null" ? patientData?.street_address : null}
                  onInput={onFieldChange}
                  className="w-full text-base"
                />
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>City</label>
                <Input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="City *"
                  value={patientData?.city !== "null" ? patientData?.city : null}
                  onInput={onFieldChange}
                  variant="bordered"
                  isDisabled={disabledEdit}
                  className="w-full text-base"
                />
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>State</label>
                <Select
                  variant="bordered"
                  id="location_id"
                  name="location_id"
                  selectedKeys={patientData.location_id ? [String(patientData.location_id)] : null}
                  onSelectionChange={(event) => onFieldChange(event, "select", "location_id")}
                  placeholder="Select State"
                  isDisabled={disabledEdit}
                  className="max-w-xs select"
                >
                  {states.map((state) => (
                    <SelectItem key={state.id} value={state.id}>
                      {state.state}
                    </SelectItem>
                  ))}
                </Select>
              </div>
              <div className="col-span-1">
                <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Zip Code</label>
                <Input
                  type="text"
                  id="zip_code"
                  name="zip_code"
                  placeholder="Zip Code *"
                  variant="bordered"
                  value={patientData?.zip_code !== "null" ? patientData?.zip_code : null}
                  onInput={onFieldChange}
                  isDisabled={disabledEdit}
                  className="w-full text-base"
                />
              </div>
            </div>
          </div>
          <div className="speciality-step basic-info mb-[24px]">
            <div
              style={{
                color: "#0A4E54",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal"
              }}
              className="mb-[5px] flex justify-between items-end"
            >
              <span>Speciality</span>
              {!disabledEdit && (
                <>
                  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"
                    onClick={addSpecialty}>
                    <rect x="0.5" y="0.5" width="29" height="29" rx="7.5" stroke="#0A4E54" />
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M15.8643 10C15.8643 9.44772 15.4166 9 14.8643 9C14.312 9 13.8643 9.44772 13.8643 10V13.8647H10C9.44772 13.8647 9 14.3124 9 14.8647C9 15.417 9.44772 15.8647 10 15.8647L13.8643 15.8647V19.7293C13.8643 20.2816 14.312 20.7293 14.8643 20.7293C15.4166 20.7293 15.8643 20.2816 15.8643 19.7293V15.8647L19.7293 15.8647C20.2816 15.8647 20.7293 15.417 20.7293 14.8647C20.7293 14.3124 20.2816 13.8647 19.7293 13.8647H15.8643V10Z"
                      fill="#0A4E54" />
                  </svg>
                </>
              )}
            </div>
            {!patientData?.user_specialities?.length && (
              <div className={"w-full flex justify-center text-danger font-semibold"}>
                No Specialty Found!
              </div>
            )}
            {patientData?.user_specialities?.map((specialty, idx) => {
              return (
                <>
                  <div key={specialty.id + "__" + specialty.name} className="mt-4 grid grid-cols-6 gap-2">
                    <div className="col-span-3">
                      <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                        htmlFor="country">Speciality</label>
                      <Select
                        variant="bordered"
                        id={`user_specialities_attributes[${idx}][speciality_id]`}
                        name={`user_specialities_attributes[${idx}][speciality_id]`}
                        placeholder="Select Specialty"
                        isDisabled={disabledEdit}
                        selectedKeys={specialty.speciality_id ? [String(specialty.speciality_id)] : null}
                        onSelectionChange={(event) => {
                          // if (!!specialty.id) return
                          const specs = patientData.user_specialities?.map((s, i) => {
                            if (i === idx) {
                              return { ...s, speciality_id: event.currentKey }
                            } else {
                              return s
                            }
                          })
                          setPatientData({ ...patientData, user_specialities: specs });
                        }}
                        className="select bg-[#D8F7FA]"
                      >
                        {specialtiesData?.map((s) => (
                          <SelectItem key={s.id} value={s.id}>
                            {s.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col-span-3">
                      <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                        htmlFor="country">Specialty State Location</label>
                      <Select
                        variant="bordered"
                        id={`user_specialities_attributes[${idx}][location_id]`}
                        name={`user_specialities_attributes[${idx}][location_id]`}
                        selectedKeys={specialty.location_id ? [String(specialty.location_id)] : null}
                        placeholder="Select State"
                        isDisabled={disabledEdit}
                        onSelectionChange={(event) => {
                          // if (!!specialty.id) return
                          const specs = patientData.user_specialities?.map((s, i) => {
                            if (i === idx) {
                              return { ...s, location_id: event.currentKey }
                            } else {
                              return s
                            }
                          })
                          setPatientData({ ...patientData, user_specialities: specs });
                        }}
                        className="max-w-xs select bg-[#D8F7FA]"
                      >
                        {states.map((state) => (
                          <SelectItem key={state.id} value={state.id}>
                            {state.state}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                    <div className="col-span-4">
                      <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                        htmlFor="license_number">License Number</label>
                      <Input
                        type="text"
                        id={`user_specialities_attributes[${idx}][license_number]`}
                        name={`user_specialities_attributes[${idx}][license_number]`}
                        placeholder="License Number *"
                        variant="bordered"
                        value={specialty.licence_number}
                        onInput={(event) => {
                          const specs = patientData.user_specialities?.map((s, i) => {
                            if (i === idx) {
                              return { ...s, licence_number: event.target.value }
                            } else {
                              return s
                            }
                          })
                          setPatientData({ ...patientData, user_specialities: specs });
                        }}
                        isDisabled={disabledEdit}
                        className="w-full text-base bg-[#D8F7FA]"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}
                        htmlFor="exp_date">Exp. date</label>
                      <LocalizationProvider
                        className="p-0"
                        dateAdapter={AdapterDayjs}
                      >
                        <DemoContainer className="p-0" components={["DatePicker"]}>
                          <DatePicker
                            sx={{
                              input: {
                                paddingLeft: "15px !important",
                              },
                              fieldset: {
                                borderColor: "var(--primary) !important"
                              }
                            }}
                            error={false}
                            disabled={disabledEdit}
                            minDate={dayjs().add(90, "day")}
                            value={dayjs(specialty.exp_date)}
                            onChange={(event) => {
                              const specs = patientData.user_specialities?.map((s, i) => {
                                if (i === idx) {
                                  return { ...s, exp_date: event.toDate() < new Date() ? new Date : event.toDate() }
                                } else {
                                  return s
                                }
                              })
                              setPatientData({ ...patientData, user_specialities: specs });
                            }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </div>

                  <br />
                  <hr />
                </>
              )
            })}

          </div>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-5" style={{ padding: "25px" }}>
          <div className={"bio-step grid grid-cols-1"}>
            <div
              className="mb-[12px] flex justify-between col-span-1"
              style={{
                color: "#0A4E54",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal"
              }}
            >
              <span>Your Bio</span>
            </div>
            <div
              className="mb-[12px] flex justify-between col-span-1"
            >
              <textarea
                disabled={disabledEdit}
                value={patientData?.about !== "null" ? patientData?.about : null}
                onChange={(event) => {
                  setPatientData({ ...patientData, about: event.target.value });
                }}
                className={"border border-primary btn-round h-[15rem] w-full bg-transparent"}
                placeholder={"Tell Us about Yourself"}
              ></textarea>
            </div>
          </div>
          <div className={"grid grid-cols-1"}>
            <div className="board-cert-step basic-info mb-[24px]">
              <div
                style={{
                  color: "#0A4E54",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal"
                }}
                className="mb-[5px] flex justify-between items-end"
              >
                <span>Board Certification</span>
                {!disabledEdit && (
                  <>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" className={'cursor-pointer'} onClick={addCertificate}>
                      <rect x="0.5" y="0.5" width="29" height="29" rx="7.5" stroke="#0A4E54" />
                      <path fillRule="evenodd" clipRule="evenodd"
                        d="M15.8643 10C15.8643 9.44772 15.4166 9 14.8643 9C14.312 9 13.8643 9.44772 13.8643 10V13.8647H10C9.44772 13.8647 9 14.3124 9 14.8647C9 15.417 9.44772 15.8647 10 15.8647L13.8643 15.8647V19.7293C13.8643 20.2816 14.312 20.7293 14.8643 20.7293C15.4166 20.7293 15.8643 20.2816 15.8643 19.7293V15.8647L19.7293 15.8647C20.2816 15.8647 20.7293 15.417 20.7293 14.8647C20.7293 14.3124 20.2816 13.8647 19.7293 13.8647H15.8643V10Z"
                        fill="#0A4E54" />
                    </svg>
                  </>
                )}
              </div>
              {patientData.board_certifications.length === 0 && (
                <div className={'w-full text-center font-semibold text-gray'}>
                  No Certification Found
                </div>
              )}
              {patientData.board_certifications.map((bc, idx) => {
                return (
                  <>
                    <div key={idx + "_" + Math.ceil(Math.random())} className="mt-4 grid grid-cols-6 gap-2">
                      <div className="col-span-6">
                        <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Speciality</label>
                        <Select
                          variant="bordered"
                          id={`board_certifications_attributes[${idx}][speciality_id]`}
                          name={`board_certifications_attributes[${idx}][speciality_id]`}
                          placeholder="Select Specialty"
                          isDisabled={disabledEdit}
                          selectedKeys={bc.speciality_id ? [String(bc.speciality_id)] : null}
                          onSelectionChange={(event) => {
                            // if (!!bc.speciality_id) return
                            const specs = patientData.board_certifications?.map((s, i) => {
                              if (i === idx) {
                                return { ...s, speciality_id: parseInt(event.currentKey) }
                              } else {
                                return s
                              }
                            })
                            console.log(specs);
                            setPatientData({ ...patientData, board_certifications: specs });
                          }}
                          className="select bg-[#D8F7FA]"
                        >
                          {specialtiesData?.map((s) => (
                            <SelectItem key={s.id} value={s.id}>
                              {s.name}
                            </SelectItem>
                          ))}
                        </Select>
                      </div>
                      <div className="col-span-3">
                        <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Certification Number</label>
                        <Input
                          type="text"
                          id={`board_certifications_attributes[${idx}][certification]`}
                          name={`board_certifications_attributes[${idx}][certification]`}
                          placeholder="Certification *"
                          variant="bordered"
                          value={bc.certification}
                          onInput={(event) => {
                            const specs = patientData.board_certifications?.map((s, i) => {
                              if (i === idx) {
                                return { ...s, certification: event.target.value }
                              } else {
                                return s
                              }
                            })
                            setPatientData({ ...patientData, board_certifications: specs });
                          }}
                          isDisabled={disabledEdit}
                          className="w-full text-base bg-[#D8F7FA]"
                        />
                      </div>
                      <div className="col-span-3">
                        <label className={"mb-[5px] text-secondary line-height-[18px] text-[12px] font-[400]"}>Exp. Date</label>
                        <LocalizationProvider
                          className="p-0"
                          dateAdapter={AdapterDayjs}
                        >
                          <DemoContainer className="p-0" components={["DatePicker"]}>
                            <DatePicker
                              sx={{
                                input: {
                                  paddingLeft: "15px !important",
                                  color: true
                                    ? "#5E5E5E"
                                    : "var(--bg-secondary)"
                                },
                                fieldset: {
                                  borderColor: "var(--primary) !important"
                                }
                              }}
                              id={`board_certifications_attributes[${idx}][exp_date]`}
                              name={`board_certifications_attributes[${idx}][exp_date]`}
                              error={false}
                              disabled={disabledEdit}
                              minDate={dayjs().add(90, "day")}
                              value={dayjs(bc.exp_date) || null}
                              onChange={(event) => {
                                const specs = patientData.board_certifications?.map((s, i) => {
                                  if (i === idx) {
                                    return { ...s, exp_date: event.toDate() < new Date() ? new Date : event.toDate() }
                                  } else {
                                    return s
                                  }
                                })
                                setPatientData({ ...patientData, board_certifications: specs });
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                      <div className={"col-span-1"}>
                        {(!disabledEdit && bc.id && (patientData.board_certifications.length > 1)) && (
                          <>
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" className={'cursor-pointer'} onClick={() => handleAlertOpen(bc.id)}>
                              <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                              <path
                                d="M23.4453 19.6951C23.6715 19.0549 24.2821 18.5976 24.998 18.5976C25.714 18.5976 26.3246 19.0549 26.5509 19.6951C26.6518 19.9808 26.9654 20.1306 27.2512 20.0296C27.5369 19.9286 27.6867 19.6151 27.5857 19.3293C27.2092 18.2643 26.1936 17.5 24.998 17.5C23.8025 17.5 22.7868 18.2643 22.4104 19.3293C22.3094 19.6151 22.4592 19.9286 22.745 20.0296C23.0307 20.1306 23.3442 19.9808 23.4453 19.6951Z"
                                fill="#EE2348" />
                              <path
                                d="M18.2305 20.9755C18.2305 20.6725 18.4762 20.4268 18.7792 20.4268H31.2183C31.5214 20.4268 31.7671 20.6725 31.7671 20.9755C31.7671 21.2786 31.5214 21.5243 31.2183 21.5243H18.7792C18.4762 21.5243 18.2305 21.2786 18.2305 20.9755Z"
                                fill="#EE2348" />
                              <path
                                d="M19.9615 22.2573C20.2639 22.2372 20.5254 22.466 20.5456 22.7684L20.8821 27.8165C20.9479 28.8028 20.9947 29.489 21.0976 30.0053C21.1973 30.5061 21.3366 30.7712 21.5367 30.9584C21.7367 31.1455 22.0105 31.2669 22.5169 31.3331C23.0389 31.4014 23.7267 31.4025 24.7151 31.4025H25.281C26.2694 31.4025 26.9572 31.4014 27.4792 31.3331C27.9855 31.2669 28.2593 31.1455 28.4594 30.9584C28.6594 30.7712 28.7987 30.5061 28.8985 30.0053C29.0013 29.489 29.0482 28.8028 29.1139 27.8165L29.4505 22.7684C29.4706 22.466 29.7321 22.2372 30.0345 22.2573C30.3369 22.2775 30.5657 22.539 30.5456 22.8414L30.2065 27.928C30.1439 28.8667 30.0934 29.6248 29.9749 30.2197C29.8517 30.8382 29.6421 31.3549 29.2092 31.7598C28.7764 32.1648 28.2469 32.3396 27.6216 32.4214C27.02 32.5 26.2602 32.5 25.3195 32.5H24.6765C23.7358 32.5 22.976 32.5 22.3745 32.4214C21.7491 32.3396 21.2197 32.1648 20.7868 31.7598C20.354 31.3549 20.1444 30.8382 20.0212 30.2197C19.9027 29.6248 19.8521 28.8667 19.7896 27.928L19.4505 22.8414C19.4303 22.539 19.6591 22.2775 19.9615 22.2573Z"
                                fill="#EE2348" />
                            </svg>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )
              })}

            </div>
            <div className="docs-step basic-info mb-[24px]">
              <div
                style={{
                  color: "#0A4E54",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "normal"
                }}
                className="mb-[5px] flex justify-between items-end"
              >
                Docs
              </div>
              <div className="mt-4 grid grid-cols-7 gap-2">
                <div className="col-span-6">
                  <div className={'w-full flex truncated-text cursor-pointer justify-start px-8 items-center btn-round bg-[#D8F7FA] h-full text-secondary'}>
                    {patientData?.pli?.name || "Professional Liability Insurance *"}
                  </div>
                  <input
                    type="file"
                    id="pli"
                    name="pli"
                    placeholder="Professional Liability Insurance *"
                    onChange={(event) => setPatientData({ ...patientData, pli: event.target.files[0] })}
                    style={{ display: 'none' }}
                    className="w-full text-base bg-[#D8F7FA]"
                  />
                </div>
                <div className={"col-span-1"}>
                  {!disabledEdit ? (
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => document.getElementById('pli').click()} className={'cursor-pointer'}>
                      <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                      <path
                        d="M19 26.2C19 28.4627 19 29.594 19.5858 30.297C20.1716 31 21.1144 31 23 31H27C28.8856 31 29.8284 31 30.4142 30.297C31 29.594 31 28.4627 31 26.2"
                        stroke="#1C274C" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M24.9992 27.8V19M24.9992 19L27.7992 21.9615M24.9992 19L22.1992 21.9615" stroke="#1C274C"
                        strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <a href={patientData.pli_url} style={{
                      opacity: patientData.pli_url ? 1 : 0.4,
                      pointerEvents: patientData.pli_url ? "auto" : "none"
                    }} target={"_blank"}>
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9994 21.7856C23.2242 21.7856 21.7852 23.2247 21.7852 24.9999C21.7852 26.7752 23.2242 28.2142 24.9994 28.2142C26.7747 28.2142 28.2137 26.7752 28.2137 24.9999C28.2137 23.2247 26.7747 21.7856 24.9994 21.7856ZM23.0709 24.9999C23.0709 23.9348 23.9344 23.0714 24.9994 23.0714C26.0645 23.0714 26.928 23.9348 26.928 24.9999C26.928 26.065 26.0645 26.9285 24.9994 26.9285C23.9344 26.9285 23.0709 26.065 23.0709 24.9999Z"
                          fill="#07383D" />
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9994 17.5C21.1302 17.5 18.524 19.8179 17.0114 21.783L16.9841 21.8185C16.642 22.2627 16.3269 22.6719 16.1132 23.1558C15.8843 23.6739 15.7852 24.2386 15.7852 25C15.7852 25.7614 15.8843 26.3261 16.1132 26.8442C16.3269 27.3281 16.642 27.7373 16.9841 28.1815L17.0114 28.2169C18.524 30.1821 21.1302 32.5 24.9994 32.5C28.8687 32.5 31.4749 30.1821 32.9875 28.2169L33.0148 28.1815C33.3569 27.7373 33.6719 27.3281 33.8857 26.8442C34.1146 26.3261 34.2137 25.7614 34.2137 25C34.2137 24.2386 34.1146 23.6739 33.8857 23.1558C33.6719 22.6719 33.3569 22.2627 33.0148 21.8184L32.9875 21.783C31.4749 19.8179 28.8687 17.5 24.9994 17.5ZM18.0302 22.5673C19.4268 20.7528 21.6998 18.7857 24.9994 18.7857C28.2991 18.7857 30.5721 20.7528 31.9687 22.5673C32.3446 23.0556 32.5648 23.3475 32.7096 23.6753C32.845 23.9817 32.928 24.3562 32.928 25C32.928 25.6438 32.845 26.0183 32.7096 26.3247C32.5648 26.6525 32.3446 26.9443 31.9687 27.4327C30.5721 29.2472 28.2991 31.2143 24.9994 31.2143C21.6998 31.2143 19.4268 29.2472 18.0302 27.4327C17.6543 26.9443 17.4341 26.6525 17.2892 26.3247C17.1539 26.0183 17.0709 25.6438 17.0709 25C17.0709 24.3562 17.1539 23.9817 17.2892 23.6753C17.4341 23.3475 17.6543 23.0556 18.0302 22.5673Z"
                          fill="#07383D" />
                      </svg>
                    </a>
                  )}

                </div>
              </div>
              <div className="mt-4 grid grid-cols-7 gap-2">
                <div className="col-span-6">
                  <div
                    className={"w-full flex truncated-text cursor-pointer justify-start px-8 items-center btn-round bg-[#D8F7FA] h-full text-secondary"}>
                    {patientData?.w9_tax_form?.name || "W9 Tax Form *"}
                  </div>
                  <input
                    type="file"
                    id="w9_tax_form"
                    name="w9_tax_form"
                    placeholder="W9 Tax Form *"
                    onChange={(event) => setPatientData({ ...patientData, w9_tax_form: event.target.files[0] })}
                    style={{ display: "none" }}
                    className="w-full text-base bg-[#D8F7FA]"
                  />
                </div>
                <div className={"col-span-1"}>
                  {!disabledEdit ? (
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" onClick={() => document.getElementById('w9_tax_form').click()} xmlns="http://www.w3.org/2000/svg">
                      <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                      <path
                        d="M19 26.2C19 28.4627 19 29.594 19.5858 30.297C20.1716 31 21.1144 31 23 31H27C28.8856 31 29.8284 31 30.4142 30.297C31 29.594 31 28.4627 31 26.2"
                        stroke="#1C274C" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M24.9992 27.8V19M24.9992 19L27.7992 21.9615M24.9992 19L22.1992 21.9615" stroke="#1C274C"
                        strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <a href={patientData.w9_tax_form_url} style={{
                      opacity: patientData.w9_tax_form_url ? 1 : 0.4,
                      pointerEvents: patientData.w9_tax_form_url ? "auto" : "none"
                    }} target={"_blank"}>
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9994 21.7856C23.2242 21.7856 21.7852 23.2247 21.7852 24.9999C21.7852 26.7752 23.2242 28.2142 24.9994 28.2142C26.7747 28.2142 28.2137 26.7752 28.2137 24.9999C28.2137 23.2247 26.7747 21.7856 24.9994 21.7856ZM23.0709 24.9999C23.0709 23.9348 23.9344 23.0714 24.9994 23.0714C26.0645 23.0714 26.928 23.9348 26.928 24.9999C26.928 26.065 26.0645 26.9285 24.9994 26.9285C23.9344 26.9285 23.0709 26.065 23.0709 24.9999Z"
                          fill="#07383D" />
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9994 17.5C21.1302 17.5 18.524 19.8179 17.0114 21.783L16.9841 21.8185C16.642 22.2627 16.3269 22.6719 16.1132 23.1558C15.8843 23.6739 15.7852 24.2386 15.7852 25C15.7852 25.7614 15.8843 26.3261 16.1132 26.8442C16.3269 27.3281 16.642 27.7373 16.9841 28.1815L17.0114 28.2169C18.524 30.1821 21.1302 32.5 24.9994 32.5C28.8687 32.5 31.4749 30.1821 32.9875 28.2169L33.0148 28.1815C33.3569 27.7373 33.6719 27.3281 33.8857 26.8442C34.1146 26.3261 34.2137 25.7614 34.2137 25C34.2137 24.2386 34.1146 23.6739 33.8857 23.1558C33.6719 22.6719 33.3569 22.2627 33.0148 21.8184L32.9875 21.783C31.4749 19.8179 28.8687 17.5 24.9994 17.5ZM18.0302 22.5673C19.4268 20.7528 21.6998 18.7857 24.9994 18.7857C28.2991 18.7857 30.5721 20.7528 31.9687 22.5673C32.3446 23.0556 32.5648 23.3475 32.7096 23.6753C32.845 23.9817 32.928 24.3562 32.928 25C32.928 25.6438 32.845 26.0183 32.7096 26.3247C32.5648 26.6525 32.3446 26.9443 31.9687 27.4327C30.5721 29.2472 28.2991 31.2143 24.9994 31.2143C21.6998 31.2143 19.4268 29.2472 18.0302 27.4327C17.6543 26.9443 17.4341 26.6525 17.2892 26.3247C17.1539 26.0183 17.0709 25.6438 17.0709 25C17.0709 24.3562 17.1539 23.9817 17.2892 23.6753C17.4341 23.3475 17.6543 23.0556 18.0302 22.5673Z"
                          fill="#07383D" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
              <div className="mt-4 grid grid-cols-7 gap-2">
                <div className="col-span-6">
                  <div
                    className={"w-full flex px-8 truncated-text cursor-pointer justify-start items-center btn-round bg-[#D8F7FA] h-full text-secondary"}>
                    {patientData?.bio?.name || `Your Bio *`}
                  </div>
                  <input
                    type="file"
                    id="bio"
                    name="bio"
                    placeholder="Your Bio *"
                    onChange={(event) => {setPatientData({ ...patientData, bio: event.target.files[0] })}}
                    style={{ display: "none" }}
                    className="w-full text-base bg-[#D8F7FA]"
                  />
                </div>
                <div className={"col-span-1"}>
                  {!disabledEdit ? (
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" onClick={() => document.getElementById('bio').click()} xmlns="http://www.w3.org/2000/svg">
                      <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                      <path
                        d="M19 26.2C19 28.4627 19 29.594 19.5858 30.297C20.1716 31 21.1144 31 23 31H27C28.8856 31 29.8284 31 30.4142 30.297C31 29.594 31 28.4627 31 26.2"
                        stroke="#1C274C" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M24.9992 27.8V19M24.9992 19L27.7992 21.9615M24.9992 19L22.1992 21.9615" stroke="#1C274C"
                        strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <a href={patientData.bio_url} style={{
                      opacity: patientData.bio_url ? 1 : 0.4,
                      pointerEvents: patientData.bio_url ? "auto" : "none"
                    }} target={"_blank"}>
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9994 21.7856C23.2242 21.7856 21.7852 23.2247 21.7852 24.9999C21.7852 26.7752 23.2242 28.2142 24.9994 28.2142C26.7747 28.2142 28.2137 26.7752 28.2137 24.9999C28.2137 23.2247 26.7747 21.7856 24.9994 21.7856ZM23.0709 24.9999C23.0709 23.9348 23.9344 23.0714 24.9994 23.0714C26.0645 23.0714 26.928 23.9348 26.928 24.9999C26.928 26.065 26.0645 26.9285 24.9994 26.9285C23.9344 26.9285 23.0709 26.065 23.0709 24.9999Z"
                          fill="#07383D" />
                        <path fillRule="evenodd" clipRule="evenodd"
                          d="M24.9994 17.5C21.1302 17.5 18.524 19.8179 17.0114 21.783L16.9841 21.8185C16.642 22.2627 16.3269 22.6719 16.1132 23.1558C15.8843 23.6739 15.7852 24.2386 15.7852 25C15.7852 25.7614 15.8843 26.3261 16.1132 26.8442C16.3269 27.3281 16.642 27.7373 16.9841 28.1815L17.0114 28.2169C18.524 30.1821 21.1302 32.5 24.9994 32.5C28.8687 32.5 31.4749 30.1821 32.9875 28.2169L33.0148 28.1815C33.3569 27.7373 33.6719 27.3281 33.8857 26.8442C34.1146 26.3261 34.2137 25.7614 34.2137 25C34.2137 24.2386 34.1146 23.6739 33.8857 23.1558C33.6719 22.6719 33.3569 22.2627 33.0148 21.8184L32.9875 21.783C31.4749 19.8179 28.8687 17.5 24.9994 17.5ZM18.0302 22.5673C19.4268 20.7528 21.6998 18.7857 24.9994 18.7857C28.2991 18.7857 30.5721 20.7528 31.9687 22.5673C32.3446 23.0556 32.5648 23.3475 32.7096 23.6753C32.845 23.9817 32.928 24.3562 32.928 25C32.928 25.6438 32.845 26.0183 32.7096 26.3247C32.5648 26.6525 32.3446 26.9443 31.9687 27.4327C30.5721 29.2472 28.2991 31.2143 24.9994 31.2143C21.6998 31.2143 19.4268 29.2472 18.0302 27.4327C17.6543 26.9443 17.4341 26.6525 17.2892 26.3247C17.1539 26.0183 17.0709 25.6438 17.0709 25C17.0709 24.3562 17.1539 23.9817 17.2892 23.6753C17.4341 23.3475 17.6543 23.0556 18.0302 22.5673Z"
                          fill="#07383D" />
                      </svg>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={openAlert}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleAlertClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Deletion Confirmation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              You are going to delete board certification.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className={'px-4 py-2 border border-secondary btn-round text-secondary'} onClick={handleAlertClose}>Disagree</button>
            <button className={'px-4 py-2 bg-primary btn-round text-white'} onClick={deleteCertificationHandler}>Agree</button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
