import React from 'react'
import { Input } from "@nextui-org/react";
import EyeSlashFilledIcon from "../assets/icons/EyeSlashFilledIcon";
import EyeFilledIcon from "../assets/icons/EyeFilledIcon";
import { validator } from "./registration/patient/validations";
import { updatePassword, updateUserData } from "../utils/axios";
import { toast } from "react-toastify";
function ResetPassword({ id }) {

  const [isVisible, setIsVisible] = React.useState(false);
  const [isCVisible, setIsCVisible] = React.useState(false);
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
  const toggleVisibility = () => setIsVisible(!isVisible);
  const toggleCVisibility = () => setIsCVisible(!isCVisible);

  const submitHandler = async () => {
    const response = await updatePassword(id,{password})
    if(response){
      toast.success("Password Changed!")
      window.location.href = response.path
    } else {
      toast.error("Error while changing password!")
    }
  }
  return (
    <div className='grid grid-cols-12'>
      {id ? <div className='p-8 col-start-1 md:col-start-5 flex flex-col col-span-12 md:col-span-4 mt-8 md:mt-24'>
        <h1 className='text-primary text-center text-4xl font-medium'>
          Update Password!
        </h1>
        <div>
          <Input
            id="password"
            name="password"
            aria-label={"Password"}
            variant="bordered"
            placeholder="Password *"
            value={password}
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleVisibility}
              >
                {isVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                )}
              </button>
            }
            type={isVisible ? "text" : "password"}
            className="w-full mt-4"
            onChange={(e) => setPassword(e.target.value)}
          />
          {!RegExp.test(password) && (
            <div className="w-full mt-1 text-sm text-danger">
              Password is Required, must be at least 8 characters long, At least one lowercase and one uppercase letter
              is required, At least one number is required in password!
            </div>
          )}
          <Input
            id="confirm_password"
            name="confirm_password"
            aria-label={"Confirm Password"}
            variant="bordered"
            placeholder="Confirm Password *"
            value={confirmPassword}
            endContent={
              <button
                className="focus:outline-none"
                type="button"
                onClick={toggleCVisibility}
              >
                {isCVisible ? (
                  <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                ) : (
                  <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                )}
              </button>
            }
            type={isCVisible ? "text" : "password"}
            className="w-full mt-4"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {(password !== confirmPassword) && (
            <div className="w-full mt-1 text-sm text-danger">
              Password not match!
            </div>
          )}
        </div>
        <button onClick={submitHandler} className='mt-9 btn-round bg-primary text-white py-3.5 px-8 w-fit self-center'>
          Change Password
        </button>
      </div> : (
        <>
          <div className={'w-full col-span-12 text-center text-xl text-secondary font-bold h-[400px] flex justify-center items-center'}>Broken Link!</div>
        </>
      )}
    </div>
  );
}

export default ResetPassword;
