
import React, { useEffect, useState } from 'react';
import { addPhysicianPaymentInfo, getPhysicianPaymentInfo, updateUserData } from '../../utils/axios';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';

export default function DoctorSettings({ data, fetchData }) {
  const [videoFee, setVideoFee] = useState(0);
  const [reviewFee, setReviewFee] = useState(0);

  const addPaymentHandler = async () => {
    if (videoFee === 0 || reviewFee === 0) {
      toast.error('Enter valid amount');
      return; // Exit the function early if either value is zero
    }

    try {
      const response = await updateUserData({ video_consultancy_price: videoFee, review_of_record_price: reviewFee });
      if (response) {
        console.log('before notification bar');

        if (fetchData) fetchData();
        toast.success('Payment method updated successfully');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }

  };

  useEffect(() => {
    setVideoFee(Number(data.video_consultancy_price));
    setReviewFee(Number(data.review_of_record_price));
  }, [data]);

  const handleVideoFeeChange = (e) => {
    const value = Number(e.target.value);
    setVideoFee(value);
    if (value === 0) {
      toast.error('Enter valid amount');
    }
  };

  const handleReviewFeeChange = (e) => {
    const value = Number(e.target.value);
    setReviewFee(value);
    if (value === 0) {
      toast.error('Enter valid amount');
    }
  };

  return (
    <div className='absolute z-10 py-[60px] rounded-b-3xl top-0 left-0 bg-white px-20 w-full h-full flex flex-col items-center justify-center'>
      <div className='relative flex flex-col justify-between h-full w-full'>
        <div>
          <div className='text-[16px] font-[500] mb-8 text-[#0A4E54] mb-[26px]'>Service Charges</div>
          <div className='grid grid-cols-2 gap-8'>
            <div className='col-span-1 flex flex-col gap-2'>
              <label htmlFor='video-consultancy' className='text-secondary font-[400] text-[14px] mb-[7px]'>Video Consultancy</label>
              <div className='flex flex-row gap-2 items-center'>
                <input
                  value={videoFee}
                  onInput={handleVideoFeeChange}
                  min={1}
                  type='number'
                  id='video-consultancy'
                  className='bg-[#CDF7FB] border w-3/4 border-[#10C1D0] outline-none btn-round px-[22px] py-[12px] text-[#07383D] font-[400] text-[16px]'
                  aria-label='video-consultancy'
                />
                <p className='text-[16px] font-[500] text-[#0A4E54]'>$</p>
              </div>
            </div>
            <div className='col-span-1 flex flex-col gap-2'>
              <label htmlFor='review-of-records' className='text-secondary font-[400] text-[14px] mb-[7px]'>Review of Records</label>
              <div className='flex flex-row gap-2 items-center'>
                <input
                  value={reviewFee}
                  onInput={handleReviewFeeChange}
                  min={1}
                  type='number'
                  id='review-of-records'
                  className='bg-[#DCFAF2] border w-3/4 border-[#10C1D0] outline-none btn-round px-[22px] py-[12px] text-[#07383D] font-[400] text-[16px]'
                  aria-label='review-of-records'
                />
                <p className='text-[16px] font-[500] text-[#0A4E54]'>$</p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className='relative flex gap-8 justify-center items-center'>
            <button onClick={addPaymentHandler} className='px-12 py-4 btn-round bg-secondary border border-secondary text-white hover:bg-primary-light hover:text-gray-400'>Update</button>
          </div>
        </div>
        <PhysicianPayment data={data} />
      </div>
    </div>
  );
}

const PaymentForm = () => {
  const [bankAccountDetails, setBankAccountDetails] = useState({
    country: 'US',
    currency: 'usd',
    account_holder_name: '',
    account_holder_type: 'individual',
    routing_number: '',
    account_number: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankAccountDetails({ ...bankAccountDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission or validation here
    console.log('Submitted bank account details:', bankAccountDetails);
    // You can then proceed with further processing such as creating a token or making an API call to Stripe
  };

  return (
    <form onSubmit={handleSubmit} className='max-w-md mx-auto mt-8'>
      <div className='mb-4'>
        <label htmlFor='account_holder_name' className='block text-gray-700 font-bold mb-2'>Account Holder Name</label>
        <input
          type='text'
          id='account_holder_name'
          name='account_holder_name'
          value={bankAccountDetails.account_holder_name}
          onChange={handleChange}
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          required
        />
      </div>
      <div className='mb-4'>
        <label htmlFor='routing_number' className='block text-gray-700 font-bold mb-2'>Routing Number</label>
        <input
          type='text'
          id='routing_number'
          name='routing_number'
          value={bankAccountDetails.routing_number}
          onChange={handleChange}
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          required
        />
      </div>
      <div className='mb-4'>
        <label htmlFor='account_number' className='block text-gray-700 font-bold mb-2'>Account Number</label>
        <input
          type='text'
          id='account_number'
          name='account_number'
          value={bankAccountDetails.account_number}
          onChange={handleChange}
          className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          required
        />
      </div>
      <div className='flex items-center justify-between'>
        <button type='submit' className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>Submit</button>
      </div>
    </form>
  );
};

const PhysicianPayment = ({ data }) => {
  const [isLinked, setIsLinked] = useState(false);

  const getPhysicianPayments = async () => {
    const paymentInfo = await getPhysicianPaymentInfo();
    setIsLinked(paymentInfo.is_linked);
  };

  useEffect(() => {
    getPhysicianPayments();
  }, []);

  return (
    <div className='flex flex-col gap-4 justify-center items-center text-lg'>
      {isLinked ? <p>Payment method is linked with this account</p> : <AddPaymentButton />}
    </div>
  );
};

const AddPaymentButton = () => {
  const [loading, setLoading] = useState(false);

  const addPhysicianPaymentInfoHandler = async () => {
    setLoading(true);
    const response = await addPhysicianPaymentInfo();
    setLoading(false);
    if (response && response.result) {
      window.location = response.result.url;
    }
  };

  return (
    <>
      <div className='font-semibold text-secondary'>Add Payment Method</div>
      <button type='button' onClick={addPhysicianPaymentInfoHandler} className='px-12 py-4 flex flex-row items-center gap-2 btn-round bg-primary border border-primary text-white'>
        {loading && <CircularProgress style={{ 'color': 'white', height: 20, width: 20 }} />}
        Let's Go
      </button>
    </>
  );
};
