import React from 'react';
import PropTypes from 'prop-types';
export default function AccountSettingIcon({ color }) {
    return (
      <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M6.103 0C4.27277 0 2.78906 1.48371 2.78906 3.31395C2.78906 5.1442 4.27277 6.62791 6.103 6.62791C7.93327 6.62791 9.41695 5.1442 9.41695 3.31395C9.41695 1.48371 7.93327 0 6.103 0ZM3.83557 3.31395C3.83557 2.06168 4.85074 1.04651 6.103 1.04651C7.35532 1.04651 8.37044 2.06168 8.37044 3.31395C8.37044 4.56623 7.35532 5.5814 6.103 5.5814C4.85074 5.5814 3.83557 4.56623 3.83557 3.31395Z"
              fill={color || "#A9A9A9"} />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M6.10472 7.67441C4.49064 7.67441 3.00353 8.04131 1.90157 8.66113C0.816013 9.2718 8.9126e-05 10.1973 8.9126e-05 11.3372L4.7375e-05 11.4084C-0.000740997 12.2189 -0.00173183 13.2363 0.890615 13.9629C1.32978 14.3205 1.94414 14.5748 2.77419 14.7429C3.60656 14.9113 4.69141 15 6.10472 15C7.51807 15 8.60295 14.9113 9.43528 14.7429C10.2654 14.5748 10.8797 14.3205 11.3189 13.9629C12.2112 13.2363 12.2102 12.2189 12.2094 11.4084L12.2094 11.3372C12.2094 10.1973 11.3935 9.2718 10.3079 8.66113C9.20595 8.04131 7.71886 7.67441 6.10472 7.67441ZM1.0466 11.3372C1.0466 10.7433 1.48012 10.0989 2.41464 9.57327C3.33274 9.05685 4.63633 8.72092 6.10472 8.72092C7.57318 8.72092 8.87672 9.05685 9.79486 9.57327C10.7294 10.0989 11.1629 10.7433 11.1629 11.3372C11.1629 12.2496 11.1347 12.7632 10.658 13.1514C10.3995 13.362 9.96739 13.5675 9.22765 13.7172C8.49028 13.8664 7.48214 13.9535 6.10472 13.9535C4.72737 13.9535 3.71921 13.8664 2.98181 13.7172C2.24209 13.5675 1.80994 13.362 1.55143 13.1514C1.07473 12.7632 1.0466 12.2496 1.0466 11.3372Z"
              fill={color || "#A9A9A9"} />
      </svg>
    )
}

AccountSettingIcon.propTypes = {
    color: PropTypes.string
}