import React, { useState, useId } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AccountSettingIcon from "../assets/icons/AccountSetting";
import PaymentIcon from "../assets/icons/Payment";
import Payment from "./settings/Payment"
import AccountSetting from "./settings/main";
import { deactivateAccount, getMyData } from "../utils/axios";
import { toast } from "react-toastify";

export default function SettingsPage(props) {
  const [selectedTab, setSelectedTab] = useState("account");
  const [data, setData] = useState({});
  const tabs = [{
    icon: AccountSettingIcon,
    label: "Account Setting",
    value: "account"
  }, {
    icon: PaymentIcon,
    label: "Payout Information",
    value: "payment"
  }
  ];

  const deactivationHandler = async () => {
    const response = await deactivateAccount()
    if(response){
      toast.success("Account Deactivation Email sent valid for 15 minutes!")
    } else {
      toast.error("Error: while deactivating account!")
    }
  }

  const fetchData = async () => {
    const response = await getMyData()
    if(response){
      setData(response)
    } else {
      setData({})
    }
  }

  const View = () => {
    switch (selectedTab) {
      case "account":
        return <AccountSetting fetchData={fetchData} data={data} />;
      case "payment":
        return <Payment fetchData={fetchData} data={data} isDoctor={props.is_doctor} stripe_env={props.stripe_key} />;
      default:
        return <AccountSetting fetchData={fetchData} data={data} />;
    }
  };

  React.useEffect(()=>{
    fetchData()
  }, [])

  return (
    <div className={"flex flex-col gap-4 items-center"}>
      <div className="window w-full sm:w-[630px]">
        <nav>
          <ul className="tab-list">
            {tabs.map((item) => (
              <li
                key={item.value}
                className={item.value === selectedTab ? "selected tab" : "tab"}
                onClick={() => setSelectedTab(item.value)}
              >
                <div className="backgroung-svg-tab">
                  <item.icon />
                  {`${item.label}`}
                  {item.value === selectedTab ? (
                    <motion.div className="underline" layoutId="underline" />
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </nav>
        <main style={{
          background: "linear-gradient(0deg, #000000 0%, #07383D 0.01%, rgba(255, 255, 255, 0) 103.78%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
          width: "100%",
          height: "calc(42rem + 8rem)",
          borderBottomLeftRadius: "2rem",
          borderBottomRightRadius: "2rem",
          padding: "1px",
        }}>
          <div className={"after"}>
            <AnimatePresence mode="wait">
              <motion.div
                key={selectedTab ? selectedTab.label : "empty"}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {selectedTab ? View() : "no tab selected"}
              </motion.div>
            </AnimatePresence>
          </div>
        </main>
      </div>
      <button
        onClick={deactivationHandler}
        className={'px-8 py-2 text-base text-danger border border-secondary bg-white btn-round'}
      >
        Deactivate Account
      </button>
    </div>
  );
}
