import React from 'react'
import Slider from "react-slick";
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function NextArrow(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        right: "-72px",
        top: "50%",
        transform: "translateY(-50%)"
      }}
      onClick={onClick}
      className={"w-[50px] h-[50px] flex justify-center items-center cursor-pointer rounded-[50%] border border-[#D7D7D7] absolute"}
    >
      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 17L9 9L0.999998 1" stroke="#0A4E54" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
)
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      style={{
        left: "-72px",
        top: "50%",
        transform: "translateY(-50%)"
      }}
      onClick={onClick}
      className={"w-[50px] h-[50px] flex justify-center items-center cursor-pointer rounded-[50%] border border-[#D7D7D7] absolute"}
    >
      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 1L2 9L10 17" stroke="#0A4E54" strokeWidth="2" strokeLinecap="round" />
      </svg>
    </div>
  );
}


const Specialities = () => {
  const [specialities, setSpecialities] = React.useState([]);
  let settings = {
    infinite: false,
    arrows: false,
    speed: 500,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    };

    const style = {
      width: '9.8125rem',
      height: '12.5rem',
      flexShrink: 0,
      borderRadius: '1.25rem',
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1.38rem',
      cursor: 'pointer',
    }

    const fetchSpecialities = async () => {
      axios.get('/specialities').then((response)=>{
        setSpecialities([...response.data, {custom: true}])
      }).catch((error)=>{
        console.log(error.message)
      })
    }

    const prevSlider = () => {

    }

    const nextSlider = () => {

    }

    React.useEffect(()=>{
      fetchSpecialities()
    }, [])
  return (
    <React.Fragment>
      <div className="slider-container relative specialty">
        <Slider {...settings}>
          {specialities.map((speciality, idx) => {
            if(speciality.custom){
              return (
                <div key={idx} className={'border btn-round border-[#DBDBDB] !flex flex-col gap-4 justify-center items-center !w-[150px] sm:!w-[400px] md:!w-[600px] lg:!w-[900px] h-[200px]'}>
                  <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M40 34.422C40 32.6089 38.5487 31.1502 36.7447 31.1502C34.9407 31.1502 33.4893 32.6089 33.4893 34.422C33.4893 35.6081 34.1133 36.6441 35.0627 37.2167C34.1404 41.7972 30.1119 45.2599 25.2831 45.2599C19.7898 45.2599 15.3137 40.7612 15.3137 35.24V27.8921C22.3669 27.1968 27.8874 21.2122 27.8874 13.946V4.98949C27.901 4.92133 27.901 4.85317 27.901 4.785V1.36325C27.901 0.613462 27.2906 0 26.5446 0H24.2659C22.5704 0 21.2004 1.37688 21.2004 3.08094C21.2004 4.785 22.5704 6.16189 24.2659 6.16189H25.1746V13.9597C25.1746 20.1761 20.1424 25.2337 13.9573 25.2474C13.9573 25.2474 13.9573 25.2474 13.9437 25.2474C7.75856 25.2474 2.71278 20.1897 2.71278 13.9597V6.16189H3.56731C5.2628 6.16189 6.63276 4.785 6.63276 3.08094C6.63276 1.37688 5.2628 0 3.56731 0H1.35639C0.610376 0 0 0.613462 0 1.36325V4.79864V13.9597C0 21.2258 5.53408 27.2241 12.5873 27.9057V35.9352C12.5873 36.0443 12.6009 36.1534 12.628 36.2488C13.1299 42.806 18.6097 48 25.2696 48C31.4819 48 36.6768 43.4876 37.7484 37.5439C39.0641 37.1076 40 35.8807 40 34.422ZM23.9132 3.06731C23.9132 2.87646 24.076 2.71287 24.2659 2.71287H25.1882V3.42176H24.2659C24.076 3.42176 23.9132 3.2718 23.9132 3.06731ZM2.72635 2.7265H3.58087C3.77077 2.7265 3.93354 2.89009 3.93354 3.08094C3.93354 3.2718 3.77077 3.43539 3.58087 3.43539H2.72635V2.7265ZM36.9752 33.9313C36.8803 33.904 36.7854 33.8904 36.6904 33.8767C36.704 33.8767 36.7311 33.8767 36.7447 33.8767C36.826 33.8767 36.9074 33.904 36.9752 33.9313Z"
                      fill="#E9E9E9" />
                  </svg>
                  <span className={'text-sm text-secondary text-center w-full'}>More Specialties Coming Soon</span>
                </div>
              )
            }
            return (
              <a key={idx} href={"/physicians/search/" + speciality.slug}>
                <div style={{ width: 'fit-content' }}>
                  <div className={"speciality-card"}
                       style={style}>
                    <div dangerouslySetInnerHTML={{ __html: speciality.icon }} />
                    <div className={'text-center'}>{speciality.name}</div>
                  </div>
                </div>
              </a>
            )
          })}
        </Slider>
      </div>
    </React.Fragment>
  )
}

export default Specialities;
