import React from 'react';

function Verified(props) {
    return (
        <svg
                xmlns='http://www.w3.org/2000/svg'
                width='43'
                height='43'
                viewBox='0 0 43 43'
            fill='none'
            {...props}
              >
                <path
                  d='M19.1189 10.5411C19.6921 8.00345 23.3079 8.00345 23.8811 10.5411C24.2526 12.1857 26.1399 12.9674 27.5655 12.0672C29.7652 10.6782 32.3218 13.2348 30.9328 15.4345C30.0326 16.8601 30.8143 18.7474 32.4589 19.1189C34.9966 19.6921 34.9966 23.3079 32.4589 23.8811C30.8143 24.2526 30.0326 26.1399 30.9328 27.5655C32.3218 29.7652 29.7652 32.3218 27.5655 30.9328C26.1399 30.0326 24.2526 30.8143 23.8811 32.4589C23.3079 34.9966 19.6921 34.9966 19.1189 32.4589C18.7474 30.8143 16.8601 30.0326 15.4345 30.9328C13.2348 32.3218 10.6782 29.7652 12.0672 27.5655C12.9674 26.1399 12.1857 24.2526 10.5411 23.8811C8.00345 23.3079 8.00345 19.6921 10.5411 19.1189C12.1857 18.7474 12.9674 16.8601 12.0672 15.4345C10.6782 13.2348 13.2348 10.6782 15.4345 12.0672C16.8601 12.9674 18.7474 12.1857 19.1189 10.5411Z'
                  fill='#00ACDD'
                />
                <path
                  d='M18 22C18.4667 22.4 20 24.5 20.3333 24C20.6667 23.5 25 20 25 20'
                  stroke='white'
                  strokeWidth='2'
                  strokeLinecap='round'
                />
              </svg>
    )
}

export default Verified