import React from 'react';
import PropTypes from 'prop-types';
export default function EyeIcon({ color }) {
    return (
      <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10 1.78571C6.41894 1.78571 3.95222 3.75279 2.43648 5.56726C2.02849 6.05565 1.78952 6.34748 1.63233 6.67532C1.48543 6.98172 1.39535 7.35622 1.39535 8C1.39535 8.64378 1.48543 9.01829 1.63233 9.3247C1.78952 9.65255 2.0285 9.94438 2.43648 10.4328C3.95222 12.2472 6.41894 14.2143 10 14.2143C13.5811 14.2143 16.0477 12.2472 17.5635 10.4328C17.9715 9.94438 18.2105 9.65255 18.3677 9.32469C18.5146 9.01828 18.6047 8.64378 18.6047 8C18.6047 7.35623 18.5146 6.98173 18.3677 6.67533C18.2105 6.34747 17.9715 6.05565 17.5635 5.56725C16.0477 3.75278 13.5811 1.78571 10 1.78571ZM1.33076 4.78303C2.97236 2.81789 5.8008 0.5 10 0.5C14.1992 0.5 17.0276 2.81789 18.6692 4.78302L18.6692 4.78303L18.699 4.8186C19.0702 5.26282 19.412 5.67198 19.644 6.15576C19.8924 6.67387 20 7.23857 20 8C20 8.76143 19.8924 9.32613 19.644 9.84425C19.412 10.3281 19.0701 10.7372 18.6989 11.1815L18.6692 11.217L18.6692 11.217C17.0276 13.1821 14.1992 15.5 10 15.5C5.8008 15.5 2.97236 13.1821 1.33076 11.217L1.33076 11.217C1.32087 11.2052 1.31101 11.1934 1.30117 11.1816C0.9299 10.7373 0.587962 10.3281 0.355985 9.84425C0.107575 9.32613 0 8.76142 0 8C0 7.23858 0.107575 6.67388 0.355987 6.15576C0.587956 5.67194 0.929879 5.26276 1.30113 4.81849C1.31098 4.80669 1.32086 4.79487 1.33076 4.78303ZM9.99999 6.07142C8.844 6.07142 7.90697 6.93484 7.90697 8C7.90697 9.06516 8.844 9.92857 9.99999 9.92857C11.156 9.92857 12.093 9.06516 12.093 8C12.093 6.93484 11.156 6.07142 9.99999 6.07142ZM6.51162 8C6.51162 6.22476 8.07337 4.78571 9.99999 4.78571C11.9266 4.78571 13.4884 6.22476 13.4884 8C13.4884 9.77524 11.9266 11.2143 9.99999 11.2143C8.07337 11.2143 6.51162 9.77524 6.51162 8Z"
              fill={color || "#A9A9A9"} />
      </svg>
    )
}

EyeIcon.propTypes = {
    color: PropTypes.string
}