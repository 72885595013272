import React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip"
import InfoSVG from "../assets/eventViewAssets/info.svg";
import CircularProgress from '@mui/material/CircularProgress';
import { getAllSlots, getAvailableSlots, getBookedSlots, setAvailableSlots } from "../utils/axios";
import { Calendar } from "primereact/calendar";
import { toast } from "react-toastify";

const ScheduleMeeting = ({ handleClose }) => {
  const [selectedDate, setSelectedDate] = React.useState([new Date(), new Date()]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [slots, setSlots] = React.useState([])
  const [selectedSlots, setSelectedSlots] = React.useState([])
  const [lastSelectedSlot, setLastSelectedSlot] = React.useState(null);
  const [booked, setBooked] = React.useState([])

  const fetchAllSlots = async () => {
    setIsLoading(true)
    const response = await getAllSlots()
    setIsLoading(false)
    setSlots(response)
  }

  const dateFormat = (dt) => dayjs(dt).format('YYYY-MM-DD')

  React.useEffect(() => {
    fetchAllSlots()
  }, []);

  React.useEffect(() => {
    const fetchAvailableSlots = async (dates) => {
      if (!selectedDate[1]) return;
      const start_date = dateFormat(selectedDate[0]);
      const end_date = dateFormat(selectedDate[1])
      const response = await getAvailableSlots(start_date, end_date);
      const bookedResponse = await getBookedSlots(start_date, end_date);
      setBooked(bookedResponse)
      setSelectedSlots(response.map(i => i.slot))
    };
    fetchAvailableSlots();
  }, [selectedDate]);

  const slotHandler = (inputSlot, event) => {
    const isShiftPressed = event.shiftKey;

    if (isShiftPressed && lastSelectedSlot) {
      const startIndex = slots.findIndex(slot => slot.id === lastSelectedSlot.id);
      const endIndex = slots.findIndex(slot => slot.id === inputSlot.id);

      const range = [startIndex, endIndex].sort((a, b) => a - b);
      const newSelectedSlots = slots.slice(range[0], range[1] + 1);

      setSelectedSlots(prevSelectedSlots => {
        const newSelections = newSelectedSlots.filter(slot => !prevSelectedSlots.includes(slot));
        return [...prevSelectedSlots, ...newSelections];
      });
    } else {
      const allExisting = selectedSlots.some(i => i.id === inputSlot.id);
      if (allExisting) {
        setSelectedSlots(selectedSlots.filter(i => i.id !== inputSlot.id));
      } else {
        setSelectedSlots([...selectedSlots, inputSlot]);
      }
    }
    setLastSelectedSlot(inputSlot);
  };

  const handleSave = async () => {
    if (!selectedDate[1]) return;
    setIsLoading(true)
    const start_date = dateFormat(selectedDate[0]);
    const end_date = dateFormat(selectedDate[1])
    const response = await setAvailableSlots(start_date, end_date, selectedSlots)
    setIsLoading(false)
    if (response) {
      toast.success("Slots are updated");
      handleClose()
    } else {
      toast.error("Error while updating slots!");
    }
  }
  return (
    <div className="w-full bg-white rounded h-[90vh] flex flex-col gap-4 md:p-8 overflow-scroll">
      <div className="w-full text-center text-secondary font-bold text-xl">
        Add new Slot to your Roaster
      </div>
      <div className="grid md:grid-cols-6 mt-4">
        <div className="col-span-4 p-12">
          <Calendar
            inline
            minDate={new Date()}
            selectionMode="range"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.value)}
          />
        </div>
        <div className="col-span-2 relative">
          {isLoading &&
            <div className={'flex justify-center items-center w-full h-full absolute bg-white'}>
              <CircularProgress />
            </div>
          }
          <div style={{
            height: 'fit-content',
            maxHeight: '500px'
          }} className="sb overflow-auto items-center content-between flex-shrink-0 flex flex-wrap justify-center gap-2">
            {slots.map(slot => {
              const isPresent = selectedSlots?.filter(i => i.id === slot?.id)
              const isBooked = booked?.filter(i => i.available_slot?.slot_id === slot.id)
              const msg = isBooked?.length > 0 ? `these date${isBooked?.length > 1 ? 's' : ''} (${isBooked.map(i => i.app_date).join(", ")}) are booked by patients and can not be deleted!` : null
              return <Tooltip className={'w-[126px]'} key={slot.id + "-" + slot.name} title={msg} onClick={(e) => slotHandler(slot, e)}><Badge badgeContent={isBooked?.length} color="error" className={`relative slot ${isPresent?.length && 'selected'}`}><div className={`w-full text-center col`}>{slot.name}</div></Badge></Tooltip>
            })}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center text-sm">
        <div
          style={{
            background: "#FDFAF1",
          }}
          className="flex gap-2 items-center"
        >
          <img src={InfoSVG} alt="" />
          You’ll be able to add Multiple Dates & Slots
        </div>
      </div>
      <div className="flex px-16 justify-between">
        <button className="btn-secondary-outlined text-center" onClick={handleClose}>Close</button>
        <button className="btn-secondary" onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};

export default ScheduleMeeting;
