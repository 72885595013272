import React from 'react'
import Table from '../../components/Table'
import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Image
} from "@nextui-org/react";
import { uniquePatients } from "../../utils/axios";
import Loader from "@mui/material/CircularProgress";
import SinglePatientDetail  from "./singleUser"


export default function Appointments() {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isLoading2, setIsLoading2] = React.useState(false)
  const [patientId, setPatientId] = React.useState(null)
  const [data, setData] = React.useState([])
  const [page, setPage] = React.useState(1)
  const {isOpen, onOpen, onClose, onOpenChange} = useDisclosure();
  const [doctor, setSelectedDoctor] = React.useState(null)

const header = [
    {
      id: 'id',
      label: 'Serial No.',
      centerAlign: true,
    },
    {
      id: 'physician_name',
      label: 'Patient’s Name',
      centerAlign: true,
    },
  {
    id: 'age',
    label: 'Age',
  },
  {
    id: 'gender',
    label: 'Gender',
  },
  {
    id: 'contact_number',
    label: 'Phone Number',
  },
    {
      id: 'history',
      label: 'History',
      hideHeader: true,
      hideSort: true,
      centerAlign: true,
      template: (object,doctor) => {
        return (
          <>
            <div
              style={{
                background: '#0A4E54',
                textTransform: 'capitalize',
                width: '106px'
              }}
              onClick={() => {
                onOpen();
                fetchAppsData(doctor.id)
                setSelectedDoctor(doctor)
              }}
              className={'gap-2 user-select-none bg-primary flex justify-center items-center text-white btn-round px-4 py-2 cursor-pointer'}
            >
              History
            </div>
          </>
        )
      }
    },
  ]
  const fetchAppsData = (doctorId) => {
    setPatientId(doctorId)
  }

  React.useEffect(()=>{
    const fetchData = async () => {
      setIsLoading(true)
      const response = await uniquePatients(page)
      setIsLoading(false)
      if(response){
        const mappedData = response.map(patient => {
          return {
            ...patient,
            physician_name: patient.first_name+" "+patient.last_name,
            age: patient.date_of_birth?.getAge()
          }
        })
        setData(mappedData)
      } else {
        setData([])
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {isLoading &&
        <div className={'glass-effect absolute w-full top-0 left-0 h-full z-50 flex justify-center items-center'}>
          <Loader />
        </div>
      }
      <div className={"table-container"}>
        <div style={{
          background: "#F8F8F8"
        }} className={"w-full px-4 py-8"}>Your Appointment’s List
        </div>
        <Table className={"primary-table"} headers={header} page={page} data={data} setPage={setPage} />
      </div>

      <Modal
        hideCloseButton={true}
        backdrop={"blur"}
        size="5xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className={'py-8'}>
                <div className={"flex justify-between"}>
                  <div className="flex">
                    <Image
                      className="h-40 w-40"
                      src={doctor.display_image_url}
                      alt="NextUI Album Cover"
                    />
                    <div className="pt-5 pb-5 flex flex-col gap-3 ml-11">
                      <div className="flex flex-col">
                        <div className="text-2xl font-semibold flex gap-2 justify-start items-center">
                          {doctor.physician_name}
                        </div>
                        <div className="flex gap-4 items-center">
                  <span>
                    <span className="text-sm text-custom-gray">
                      Age:{" "}
                    </span>
                    <span className="text-lg text-custom-gray">
                      {doctor.date_of_birth?.getAge()}
                    </span>
                  </span>
                          <span className="items-center flex gap-2">
                    <span className={'capitalize'}>
                              {doctor.gender}
                            </span>
                  </span>
                        </div>
                      </div>
                      <div className="flex gap-4">
                        {doctor.specialities.map((speciality, index) => {
                          return (
                            <div
                              key={index}
                              className="py-2.5 px-6 bg-primary-dark gap-2.5 btn-round"
                            >
                              {speciality.name}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className={"flex gap-4 h-10"}>
                    <button className={"flex px-4 py-2 btn-round border border-secondary text-secondary"}
                            onClick={() => {
                              onClose();
                              setSelectedDoctor({});
                            }}>Close
                    </button>
                  </div>
                </div>
                <div className={"relative"}>
                  {isLoading2 &&
                    <div
                      className={"glass-effect absolute w-full top-0 left-0 h-full z-50 flex justify-center items-center"}>
                      <Loader />
                    </div>
                  }
                  {patientId ? <SinglePatientDetail id={patientId}/> : <></>}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}