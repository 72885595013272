import React, { useId, useState } from "react";
import { toast } from "react-toastify";
import SecondaryEmailTemplate from "./secondaryEmail";
import { Input } from "@nextui-org/react";
import PhoneInput from "react-phone-input-2";
import SaveIcon from "@mui/icons-material/Save";
import { getMyData, sendResetPassEmail, updateUserData } from "../../utils/axios";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AccountSetting({ data, fetchData }) {
  const primaryEmailId = useId()
  const secondaryEmailId = useId()
  const phoneId = useId()
  const [primaryEmail, setPrimaryEmail] = useState('')
  const [secondaryEmail, setSecondaryEmail] = useState('')
  const [contactNumber, setContactNumber] = useState('')
  const [editPhone, setEditPhone] = useState(false)
  const [show, setShow] = useState(false)
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleAlertOpen = () => {
    console.log('clicked')
    setOpenAlert(true);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };
  const closeEmailHandler = () => setShow(false)
  const openEmailHandler = () => setShow(true)

  const deleteSecondaryHandler = async () => {
    const response = await updateUserData({ secondary_email: "" })
    if (response) {
      setSecondaryEmail("")
      if (fetchData) fetchData()
      setOpenAlert(false)
    }
  }

  const updatePhoneHandler = async (ph) => {
    const response = await updateUserData({ contact_number: ph })
    if (response) {
      setContactNumber(ph)
      if (fetchData) fetchData()
    }
  }


  const resetPassword = async () => {
    const response = await sendResetPassEmail()
    if (response) {
      toast.success('Password Reset Email has been sent. Please Check your Inbox!')
    } else {
      toast.error('Error while generating reset email!')
    }
  }

  React.useEffect(() => {
    setPrimaryEmail(data.email)
    setSecondaryEmail(data.secondary_email)
    setContactNumber(data.contact_number)
  }, [data])

  return (
    <div className={"md:py-[90px] py-5 md:px-[60px] px-3"}>
      {show && <SecondaryEmailTemplate onChange={(val) => {
        if (fetchData) fetchData();
        setSecondaryEmail(val);
      }} onClose={closeEmailHandler} />}

      <div className={"flex justify-between"}>
        <label htmlFor={primaryEmailId} className={"font-semibold text-sm text-secondary"}>Primary Email</label>
        {(!secondaryEmail || secondaryEmail === "null") && (
          <>
            <svg onClick={openEmailHandler} className={"cursor-pointer"} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="0.5" width="29" height="29" rx="7.5" stroke="#0A4E54" />
              <path fillRule="evenodd" clipRule="evenodd"
                d="M15.8643 10C15.8643 9.44772 15.4166 9 14.8643 9C14.312 9 13.8643 9.44772 13.8643 10V13.8647H10C9.44772 13.8647 9 14.3124 9 14.8647C9 15.417 9.44772 15.8647 10 15.8647L13.8643 15.8647V19.7293C13.8643 20.2816 14.312 20.7293 14.8643 20.7293C15.4166 20.7293 15.8643 20.2816 15.8643 19.7293V15.8647L19.7293 15.8647C20.2816 15.8647 20.7293 15.417 20.7293 14.8647C20.7293 14.3124 20.2816 13.8647 19.7293 13.8647H15.8643V10Z"
                fill="#0A4E54" />
            </svg>
          </>
        )}
      </div>

      <Input
        type="email"
        id={primaryEmailId}
        name="email"
        disabled={true}
        placeholder="Email Address *"
        variant="bordered"
        value={primaryEmail}
        onInput={(e) => setPrimaryEmail(e.target?.value)}
        required
        className="w-full mt-4"
      />
      {secondaryEmail && secondaryEmail !== "null" && (
        <div className={"mt-4"}>
          <label htmlFor={secondaryEmailId} className={"text-sm text-secondary"}>Secondary Email</label>
          <div className={"flex gap-4"}>
            <div className={"w-[calc(100%-50px)]"}>
              <Input
                type="email"
                id={secondaryEmailId}
                name="email"
                placeholder="Email Address *"
                variant="bordered"
                disabled={true}
                value={secondaryEmail === "null" ? null : secondaryEmail}
                onInput={(e) => setSecondaryEmail(e.target?.value)}
                required
                className="w-full mt-4"
              />
            </div>
            <div className={"mt-4 w-[50px] h-[50px] cursor-pointer flex justify-center items-center"}
              style={{ background: "#8181810D", borderRadius: "10px" }}>
              <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"
                onClick={handleAlertOpen}>
                <path
                  d="M5.44525 2.69507C5.67152 2.0549 6.28205 1.59756 6.99803 1.59756C7.71401 1.59756 8.32462 2.0549 8.55086 2.69507C8.65183 2.98083 8.96537 3.13061 9.25118 3.02961C9.53691 2.9286 9.68669 2.61507 9.58571 2.32932C9.20925 1.26433 8.19364 0.5 6.99803 0.5C5.80249 0.5 4.78684 1.26433 4.41043 2.32932C4.30942 2.61507 4.45919 2.9286 4.74496 3.02961C5.03072 3.13061 5.34425 2.98083 5.44525 2.69507Z"
                  fill="#EE2348" />
                <path
                  d="M0.230469 3.97554C0.230469 3.67246 0.476169 3.42676 0.779249 3.42676H13.2183C13.5214 3.42676 13.7671 3.67246 13.7671 3.97554C13.7671 4.27862 13.5214 4.52432 13.2183 4.52432H0.779249C0.476169 4.52432 0.230469 4.27862 0.230469 3.97554Z"
                  fill="#EE2348" />
                <path
                  d="M1.96152 5.25734C2.26392 5.23718 2.52542 5.46599 2.54558 5.7684L2.88213 10.8165C2.94787 11.8028 2.99472 12.489 3.09758 13.0053C3.19735 13.5061 3.33661 13.7712 3.53667 13.9584C3.73673 14.1455 4.01051 14.2669 4.51685 14.3331C5.03885 14.4014 5.72669 14.4025 6.71508 14.4025H7.28099C8.26938 14.4025 8.95718 14.4014 9.47918 14.3331C9.98552 14.2669 10.2593 14.1455 10.4594 13.9584C10.6594 13.7712 10.7987 13.5061 10.8985 13.0053C11.0013 12.489 11.0482 11.8028 11.1139 10.8165L11.4505 5.7684C11.4706 5.46599 11.7321 5.23718 12.0345 5.25734C12.3369 5.2775 12.5657 5.539 12.5456 5.84141L12.2065 10.928C12.1439 11.8667 12.0934 12.6248 11.9749 13.2197C11.8517 13.8382 11.6421 14.3549 11.2092 14.7598C10.7764 15.1648 10.2469 15.3396 9.62157 15.4214C9.02004 15.5 8.26023 15.5 7.31955 15.5H6.67652C5.73584 15.5 4.97598 15.5 4.37449 15.4214C3.74914 15.3396 3.21971 15.1648 2.78684 14.7598C2.35396 14.3549 2.14439 13.8382 2.02117 13.2197C1.90265 12.6248 1.85212 11.8667 1.78957 10.928L1.45046 5.84141C1.43029 5.539 1.6591 5.2775 1.96152 5.25734Z"
                  fill="#EE2348" />
              </svg>
            </div>
          </div>
        </div>
      )}
      <div className={"mt-4"}>
        <label htmlFor={phoneId} className={"font-semibold text-sm text-secondary"}>Phone Number</label>
        <div className={"flex gap-4 mt-4"}>
          <div className={"w-[calc(100%-50px)]"}>
            <PhoneInput
              className={"invalid-borderx"}
              inputProps={{
                id: phoneId,
                name: "contact_number",
                class: "form-control",
                required: true,
                autoFocus: true
              }}
              country={"us"}
              disabled={!editPhone}
              value={contactNumber}
              onChange={updatePhoneHandler}
            />
          </div>

          {
            !editPhone && (
              <svg width="50" height="50" viewBox="0 0 50 50" className={'cursor-pointer'} fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setEditPhone(true)}>
                <rect width="50" height="50" rx="10" fill="#818181" fillOpacity="0.05" />
                <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.1867 17.5103C29.9544 17.435 30.7156 17.7721 31.4718 18.5282C32.2279 19.2844 32.565 20.0456 32.4897 20.8133C32.417 21.554 31.9703 22.1678 31.4718 22.6664L30.6974 23.4407C30.6944 23.4438 30.6914 23.4468 30.6883 23.4498L26.0376 28.1005C26.0364 28.1017 26.0352 28.1029 26.034 28.1041L26.033 28.1051L26.0316 28.1065C25.8763 28.2567 25.6733 28.3881 25.4775 28.4865C25.2828 28.5844 25.0534 28.6707 24.8348 28.7019L22.759 28.9985C22.7581 28.9986 22.7573 28.9987 22.7564 28.9988C22.2446 29.0693 21.755 28.9296 21.4115 28.5874C21.0672 28.2446 20.9264 27.7547 21.0017 27.24L21.0018 27.239L21.2978 25.1669L21.298 25.166C21.329 24.9445 21.4149 24.7135 21.5135 24.5174C21.6122 24.3208 21.7452 24.1166 21.8995 23.9624L26.5461 19.3158C26.5517 19.3099 26.5575 19.3041 26.5634 19.2984L27.3336 18.5282C27.8322 18.0297 28.446 17.583 29.1867 17.5103ZM26.7147 20.6101L22.6309 24.6938C22.5783 24.7465 22.5044 24.8491 22.4377 24.9819C22.3709 25.1148 22.3327 25.2351 22.3223 25.3099L22.3221 25.3115L22.0255 27.3873L22.0253 27.3892C21.9905 27.6258 22.0601 27.7735 22.1414 27.8545C22.2236 27.9363 22.3749 28.0069 22.6142 27.9742L22.6154 27.9741L24.6885 27.6779C24.7594 27.6678 24.8784 27.6299 25.0129 27.5623C25.1445 27.4962 25.2507 27.4218 25.3097 27.3655L29.3909 23.2843C28.2069 22.7403 27.2565 21.7911 26.7147 20.6101ZM30.1838 22.4914C28.932 22.0445 27.9497 21.0617 27.508 19.8168L28.0651 19.2597C28.5044 18.8204 28.9043 18.5774 29.2877 18.5398C29.644 18.5048 30.1172 18.6366 30.7403 19.2597C31.3634 19.8828 31.4952 20.356 31.4602 20.7123C31.4226 21.0957 31.1796 21.4956 30.7403 21.9349L30.1838 22.4914Z"
                  fill="#10C1D0" />
                <path
                  d="M18.7721 18.9455C19.6924 18.0252 21.0694 17.6733 22.8445 17.6733H24.2237C24.5094 17.6733 24.7409 17.9049 24.7409 18.1906C24.7409 18.4762 24.5094 18.7078 24.2237 18.7078H22.8445C21.1715 18.7078 20.1349 19.0455 19.5035 19.6769C18.8722 20.3083 18.5344 21.3448 18.5344 23.0178V27.1555C18.5344 28.8285 18.8722 29.8651 19.5035 30.4965C20.1349 31.1278 21.1715 31.4656 22.8445 31.4656H26.9822C28.6552 31.4656 29.6917 31.1278 30.3231 30.4965C30.9545 29.8651 31.2922 28.8285 31.2922 27.1555V25.7763C31.2922 25.4906 31.5238 25.2591 31.8094 25.2591C32.0951 25.2591 32.3266 25.4906 32.3266 25.7763V27.1555C32.3266 28.9306 31.9748 30.3076 31.0545 31.2279C30.1343 32.1482 28.7572 32.5 26.9822 32.5H22.8445C21.0694 32.5 19.6924 32.1482 18.7721 31.2279C17.8518 30.3076 17.5 28.9306 17.5 27.1555V23.0178C17.5 21.2428 17.8518 19.8657 18.7721 18.9455Z"
                  fill="#10C1D0" />
              </svg>
            )
          }
          {
            editPhone && (
              <div className={"w-[50px] h-[50px] cursor-pointer flex justify-center items-center"}
                onClick={() => {
                  toast.success("Phone Number is updated!")
                  setEditPhone(false);
                }}
                style={{ background: "#8181810D", borderRadius: "10px" }}>
                <SaveIcon sx={{ color: "#10C1D0" }} />
              </div>
            )
          }
        </div>
      </div>
      <br />
      <br />
      <br />
      <hr />
      <div className={"w-full flex mt-12 flex-col gap-4 justify-center items-center"}>
        <p className={"text-semibold text-secondary"}>Update Password</p>
        <button onClick={resetPassword} className={"btn-round bg-secondary text-white px-8 py-4"}>Click here to generate
          Link
        </button>
      </div>
      <Dialog
        open={openAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleAlertClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Deletion Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You are going to delete Secondary Email.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className={'px-4 py-2 border border-secondary btn-round text-secondary'} onClick={handleAlertClose}>Disagree</button>
          <button className={'px-4 py-2 bg-primary btn-round text-white'} onClick={deleteSecondaryHandler}>Agree</button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
