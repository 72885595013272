import React from 'react'
import EventDetail from '../../components/EventDetail'
import { getUpcoming3Appointments } from "../../utils/axios";
import Loader from "@mui/material/CircularProgress"

export default function Upcoming(){
  const [data, setData] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [position, setPosition] = React.useState([2,1,0])


  React.useEffect(()=>{
    const fetchData = async () => {
      setIsLoading(true)
      const response = await getUpcoming3Appointments()
      setIsLoading(false)
      if(response){
        const l = response.length
        if(l === 3) {
          setPosition([0,1,2])
        } else if(l === 2) {
          setPosition([0,1,2])
        } else if(l === 1) {
          setPosition([1,0,2])
        } else {
          setPosition([0,1,2])
        }
        setData(response)
      }else {
        setPosition([0,1,2])
        setData([])
      }
    }
    fetchData()
  }, [])

  return (
    <>
      {isLoading &&
        <div className={'glass-effect absolute w-full top-0 left-0 h-full z-10 flex justify-center items-center'}>
          <Loader />
        </div>
      }
      <h1 className={'px-16 py-4'}>Your Upcoming Appointments</h1>
    <div className={'w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 overflow-hidden h-full gap-4 p-8'}>
      {position.map(index => {
        const event = data[index]
        if(event){
          return (
            <div key={index} className={'xl:w-[100%] lg:w-[100%]'}>
              <EventDetail className={'col-span-1'} show={true} shadow={false} event={event} handleClose={() => {
              }} />
            </div>
          )
        } else {
         return <EventDetail className={'col-span-1'} key={index} shadow={false} />
        }
      })}
    </div>
    </>
  )
}