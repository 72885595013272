const TAB_1 = ['first_name', 'last_name', 'gender', 'date_of_birth', 'email', 'password', 'confirm_password'];
const TAB_2 = ['street_address', 'zip_code', 'location_id', 'city', 'specialities'];
export const enabledTabs = (formik, active) => {
    const { errors, setFieldTouched } = formik;

    const nested = (field) => {
        if (field === 'specialities') {
            errors.specialities?.forEach((v, i) => {
                setFieldTouched(`specialities.${i}.licence_number`)
                setFieldTouched(`specialities.${i}.exp_date`)
                setFieldTouched(`specialities.${i}.location_id`)
                setFieldTouched(`specialities.${i}.speciality_id`)
            })
        }
        if (field === 'board_certifications') {
            errors.specialities?.forEach((v, i) => {
                setFieldTouched(`board_certifications.${i}.certification`)
                setFieldTouched(`board_certifications.${i}.exp_date`)
            })
        }
    }
    const countValidFields = (tab) => {
        return tab.reduce((acc, field) => {
            setFieldTouched(field, true, true);
            nested(field)
            if (!errors[field]) {
                return acc - 1;
            }
            return acc;
        }, tab.length);
    };

    switch (active) {
        case 'one':
            const firstTabCount = countValidFields(TAB_1);
            return firstTabCount === 0 ? 'two' : 'one';
        case 'two':
            const secondTabCount = countValidFields(TAB_2);
            return secondTabCount === 0 ? 'three' : 'two';
        default:
            return active;
    }
};

export const validator = (formik, key) => !!formik.errors[key] && formik.touched[key];


export const nestedValidator = (formik, parent, index, key) => {
    if (formik.touched[parent] && formik.touched[parent][index]) {
        if (formik.errors[parent] && !!formik.errors[parent][index]) { 
            return formik.touched[parent][index][key] && !!formik.errors[parent][index][key]
        }
    }
    return false
}