import React from 'react'
import Slider from "react-slick";
import axios from 'axios'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { object } from 'prop-types';


const reviewsData = [
  {
    comment: "Dr was very professional and understanding. I felt he really listened to what I had to say and gave me different options of treatment. Looking forward to seeing him again for my follow up",
    patient_name: "Denzel Washington",
    image_url: "https://i.pravatar.cc/300",
    rating: 5
  },
]
  const Specialities = () => {
    const [reviews, setReviews] = React.useState([])
    let sliderRef = React.useRef(null);
    let settings = {
      dots: false,
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
    };

    const fiveTimesArray = (times) => Array.from({ length: times }, (_, index) => index);

    const fetchReviews = async () => {
      axios.get('/reviews').then((response)=>{
        console.log(response.data)
        const dt = response.data.length ? response.data : reviewsData
        setReviews(dt)
      }).catch((error)=>{
        setReviews(reviewsData)
        console.log(error.message)
      })
    }

    React.useEffect(()=>{
      // fetchReviews()
    }, [])


  return (
    <React.Fragment>
      <div className="slider-container">
        <Slider
          ref={slider => {
            sliderRef = slider;
          }}
          {...settings}
        >
          {reviews.map((review, idx)=> {
            return (
              <div key={idx} className="bg-white w-full min-h-[24rem] lg:max-w-[43rem] rounded-[1.25rem] p-[3.125rem]">
                <p className="text-xl sm:text-[1.625rem] text-[#838383] font-medium pb-8">
                    <span className="pb-4 inline-flex">
                        <svg width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.15342 15H0L6.90411 0H12.1644L8.15342 15ZM19.989 15H11.8356L18.7397 0H24L19.989 15Z" fill="#838383" />
                        </svg>
                    </span>
                  <span className="flex pl-5">{review.comment}</span>
                </p>
                <div className="flex md:flex-row flex-col items-center justify-between border-t p-5">
                  <div className="flex">
                    <img src={review.image_url} className={'mr-4 inline-block h-[3.75rem] w-[3.75rem] rounded-full ring-2 ring-white'}/>
                    <div className="detail">
                      <p className="text-sm">{review.patient_name}</p>
                      <div className="flex items-center mt-2">
                        {fiveTimesArray(review.rating).map(value => {
                          return (
                            <span key={value} className="p-1">
                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.57342 1.39057C7.02244 0.00860715 8.97756 0.00861025 9.42659 1.39058L10.1329 3.56434C10.3337 4.18237 10.9096 4.60081 11.5595 4.60081H13.8451C15.2982 4.60081 15.9023 6.46024 14.7268 7.31434L12.8777 8.6578C12.3519 9.03976 12.1319 9.71681 12.3328 10.3348L13.0391 12.5086C13.4881 13.8906 11.9064 15.0398 10.7308 14.1857L8.88168 12.8422C8.35595 12.4602 7.64405 12.4602 7.11832 12.8422L5.26921 14.1857C4.09364 15.0398 2.51192 13.8906 2.96095 12.5086L3.66725 10.3348C3.86806 9.71681 3.64807 9.03976 3.12234 8.6578L1.27322 7.31434C0.0976527 6.46023 0.701818 4.60081 2.1549 4.60081H4.44053C5.09037 4.60081 5.66631 4.18237 5.86712 3.56434L6.57342 1.39057Z"
                                fill="#FFDD86" />
                            </svg>
                          </span>
                          )
                        })}
                        <span className="p-1 text-sm">{review.rating}</span>
                      </div>
                    </div>
                  </div>
                  <div className="flex md:mt-0 mt-4">
                    <a className={`relative p-[0.3125rem] ${idx === 0 ? 'hidden' : idx === 4 ? 'left-[-3rem] cursor-pointer' : 'cursor-pointer'}`} onClick={() => sliderRef.slickGoTo(idx - 1)}>
                      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25" cy="25" r="24.5" stroke="#D7D7D7" />
                        <path d="M28 17L20 25L28 33" stroke="#0A4E54" strokeWidth="2" strokeLinecap="round" />
                      </svg>
                    </a>
                    {idx !== reviews.length-1 && (
                      <a className={`p-[0.3125rem] ${idx === 4 ? 'hidden' : 'cursor-pointer'}`}
                         onClick={() => sliderRef.slickGoTo(idx + 1)}>
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="25" cy="25" r="24.5" transform="rotate(180 25 25)" stroke="#D7D7D7" />
                          <path d="M22 33L30 25L22 17" stroke="#0A4E54" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    </React.Fragment>
  )
  }

export default Specialities;
