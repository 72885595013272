import React, { useState } from 'react'
import dayjs from "dayjs";
import { getAvailableNotSlots, getAvailableSlots } from "../../utils/axios";
import { Calendar } from "primereact/calendar";
import CircularProgress from "@mui/material/CircularProgress";
const slotSelection = ({user, date, data, onSelect}) => {
  const [availableSlotId_, setAvailableSlotId] = React.useState(null)
  const [date_, setDate] = React.useState(null)
  const [selectedDate, setSelectedDate] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [slots, setSlots] = React.useState([])
  const [selectedSlot, setSelectedSlot] = React.useState({id: data.available_slot_id, slot: data.slot})
  const [disabledDates, setDisabledDates] = useState([]);
  const [viewDate, setViewDate] = useState(dayjs().startOf('month'));

  const dateFormat = (dt) => dayjs(dt).format('YYYY-MM-DD')

  React.useEffect(()=>{
    if(data?.available_slot_id){
      setAvailableSlotId(data.available_slot_id)
      setSelectedSlot({id: data?.available_slot_id, slot: data?.slot})
    }
  }, [data.id])

  React.useEffect(() => {
    setIsLoading(true)
    const fetchAvailableSlots = async (dates) => {
      const selected_date = selectedDate ? dateFormat(selectedDate) : new Date();
      const id = window.location.pathname.getLastParam()
      const response = await getAvailableSlots(selected_date, selected_date, user.slug || id);
      const lastDayOfMonth = dateFormat(dayjs(viewDate).endOf('month').toDate());
      const noSlotDates = await getAvailableNotSlots(dateFormat(viewDate), lastDayOfMonth, data.doctor_id);
      noSlotDates.map((slot) => disabledDates.push(new Date(slot)));
      setIsLoading(false)
      if(response){
        setSlots(response)
      }
         setSelectedSlot({ id: null, slot: null });

    };
    fetchAvailableSlots();
  }, [selectedDate, viewDate]);

  const slotHandler = (slot) => {
    setSelectedSlot(slot)
  }
  
  React.useEffect(()=>{
    if(onSelect) onSelect(selectedSlot, selectedDate)
  }, [selectedSlot])


  return (
    <>
      <div className={'text-secondary font-semibold'} style={{ fontSize: 14 }}>
        2. Select and Available slot
      </div>
      <div className={'w-full flex justify-center'}>
        <div className="w-full bg-white rounded h-full flex flex-col gap-4 md:p-8">
          <div className="grid md:grid-cols-6 mt-4">
            <div className="col-span-4 md:p-4">
              <Calendar
                inline
                minDate={new Date()}
                value={selectedDate}
                selectOtherMonths={false}
                onChange={(e) => setSelectedDate(e.value)}
                disabledDates={disabledDates}
                onViewDateChange={(e) => setViewDate(e.value)}
                viewDate={viewDate}
              />
            </div>
            <div className="col-span-2 relative">
              {isLoading &&
                <div className={'flex z-50 justify-center items-center w-full h-full absolute bg-white'}>
                  <CircularProgress />
                </div>
              }
              <div style={{
                height: 'fit-content',
                maxHeight: '500px'
              }}
                   className="sb overflow-auto items-center content-between flex-shrink-0 flex flex-wrap justify-center gap-2">
                {slots.map(availableSlot => {
                  return <div onClick={() => slotHandler(availableSlot)}
                              key={availableSlot.id + "-" + availableSlot.date}
                              className={`relative slot ${selectedSlot.id === availableSlot.id ? '!bg-primary' : ''} w-full text-center col`}>{availableSlot.slot?.name}</div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default slotSelection