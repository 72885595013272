import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import {
  NextUIProvider,
  User,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  Link,
  Divider,
  Button,
  NavbarMenuToggle,
  Listbox,
  ListboxItem,
  cn,
} from "@nextui-org/react";
import AcmeLogo from "../assets/logo.png";
import axios from "axios";

export const ListboxWrapper = ({ children }) => (
  <div className="w-full max-w-[260px] border-small px-1 rounded-small border-default-200 dark:border-default-100">
    {children}
  </div>
);

export default function App({ full_name, category, user_id, display_image }) {
  const [open, setOpen] = React.useState(false);
  const [activeLink, setActiveLink] = React.useState('')
  const [isHome, setIsHome] = React.useState(false)
  const [isPhysicianRegistration, setIsPhysicianRegistration] = React.useState(false)
  const [isPatientRegistration, setIsPatientRegistration] = React.useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const iconClasses =
    "text-xl text-default-500 pointer-events-none flex-shrink-0";

  const menuItems = [
    { label: "Home", path: "/", isActive: true },
    { label: "How It Works", path: "/how-it-works", isActive: true },
    {
      label: "Our Physicians",
      path: "/physicians",
      isActive: true
    },
    {
      label: "Blog",
      path: "/blog",
    }
  ];

  const logoutHandler = async () => {
    const response = await axios.delete("/logout");
    if (response.status === 200) {
      sessionHandler(response.data);
    }
  };
  const sessionHandler = ({category}) => {
    const link = isHome ? '/login' : isPhysicianRegistration ? "/login" : isPatientRegistration ? "/physician/login" : '/login'
    // window.location.href = (category === "doctor" ? "/physician/login" : "/login")
    window.location.href =  link
  };

  useEffect(() => {
    document.body.addEventListener('click', function(e) {
      if(e.target.matches('.menu-opener') || e.target.matches('img') || e.target.matches('.text-small.text-inherit')){
       // console.log()
      } else {
        setOpen(false)
      }
    })
    setActiveLink(window.location.pathname)
    setIsHome(window.location.pathname === "/")
    setIsPhysicianRegistration(window.location.pathname === "/physician/login")
    setIsPatientRegistration(window.location.pathname === "/login")
    window.toast = toast
  }, []);

  return (
    <NextUIProvider>
      <ToastContainer />
      <Navbar
        disableAnimation
        className="h-24 sm:px-0 md:px-0 lg:px-20 xl:px-40"
        id="nav-bar"
      >
        <NavbarContent className="sm:hidden" justify="start">
          <NavbarMenuToggle />
        </NavbarContent>

        <NavbarContent className="sm:hidden pr-3" justify="center">
          <NavbarBrand>
            <a href="/">
              <img src={AcmeLogo} alt="abc" width="80" />
            </a>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex sm:justify-between w-full !justify-between">
          <a href="/">
            <img src={AcmeLogo} alt="abc" className="w-[135px]" />
          </a>
          <div className="flex gap-6 md:gap-12 w-full justify-center">
            {menuItems.map((item, index) => (
              <NavbarItem key={index}>
                <Link className={['text-sm','text-[400]', 'text-[#0A4E54]',activeLink === item.path ? 'active-link' : ''].join(" ")} color="foreground" href={item.path}>
                  {item.label}
                </Link>
              </NavbarItem>
            ))}
          </div>
          {!user_id && (
            <NavbarItem className="col-span-2 flex justify-end">
              <Button as={Link} color="primary" onClick={sessionHandler}>
                {isHome ? 'Log-in / Register' : isPhysicianRegistration ? 'Log-in as Patient' : isPatientRegistration ? 'Log-in as Physician' : 'Log-in'}
              </Button>
            </NavbarItem>
          )}
          {user_id && (
            <NavbarItem
              style={{ width: 270 }}
              className="relative flex items-center justify-between gap-6"
            >
              <AnimatePresence mode="wait">
                {open && (
                  <motion.div
                    className="absolute w-full profile-card bg-white menu-opener-1 no-child-border"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div
                      className="w-full flex items-center cursor-pointer justify-between menu-opener-1"
                      onClick={handleClose}
                    >
                      <User
                        className="cursor-pointer menu-opener-1"
                        name={full_name?.slice(0,15)+"..."}
                        avatarProps={{
                          src: display_image,
                        }}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="9"
                        viewBox="0 0 13 9"
                        fill="none"
                        className={'menu-opener-1'}
                      >
                        <path
                          d="M12 7.5L6.5 2L1 7.5"
                          stroke="#07383D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <Divider className="my-4 menu-opener-1" />
                    <ListboxWrapper className={'menu-opener-1'}>
                      <Listbox
                        variant="light"
                        className={'menu-opener-1'}
                        aria-label="Listbox menu with descriptions"
                      >
                        <ListboxItem
                          href="/dashboard"
                          key="activity"
                          className={'menu-opener-1'}
                          startContent={
                            <svg
                              width="15"
                              height="15"
                              className={'menu-opener-1'}
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 1.01293e-07H7.45995C5.84946 -6.87545e-06 4.58734 -1.38789e-05 3.60259 0.132384C2.59463 0.2679 1.79927 0.550695 1.17498 1.17498C0.550695 1.79927 0.2679 2.59463 0.132384 3.60259C-1.38789e-05 4.58734 -6.87545e-06 5.84946 1.01293e-07 7.45995V7.54005C-6.87545e-06 9.15056 -1.38789e-05 10.4127 0.132384 11.3974C0.2679 12.4053 0.550695 13.2008 1.17498 13.825C1.79927 14.4493 2.59463 14.7321 3.60259 14.8677C4.58734 15 5.84946 15 7.45995 15H7.54005C9.15056 15 10.4127 15 11.3974 14.8677C12.4053 14.7321 13.2008 14.4493 13.825 13.825C14.4493 13.2008 14.7321 12.4053 14.8677 11.3974C15 10.4127 15 9.15056 15 7.54005V7.5C15 7.21102 14.7657 6.97674 14.4767 6.97674C14.1878 6.97674 13.9535 7.21102 13.9535 7.5C13.9535 9.15921 13.9524 10.351 13.8304 11.258C13.7106 12.1499 13.4825 12.6876 13.085 13.085C12.6876 13.4825 12.1499 13.7106 11.258 13.8304C10.351 13.9524 9.15921 13.9535 7.5 13.9535C5.84078 13.9535 4.649 13.9524 3.74203 13.8304C2.85012 13.7106 2.31241 13.4825 1.91498 13.085C1.51754 12.6876 1.28948 12.1499 1.16956 11.258C1.04762 10.351 1.04651 9.15921 1.04651 7.5C1.04651 5.84078 1.04762 4.649 1.16956 3.74203C1.28948 2.85012 1.51754 2.31241 1.91498 1.91498C2.31241 1.51754 2.85012 1.28948 3.74203 1.16956C4.649 1.04762 5.84078 1.04651 7.5 1.04651C7.78898 1.04651 8.02326 0.81224 8.02326 0.523256C8.02326 0.234272 7.78898 1.01293e-07 7.5 1.01293e-07Z"
                                fill="#07383D"
                              />
                              <path
                                d="M7.82758 6.43235C7.62323 6.6367 7.62323 6.96795 7.82758 7.1723C8.03193 7.37665 8.36318 7.37665 8.56753 7.1723L13.9534 1.78651V4.25145C13.9534 4.54044 14.1876 4.77471 14.4766 4.77471C14.7656 4.77471 14.9999 4.54044 14.9999 4.25145V0.523256C14.9999 0.234272 14.7656 0 14.4766 0H10.7484C10.4594 0 10.2251 0.234272 10.2251 0.523256C10.2251 0.81224 10.4594 1.04651 10.7484 1.04651H13.2133L7.82758 6.43235Z"
                                fill="#07383D"
                              />
                            </svg>
                          }
                        >
                          My Activity
                        </ListboxItem>
                        <ListboxItem
                          href={`/${category === "patient" ? 'patient' : 'physicians' }/profile`}
                          key="profile"
                          className={'menu-opener-1'}
                          startContent={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              className={'menu-opener-1'}
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.1047 0C4.27448 0 2.79077 1.48371 2.79077 3.31395C2.79077 5.1442 4.27448 6.62791 6.1047 6.62791C7.93498 6.62791 9.41866 5.1442 9.41866 3.31395C9.41866 1.48371 7.93498 0 6.1047 0ZM3.83728 3.31395C3.83728 2.06168 4.85245 1.04651 6.1047 1.04651C7.35703 1.04651 8.37215 2.06168 8.37215 3.31395C8.37215 4.56623 7.35703 5.5814 6.1047 5.5814C4.85245 5.5814 3.83728 4.56623 3.83728 3.31395Z"
                                fill="#07383D"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.10472 7.67432C4.49064 7.67432 3.00353 8.04122 1.90157 8.66104C0.816013 9.27171 8.9126e-05 10.1972 8.9126e-05 11.3371L4.7375e-05 11.4083C-0.000740997 12.2188 -0.00173183 13.2362 0.890615 13.9628C1.32978 14.3204 1.94414 14.5747 2.77419 14.7428C3.60656 14.9112 4.69141 14.9999 6.10472 14.9999C7.51807 14.9999 8.60295 14.9112 9.43528 14.7428C10.2654 14.5747 10.8797 14.3204 11.3189 13.9628C12.2112 13.2362 12.2102 12.2188 12.2094 11.4083L12.2094 11.3371C12.2094 10.1972 11.3935 9.27171 10.3079 8.66104C9.20595 8.04122 7.71886 7.67432 6.10472 7.67432ZM1.0466 11.3371C1.0466 10.7432 1.48012 10.0988 2.41464 9.57318C3.33274 9.05676 4.63633 8.72083 6.10472 8.72083C7.57318 8.72083 8.87672 9.05676 9.79486 9.57318C10.7294 10.0988 11.1629 10.7432 11.1629 11.3371C11.1629 12.2495 11.1347 12.7632 10.658 13.1513C10.3995 13.3619 9.96739 13.5674 9.22765 13.7171C8.49028 13.8663 7.48214 13.9534 6.10472 13.9534C4.72737 13.9534 3.71921 13.8663 2.98181 13.7171C2.24209 13.5674 1.80994 13.3619 1.55143 13.1513C1.07473 12.7632 1.0466 12.2495 1.0466 11.3371Z"
                                fill="#07383D"
                              />
                            </svg>
                          }
                        >
                          My Profile
                        </ListboxItem>
                        <ListboxItem
                          key="edit"
                          href="/profile/settings"
                          showDivider
                          className="pb-4 menu-opener-1"
                          startContent={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="17"
                              viewBox="0 0 18 17"
                              fill="none"
                              className={'menu-opener-1'}
                            >
                              <path
                                d="M1 9.19923V7.80077C1 6.9744 1.6754 6.29106 2.50971 6.29106C3.94791 6.29106 4.5359 5.27399 3.81283 4.02649C3.39964 3.31137 3.64596 2.3817 4.36904 1.96852L5.74367 1.18188C6.37139 0.808427 7.18186 1.03091 7.55532 1.65863L7.64272 1.8096C8.35785 3.0571 9.53383 3.0571 10.2569 1.8096L10.3443 1.65863C10.7178 1.03091 11.5282 0.808427 12.156 1.18188L13.5306 1.96852C14.2537 2.3817 14.5 3.31137 14.0868 4.02649C13.3637 5.27399 13.9517 6.29106 15.3899 6.29106C16.2163 6.29106 16.8996 6.96645 16.8996 7.80077V9.19923C16.8996 10.0256 16.2242 10.7089 15.3899 10.7089C13.9517 10.7089 13.3637 11.726 14.0868 12.9735C14.5 13.6966 14.2537 14.6183 13.5306 15.0315L12.156 15.8181C11.5282 16.1916 10.7178 15.9691 10.3443 15.3414L10.2569 15.1904C9.54178 13.9429 8.36579 13.9429 7.64272 15.1904L7.55532 15.3414C7.18186 15.9691 6.37139 16.1916 5.74367 15.8181L4.36904 15.0315C3.64596 14.6183 3.39964 13.6886 3.81283 12.9735C4.5359 11.726 3.94791 10.7089 2.50971 10.7089C1.6754 10.7089 1 10.0256 1 9.19923Z"
                                stroke="#07383D"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        >
                          Settings
                        </ListboxItem>
                        <ListboxItem
                          key="delete"
                          onClick={logoutHandler}
                          className="text-danger mt-2"
                          color="danger"
                          classNames={'menu-opener-1'}
                          startContent={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              className={'menu-opener-1'}
                              fill="none"
                            >
                              <path
                                d="M9.55413 3.65506e-07C8.59995 -1.3588e-05 7.83074 -2.75082e-05 7.22585 0.0812934C6.5978 0.165726 6.06895 0.346347 5.64897 0.766291C5.28268 1.13252 5.09761 1.58258 5.00046 2.11141C4.90606 2.62529 4.888 3.25416 4.8838 4.00872C4.88219 4.2977 5.11518 4.53327 5.4042 4.53488C5.69322 4.53649 5.92882 4.30352 5.93042 4.01454C5.93467 3.25164 5.95449 2.71088 6.02988 2.30048C6.10252 1.90503 6.2192 1.67614 6.38903 1.50629C6.58217 1.3132 6.85332 1.1873 7.36526 1.11848C7.89235 1.04762 8.59088 1.04651 9.59244 1.04651H10.2902C11.2918 1.04651 11.9903 1.04762 12.5174 1.11848C13.0294 1.1873 13.3005 1.3132 13.4936 1.50629C13.6867 1.69937 13.8126 1.97048 13.8815 2.48241C13.9523 3.0094 13.9534 3.70785 13.9534 4.7093V10.2907C13.9534 11.2921 13.9523 11.9906 13.8815 12.5176C13.8126 13.0296 13.6867 13.3006 13.4936 13.4937C13.3005 13.6868 13.0294 13.8127 12.5174 13.8816C11.9903 13.9524 11.2918 13.9535 10.2902 13.9535H9.59244C8.59088 13.9535 7.89235 13.9524 7.36526 13.8816C6.85332 13.8127 6.58217 13.6868 6.38903 13.4937C6.2192 13.3238 6.10252 13.095 6.02988 12.6996C5.95449 12.2891 5.93467 11.7483 5.93042 10.9854C5.92882 10.6965 5.69322 10.4635 5.4042 10.4651C5.11518 10.4667 4.88219 10.7023 4.8838 10.9913C4.888 11.7458 4.90606 12.3747 5.00046 12.8886C5.09761 13.4174 5.28268 13.8675 5.64897 14.2337C6.06895 14.6537 6.5978 14.8343 7.22585 14.9187C7.83074 15 8.59995 15 9.55413 15H10.3285C11.2828 15 12.0519 15 12.6569 14.9187C13.2849 14.8343 13.8137 14.6537 14.2337 14.2337C14.6537 13.8137 14.8344 13.285 14.9188 12.6571C15.0001 12.0522 15.0001 11.2831 15.0001 10.329V4.67102C15.0001 3.71689 15.0001 2.94783 14.9188 2.34297C14.8344 1.71498 14.6537 1.18624 14.2337 0.766291C13.8137 0.346347 13.2849 0.165726 12.6569 0.0812934C12.0519 -2.75082e-05 11.2828 -1.3588e-05 10.3285 3.65506e-07H9.55413Z"
                                fill="#07383D"
                              />
                              <path
                                d="M9.59302 6.97682C9.882 6.97682 10.1163 7.21109 10.1163 7.50007C10.1163 7.78905 9.882 8.02332 9.59302 8.02332H1.93775L3.30564 9.19583C3.52506 9.38386 3.55047 9.7142 3.3624 9.93362C3.17434 10.153 2.844 10.1784 2.62459 9.99034L0.182728 7.89732C0.0667465 7.79798 0 7.65279 0 7.50007C0 7.34735 0.0667465 7.20216 0.182728 7.10282L2.62459 5.00977C2.844 4.8217 3.17434 4.84711 3.3624 5.06653C3.55047 5.28594 3.52506 5.61627 3.30564 5.80434L1.93775 6.97682H9.59302Z"
                                fill="#07383D"
                              />
                            </svg>
                          }
                        >
                          Logout
                        </ListboxItem>
                      </Listbox>
                    </ListboxWrapper>
                  </motion.div>
                )}
              </AnimatePresence>
              <div
                className="w-full cursor-pointer flex items-center justify-between menu-opener"
                onClick={handleOpen}
              >
                <User
                  className="cursor-pointer menu-opener"
                  name={full_name?.slice(0,15)+"..."}
                  avatarProps={{
                    src: display_image,
                  }}
                />
                <svg
                  width="13"
                  height="8"
                  viewBox="0 0 13 8"
                  className={'menu-opener'}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6.5 6.5L12 1"
                    stroke="#07383D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </NavbarItem>
          )}
        </NavbarContent>

        <NavbarContent className="sm:hidden justify-between w-full">
          {!user_id && (
            <NavbarItem className="col-span-2 flex justify-end">
              <Button as={Link} color="primary" onClick={sessionHandler}>
                {isHome ? 'Log-in / Register' : isPhysicianRegistration ? 'Log-in as Patient' : isPatientRegistration ? 'Log-in as Physician' : 'Log-in'}
              </Button>
            </NavbarItem>
          )}
          {user_id && (
            <NavbarItem
              className="relative flex items-center justify-between gap-6"
            >
              <AnimatePresence mode="wait">
                {open && (
                  <motion.div
                    className="absolute w-full profile-card bg-white menu-opener-1 no-child-border"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div
                      className="w-full flex items-center cursor-pointer justify-between menu-opener-1"
                      onClick={handleClose}
                    >
                      <User
                        className="cursor-pointer menu-opener-1"
                        name={full_name?.slice(0,15)+"..."}
                        avatarProps={{
                          src: display_image,
                        }}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="9"
                        viewBox="0 0 13 9"
                        fill="none"
                        className={'menu-opener-1'}
                      >
                        <path
                          d="M12 7.5L6.5 2L1 7.5"
                          stroke="#07383D"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <Divider className="my-4 menu-opener-1" />
                    <ListboxWrapper className={'menu-opener-1'}>
                      <Listbox
                        variant="light"
                        className={'menu-opener-1'}
                        aria-label="Listbox menu with descriptions"
                      >
                        <ListboxItem
                          href="/dashboard"
                          key="activity"
                          className={'menu-opener-1'}
                          startContent={
                            <svg
                              width="15"
                              height="15"
                              className={'menu-opener-1'}
                              viewBox="0 0 15 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 1.01293e-07H7.45995C5.84946 -6.87545e-06 4.58734 -1.38789e-05 3.60259 0.132384C2.59463 0.2679 1.79927 0.550695 1.17498 1.17498C0.550695 1.79927 0.2679 2.59463 0.132384 3.60259C-1.38789e-05 4.58734 -6.87545e-06 5.84946 1.01293e-07 7.45995V7.54005C-6.87545e-06 9.15056 -1.38789e-05 10.4127 0.132384 11.3974C0.2679 12.4053 0.550695 13.2008 1.17498 13.825C1.79927 14.4493 2.59463 14.7321 3.60259 14.8677C4.58734 15 5.84946 15 7.45995 15H7.54005C9.15056 15 10.4127 15 11.3974 14.8677C12.4053 14.7321 13.2008 14.4493 13.825 13.825C14.4493 13.2008 14.7321 12.4053 14.8677 11.3974C15 10.4127 15 9.15056 15 7.54005V7.5C15 7.21102 14.7657 6.97674 14.4767 6.97674C14.1878 6.97674 13.9535 7.21102 13.9535 7.5C13.9535 9.15921 13.9524 10.351 13.8304 11.258C13.7106 12.1499 13.4825 12.6876 13.085 13.085C12.6876 13.4825 12.1499 13.7106 11.258 13.8304C10.351 13.9524 9.15921 13.9535 7.5 13.9535C5.84078 13.9535 4.649 13.9524 3.74203 13.8304C2.85012 13.7106 2.31241 13.4825 1.91498 13.085C1.51754 12.6876 1.28948 12.1499 1.16956 11.258C1.04762 10.351 1.04651 9.15921 1.04651 7.5C1.04651 5.84078 1.04762 4.649 1.16956 3.74203C1.28948 2.85012 1.51754 2.31241 1.91498 1.91498C2.31241 1.51754 2.85012 1.28948 3.74203 1.16956C4.649 1.04762 5.84078 1.04651 7.5 1.04651C7.78898 1.04651 8.02326 0.81224 8.02326 0.523256C8.02326 0.234272 7.78898 1.01293e-07 7.5 1.01293e-07Z"
                                fill="#07383D"
                              />
                              <path
                                d="M7.82758 6.43235C7.62323 6.6367 7.62323 6.96795 7.82758 7.1723C8.03193 7.37665 8.36318 7.37665 8.56753 7.1723L13.9534 1.78651V4.25145C13.9534 4.54044 14.1876 4.77471 14.4766 4.77471C14.7656 4.77471 14.9999 4.54044 14.9999 4.25145V0.523256C14.9999 0.234272 14.7656 0 14.4766 0H10.7484C10.4594 0 10.2251 0.234272 10.2251 0.523256C10.2251 0.81224 10.4594 1.04651 10.7484 1.04651H13.2133L7.82758 6.43235Z"
                                fill="#07383D"
                              />
                            </svg>
                          }
                        >
                          My Activity
                        </ListboxItem>
                        <ListboxItem
                          href={`/${category === "patient" ? 'patient' : 'physicians' }/profile`}
                          key="profile"
                          className={'menu-opener-1'}
                          startContent={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              className={'menu-opener-1'}
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.1047 0C4.27448 0 2.79077 1.48371 2.79077 3.31395C2.79077 5.1442 4.27448 6.62791 6.1047 6.62791C7.93498 6.62791 9.41866 5.1442 9.41866 3.31395C9.41866 1.48371 7.93498 0 6.1047 0ZM3.83728 3.31395C3.83728 2.06168 4.85245 1.04651 6.1047 1.04651C7.35703 1.04651 8.37215 2.06168 8.37215 3.31395C8.37215 4.56623 7.35703 5.5814 6.1047 5.5814C4.85245 5.5814 3.83728 4.56623 3.83728 3.31395Z"
                                fill="#07383D"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6.10472 7.67432C4.49064 7.67432 3.00353 8.04122 1.90157 8.66104C0.816013 9.27171 8.9126e-05 10.1972 8.9126e-05 11.3371L4.7375e-05 11.4083C-0.000740997 12.2188 -0.00173183 13.2362 0.890615 13.9628C1.32978 14.3204 1.94414 14.5747 2.77419 14.7428C3.60656 14.9112 4.69141 14.9999 6.10472 14.9999C7.51807 14.9999 8.60295 14.9112 9.43528 14.7428C10.2654 14.5747 10.8797 14.3204 11.3189 13.9628C12.2112 13.2362 12.2102 12.2188 12.2094 11.4083L12.2094 11.3371C12.2094 10.1972 11.3935 9.27171 10.3079 8.66104C9.20595 8.04122 7.71886 7.67432 6.10472 7.67432ZM1.0466 11.3371C1.0466 10.7432 1.48012 10.0988 2.41464 9.57318C3.33274 9.05676 4.63633 8.72083 6.10472 8.72083C7.57318 8.72083 8.87672 9.05676 9.79486 9.57318C10.7294 10.0988 11.1629 10.7432 11.1629 11.3371C11.1629 12.2495 11.1347 12.7632 10.658 13.1513C10.3995 13.3619 9.96739 13.5674 9.22765 13.7171C8.49028 13.8663 7.48214 13.9534 6.10472 13.9534C4.72737 13.9534 3.71921 13.8663 2.98181 13.7171C2.24209 13.5674 1.80994 13.3619 1.55143 13.1513C1.07473 12.7632 1.0466 12.2495 1.0466 11.3371Z"
                                fill="#07383D"
                              />
                            </svg>
                          }
                        >
                          My Profile
                        </ListboxItem>
                        <ListboxItem
                          key="edit"
                          href="/profile/settings"
                          showDivider
                          className="pb-4 menu-opener-1"
                          startContent={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="17"
                              viewBox="0 0 18 17"
                              fill="none"
                              className={'menu-opener-1'}
                            >
                              <path
                                d="M1 9.19923V7.80077C1 6.9744 1.6754 6.29106 2.50971 6.29106C3.94791 6.29106 4.5359 5.27399 3.81283 4.02649C3.39964 3.31137 3.64596 2.3817 4.36904 1.96852L5.74367 1.18188C6.37139 0.808427 7.18186 1.03091 7.55532 1.65863L7.64272 1.8096C8.35785 3.0571 9.53383 3.0571 10.2569 1.8096L10.3443 1.65863C10.7178 1.03091 11.5282 0.808427 12.156 1.18188L13.5306 1.96852C14.2537 2.3817 14.5 3.31137 14.0868 4.02649C13.3637 5.27399 13.9517 6.29106 15.3899 6.29106C16.2163 6.29106 16.8996 6.96645 16.8996 7.80077V9.19923C16.8996 10.0256 16.2242 10.7089 15.3899 10.7089C13.9517 10.7089 13.3637 11.726 14.0868 12.9735C14.5 13.6966 14.2537 14.6183 13.5306 15.0315L12.156 15.8181C11.5282 16.1916 10.7178 15.9691 10.3443 15.3414L10.2569 15.1904C9.54178 13.9429 8.36579 13.9429 7.64272 15.1904L7.55532 15.3414C7.18186 15.9691 6.37139 16.1916 5.74367 15.8181L4.36904 15.0315C3.64596 14.6183 3.39964 13.6886 3.81283 12.9735C4.5359 11.726 3.94791 10.7089 2.50971 10.7089C1.6754 10.7089 1 10.0256 1 9.19923Z"
                                stroke="#07383D"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          }
                        >
                          Settings
                        </ListboxItem>
                        <ListboxItem
                          key="delete"
                          onClick={logoutHandler}
                          className="text-danger mt-2"
                          color="danger"
                          classNames={'menu-opener-1'}
                          startContent={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              className={'menu-opener-1'}
                              fill="none"
                            >
                              <path
                                d="M9.55413 3.65506e-07C8.59995 -1.3588e-05 7.83074 -2.75082e-05 7.22585 0.0812934C6.5978 0.165726 6.06895 0.346347 5.64897 0.766291C5.28268 1.13252 5.09761 1.58258 5.00046 2.11141C4.90606 2.62529 4.888 3.25416 4.8838 4.00872C4.88219 4.2977 5.11518 4.53327 5.4042 4.53488C5.69322 4.53649 5.92882 4.30352 5.93042 4.01454C5.93467 3.25164 5.95449 2.71088 6.02988 2.30048C6.10252 1.90503 6.2192 1.67614 6.38903 1.50629C6.58217 1.3132 6.85332 1.1873 7.36526 1.11848C7.89235 1.04762 8.59088 1.04651 9.59244 1.04651H10.2902C11.2918 1.04651 11.9903 1.04762 12.5174 1.11848C13.0294 1.1873 13.3005 1.3132 13.4936 1.50629C13.6867 1.69937 13.8126 1.97048 13.8815 2.48241C13.9523 3.0094 13.9534 3.70785 13.9534 4.7093V10.2907C13.9534 11.2921 13.9523 11.9906 13.8815 12.5176C13.8126 13.0296 13.6867 13.3006 13.4936 13.4937C13.3005 13.6868 13.0294 13.8127 12.5174 13.8816C11.9903 13.9524 11.2918 13.9535 10.2902 13.9535H9.59244C8.59088 13.9535 7.89235 13.9524 7.36526 13.8816C6.85332 13.8127 6.58217 13.6868 6.38903 13.4937C6.2192 13.3238 6.10252 13.095 6.02988 12.6996C5.95449 12.2891 5.93467 11.7483 5.93042 10.9854C5.92882 10.6965 5.69322 10.4635 5.4042 10.4651C5.11518 10.4667 4.88219 10.7023 4.8838 10.9913C4.888 11.7458 4.90606 12.3747 5.00046 12.8886C5.09761 13.4174 5.28268 13.8675 5.64897 14.2337C6.06895 14.6537 6.5978 14.8343 7.22585 14.9187C7.83074 15 8.59995 15 9.55413 15H10.3285C11.2828 15 12.0519 15 12.6569 14.9187C13.2849 14.8343 13.8137 14.6537 14.2337 14.2337C14.6537 13.8137 14.8344 13.285 14.9188 12.6571C15.0001 12.0522 15.0001 11.2831 15.0001 10.329V4.67102C15.0001 3.71689 15.0001 2.94783 14.9188 2.34297C14.8344 1.71498 14.6537 1.18624 14.2337 0.766291C13.8137 0.346347 13.2849 0.165726 12.6569 0.0812934C12.0519 -2.75082e-05 11.2828 -1.3588e-05 10.3285 3.65506e-07H9.55413Z"
                                fill="#07383D"
                              />
                              <path
                                d="M9.59302 6.97682C9.882 6.97682 10.1163 7.21109 10.1163 7.50007C10.1163 7.78905 9.882 8.02332 9.59302 8.02332H1.93775L3.30564 9.19583C3.52506 9.38386 3.55047 9.7142 3.3624 9.93362C3.17434 10.153 2.844 10.1784 2.62459 9.99034L0.182728 7.89732C0.0667465 7.79798 0 7.65279 0 7.50007C0 7.34735 0.0667465 7.20216 0.182728 7.10282L2.62459 5.00977C2.844 4.8217 3.17434 4.84711 3.3624 5.06653C3.55047 5.28594 3.52506 5.61627 3.30564 5.80434L1.93775 6.97682H9.59302Z"
                                fill="#07383D"
                              />
                            </svg>
                          }
                        >
                          Logout
                        </ListboxItem>
                      </Listbox>
                    </ListboxWrapper>
                  </motion.div>
                )}
              </AnimatePresence>
              <div
                className="w-full cursor-pointer flex items-center justify-end profile-mobile-end menu-opener"
                onClick={handleOpen}
              >
                <User
                  className="cursor-pointer menu-opener"
                  name={full_name?.slice(0,15)+"..."}
                  avatarProps={{
                    src: display_image,
                  }}
                />
                <svg
                  width="13"
                  height="8"
                  viewBox="0 0 13 8"
                  className={'menu-opener'}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6.5 6.5L12 1"
                    stroke="#07383D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </NavbarItem>
          )}
        </NavbarContent>

        <NavbarMenu>
          {menuItems.map((item, index) => (
            <NavbarMenuItem key={`${item.path}-${index}`}>
              <Link
                className={['text-sm','w-full', 'text-[400]',activeLink === item.path ? 'active-link' : ''].join(" ")}
                href={item.path}
                size="lg"
              >
                {item.label}
              </Link>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </Navbar>
    </NextUIProvider>
  );
}
