import React from 'react';
import PropTypes from 'prop-types';
export default function PatientsIcon({ color }) {
    return (
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8.59756 0C6.67803 0 5.12195 1.55609 5.12195 3.47561C5.12195 5.39513 6.67803 6.95122 8.59756 6.95122C10.5171 6.95122 12.0732 5.39513 12.0732 3.47561C12.0732 1.55609 10.5171 0 8.59756 0ZM6.21951 3.47561C6.21951 2.16225 7.28422 1.09756 8.59756 1.09756C9.9109 1.09756 10.9756 2.16225 10.9756 3.47561C10.9756 4.78897 9.9109 5.85366 8.59756 5.85366C7.28422 5.85366 6.21951 4.78897 6.21951 3.47561Z" fill="#A9A9A9"/>
  <path d="M12.9877 1.46338C12.6847 1.46338 12.439 1.70908 12.439 2.01216C12.439 2.31524 12.6847 2.56094 12.9877 2.56094C13.9949 2.56094 14.6341 3.22367 14.6341 3.84143C14.6341 4.45919 13.9949 5.12192 12.9877 5.12192C12.6847 5.12192 12.439 5.36762 12.439 5.6707C12.439 5.97378 12.6847 6.21948 12.9877 6.21948C14.4052 6.21948 15.7316 5.24422 15.7316 3.84143C15.7316 2.43863 14.4052 1.46338 12.9877 1.46338Z" fill="#A9A9A9"/>
  <path d="M4.75606 2.01216C4.75606 1.70908 4.51036 1.46338 4.20728 1.46338C2.7898 1.46338 1.46338 2.43863 1.46338 3.84143C1.46338 5.24422 2.7898 6.21948 4.20728 6.21948C4.51036 6.21948 4.75606 5.97378 4.75606 5.6707C4.75606 5.36762 4.51036 5.12192 4.20728 5.12192C3.20009 5.12192 2.56094 4.45919 2.56094 3.84143C2.56094 3.22367 3.20009 2.56094 4.20728 2.56094C4.51036 2.56094 4.75606 2.31524 4.75606 2.01216Z" fill="#A9A9A9"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M8.59759 8.04883C7.29201 8.04883 6.08524 8.40063 5.18881 8.99822C4.29622 9.59332 3.65857 10.4756 3.65857 11.5244C3.65857 12.5733 4.29622 13.4556 5.18881 14.0507C6.08524 14.6482 7.29201 15 8.59759 15C9.90318 15 11.1099 14.6482 12.0064 14.0507C12.8989 13.4556 13.5366 12.5733 13.5366 11.5244C13.5366 10.4756 12.8989 9.59332 12.0064 8.99822C11.1099 8.40063 9.90318 8.04883 8.59759 8.04883ZM4.75613 11.5244C4.75613 10.9568 5.10127 10.3757 5.79763 9.91146C6.49016 9.44975 7.47852 9.14639 8.59759 9.14639C9.71667 9.14639 10.7051 9.44975 11.3975 9.91146C12.0939 10.3757 12.4391 10.9568 12.4391 11.5244C12.4391 12.0921 12.0939 12.6731 11.3975 13.1374C10.7051 13.5991 9.71667 13.9025 8.59759 13.9025C7.47852 13.9025 6.49016 13.5991 5.79763 13.1374C5.10127 12.6731 4.75613 12.0921 4.75613 11.5244Z" fill="#A9A9A9"/>
  <path d="M13.9152 9.21159C13.9801 8.91554 14.2728 8.72822 14.5688 8.79312C15.2726 8.94744 15.9068 9.22622 16.3777 9.61154C16.8482 9.99649 17.1951 10.5257 17.1951 11.1584C17.1951 11.7912 16.8482 12.3204 16.3777 12.7053C15.9068 13.0907 15.2726 13.3694 14.5688 13.5238C14.2728 13.5887 13.9801 13.4013 13.9152 13.1053C13.8503 12.8092 14.0376 12.5166 14.3337 12.4517C14.9135 12.3245 15.3768 12.1061 15.6826 11.8559C15.9889 11.6054 16.0976 11.3606 16.0976 11.1584C16.0976 10.9563 15.9889 10.7115 15.6826 10.461C15.3768 10.2107 14.9135 9.99239 14.3337 9.86522C14.0376 9.80032 13.8503 9.50764 13.9152 9.21159Z" fill="#A9A9A9"/>
  <path d="M2.62635 8.79312C2.9224 8.72822 3.21503 8.91554 3.27994 9.21159C3.34487 9.50764 3.1575 9.80032 2.86145 9.86522C2.28168 9.99239 1.81833 10.2107 1.51253 10.461C1.20631 10.7115 1.09756 10.9563 1.09756 11.1584C1.09756 11.3606 1.20631 11.6054 1.51253 11.8559C1.81833 12.1061 2.28168 12.3245 2.86145 12.4517C3.1575 12.5166 3.34487 12.8092 3.27994 13.1053C3.21503 13.4013 2.9224 13.5887 2.62635 13.5238C1.92253 13.3694 1.28832 13.0907 0.817456 12.7053C0.347011 12.3204 0 11.7912 0 11.1584C0 10.5257 0.347011 9.99649 0.817456 9.61154C1.28832 9.22622 1.92253 8.94744 2.62635 8.79312Z" stroke={color || "#A9A9A9"} />
</svg>
    )
}

PatientsIcon.propTypes = {
    color: PropTypes.string
}