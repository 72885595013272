import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { toast } from "react-toastify";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import UploadIcon from "../../../assets/icons/UploadIcon";
import BasicInfo from "./basic";
import ContactInfo from "./contact";
import { enabledTabs } from "../physician/validations";

const PatientRegistration = (props) => {
  const [value, setValue] = useState("one");
  const [displayImage, setDisplayImage] = useState(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [displayImageUrl, setDisplayImageUrl] = useState(null);
  const [disabledKeys, setDisabledKeys] = React.useState(true)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMoveToHome = () => {
    window.location.href = "/";
  };

  const moveNextTab = () => {
    const activeTab = enabledTabs(formik,value)
    if (activeTab === "one") {
      setDisabledKeys(false)
    } else {
      setDisabledKeys(true)
    }
    setValue(activeTab)
    // if (value === 0) {
    //   setValue(1);
    // }
  };

  const movePrevTab = () => {
    if (value === 'two') {
      setValue('one');
    }
  };

  useEffect(() => {
    formik.validateForm().then(() => {
      console.log("form validation loaded successfully!");
    });
  }, []);

  const fetchData = async (userData) => {
    try {
      const formData = new FormData();
      Object.keys(userData).forEach((key) => {
        formData.append(key, userData[key]);
      });
      formData.append('display_image', displayImage);

      const response = await axios.post("/patient", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      return response.data;
    } catch (error) {
      const msg = error?.response?.data?.errors?.[0];
      toast.error(msg || "Error while Sign-up!");
      return;
    }
  };

  const SignupSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "First name too short!")
      .max(50, "First name too long!")
      .required("First name required"),
    last_name: Yup.string()
      .min(2, "Last name too short!")
      .max(50, "Last name too long!")
      .required("Last name required"),
    email: Yup.string().email("Invalid email").required("Email required"),
    date_of_birth: Yup.string().required("Date of birth required"),
    gender: Yup.string()
      .required("Gender is required")
      .oneOf(["male", "female", "other"], "Invalid gender"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters long")
      .matches(/[a-z]/, "At least one lowercase letter is required in password")
      .matches(/[A-Z]/, "At least one uppercase letter is required in password")
      .matches(/\d/, "At least one number is required in password")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "At least one special character is required in password",
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .min(8, "Password must be at least 8 characters long in password")
      .matches(/[a-z]/, "At least one lowercase letter is required in password")
      .matches(/[A-Z]/, "At least one uppercase letter is required in password")
      .matches(/\d/, "At least one number is required in password")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "At least one special character is required in password",
      )
      .required("Password is required"),
    street_address: Yup.string()
      .min(6, "Street address too short!")
      .required("Street Address Required"),
    country: Yup.string().required("Country required"),
    zip_code: Yup.string(),
    city: Yup.string(),
    location_id: Yup.string(),
    contact_number: Yup.string().required("Contact Required"),
    records: Yup.mixed(),
  });

  const formik = useFormik({
    initialValues: {
      display_image: null,
      role: "regular",
      category: "patient",
      first_name: "",
      last_name: "",
      gender: "",
      email: "",
      password: "",
      date_of_birth: "",
      confirm_password: "",
      country: "",
      street_address: "",
      city: "",
      location_id: "",
      zip_code: "",
      contact_number: "",
      records: "",
    },
    validateOnMount: true,
    validationSchema: SignupSchema,
    onSubmit: async (data) => {
      if (!displayImage) {
        toast.error("Display Image is required!");
        return;
      }
      setIsSaveDisabled(true); // Disable the save button when clicked
      const response = await fetchData(data);
      if (response) {
        window.location.href = "/login";
        toast.success("Registration Completed!");
      }
    },
  });

  const imageConversion = (input) => {
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const blob = dataURLtoBlob(e.target.result);
        const blobUrl = URL.createObjectURL(blob);
        setDisplayImageUrl(blobUrl);
      };
      reader.readAsDataURL(input.files[0]);
      setDisplayImage(input.files?.[0]);
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  return (
    <div className="grid grid-cols-1 p-4 sm:grid-cols-2 gap-4">
      <div className="col-span-1 flex flex-col gap-2 justify-center items-center">
            {!displayImageUrl &&
              <div
                style={{
                  width: '285px',
                  height: '285px',
                  background: '#10c1d01a'
                }}
                onClick={() => {
                  const ele = document.getElementById("display_pic");
                  if (ele) {
                    ele.click();
                  }
                }}
                className="border cursor-pointer border-primary btn-round flex items-center justify-center"
              >
                <UploadIcon width="50" />
              </div>
            }
           {displayImageUrl &&
              <div className="relative">
                <img width={285} height={285} src={displayImageUrl} alt="Profile" />
                <div className="w-full flex justify-end mt-4">
                 <button
                    onClick={() => setDisplayImageUrl(null)}
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                  >
                    Reset Image
                  </button>
                </div>
              </div>
            }
            <input
              onInput={(event) => {
                const fileInput = event.target;
                imageConversion(fileInput);
              }}
              type="file"
              aria-label={"Display Pic"}
              id="display_pic"
              className="hidden"
              accept="image/png, image/gif, image/jpeg"
            />
            <div
              style={{
                fontSize: '14px',
                color: 'var(--secondary)',
                fontWeight: 400
              }}
            >
              Upload Your Profile Picture
            </div>
            <div
              style={{
                fontSize: '12px',
                color: 'var(--secondary)',
                fontWeight: 400
              }}
            >
              Maximum 2500 x 1800 px
            </div>
          </div>

      <div className="col-span-1 w-full">
        <div className="w-full">
          <div className="flex justify-center items-center px-8 md:px-40 w-full">
            <div className="relative w-full">
              <div className="back-line absolute bg-primary"></div>
              <Tabs
                sx={{
                  position: "relative",
                  width: '100%',
                  zIndex: 1,
                  ".MuiTabs-indicator": {
                    zIndex: 1,
                    top: 0,
                    width: 40,
                    height: 40,
                    background: "white",
                  },
                  ".MuiTabs-flexContainer": {
                    justifyContent: "space-between",
                    ".MuiButtonBase-root": {
                      fontSize: 14,
                      lineHeight: 21,
                      fontWeight: 500,
                      color: "#000000",
                      zIndex: 2,
                      width: 40,
                      height: 40,
                      background: "var(--primary-light)",
                      border: "2px solid var(--primary)",
                      minWidth: "auto",
                      minHeight: "auto",
                      '&[aria-selected="true"]': {
                        background: "transparent",
                      },
                    },
                  },
                }}
                value={value}
                onChange={handleChange}
                aria-label="icon tabs example"
              >
                <Tab aria-label="basic" label="1" value='one' />
                <Tab aria-label="contact" label="2" value='two' disabled={disabledKeys}/>
              </Tabs>
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="registration-form mt-6"
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={value ? value + "--" : "empty"}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                {value === "one" && <BasicInfo formik={formik} />}
                {value === "two" && <ContactInfo formik={formik} locations={props.locations} />}
              </motion.div>
            </AnimatePresence>
            <div className="flex justify-between mt-8 xl:mt-12">
                <button
                disabled={value === "two"}
                style={{
                  display: value === "one" ? "block" : "none",
                }}
                type="button"
                className="btn-round border border-gray-400 bg-primary-light text-gray-400 py-2 px-6 w-fit self-center hover:bg-primary hover:text-white"
                onClick={handleMoveToHome}
              >
                Cancel
              </button>
             <button
                disabled={value === "one"}
                style={{
                  display: value === "one" ? "none" : "block",
                }}
                type="button"
                className="btn-round border border-gray-400 bg-primary-light text-gray-400 py-2 px-6 w-fit self-center hover:bg-primary hover:text-white"
                onClick={movePrevTab}
                  >
                    Back
                  </button>
                  <button
                        style={{
                          display: value === "two" ? "none" : "block",
                        }}
                        type="button"
                        className="cursor-pointer btn-round bg-primary text-white py-2 px-6 w-fit self-center hover:bg-primary-light hover:text-gray-400"
                        onClick={moveNextTab}
                      >
                        Next
                  </button>
                    <input
                      style={{
                        display: value === "two" ? "block" : "none",
                      }}
                      type="submit"
                      className="cursor-pointer btn-round bg-primary text-white py-2 px-6 w-fit self-center hover:bg-primary-light hover:text-gray-400"
                      value="Save"
                    />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PatientRegistration