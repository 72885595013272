
import EyeSlashFilledIcon from '../assets/icons/EyeSlashFilledIcon';
import EyeFilledIcon from '../assets/icons/EyeFilledIcon';
import ImageBG from '../assets/physician-login-screen-image.png'
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Input } from '@nextui-org/react';
import axios from 'axios'
export default function Login(props) {
  const [email, setEmail] = useState('');
  const [disable, setDisable] = useState(false)
  const [password, setPassword] = useState('');
  const [redirectionPath, setRedirectionPath] = useState(null)

  const createAccountHandler = () => {
    window.location.href = (window.location.pathname === "/physician/login" ? "/registration/physician" : "/registration/patient")
  }

  const submitHandler = async (event) => {
    setDisable(true)
    event.preventDefault()
    if (!email) {
      toast.error('Email is required!');
      setDisable(false)
      return
    }
    if (!password) {
      toast.error('Password is required!');
      setDisable(false)
      return
    }
    const res = await fetchData({ email, password, as: props.as });
    setDisable(false)
    if (res) {
      toast.success('Login Successful!');
        window.location.href = redirectionPath || '/dashboard'
    }
  };
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  React.useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const path = urlParams.get('redirect_to');
    if(path) setRedirectionPath(path)
  }, [])
  return (
    <div className="grid grid-cols-12 h-full px-6">
      <div className="col-span-12 md:col-span-5 justify-start mt-8 md:12 xl:mt-20 p-8 sm:p-0 hidden md:flex">
        <img
          src={ImageBG}
          className="md:w-auto md:h-fit xl:h-fit xl:w-auto"
          alt="physician login screen image"
        />
      </div>
      <div className="col-span-12 md:col-span-7 grid grid-cols-6">
        <div
          className="col-span-12 md:col-start-2 md:col-span-4 lg:mt-8 md:12 xl:mt-20 p-6 max-h-41 flex flex-col justify-between">
          {props.error && (
            <div className={"w-full text-center bg-info py-2"}>{props.error}</div>
          )}
          <form onSubmit={submitHandler}>
            <h1 className="text-primary text-center text-4xl font-medium">
              Welcome Back
            </h1>
            <p className="text-sm text-secondary font-normal text-center">
              Login <span
              className={"text-primary font-semibold"}> {props.as === "physician" ? "as physician" : "as patient"} </span> to your
              account below
            </p>
            <div className="relative mb-3 mt-9 flex flex-col gap-5">
              <Input
                type="email"
                id="email"
                name="email"
                placeholder="Email Address *"
                variant="bordered"
                required
                value={email}
                onChange={handleEmailChange}
                className="w-full mt-4"
              />
              <Input
                id="password"
                name="password"
                variant="bordered"
                placeholder="Password *"
                endContent={
                  <button
                    className="focus:outline-none"
                    type="button"
                    onClick={toggleVisibility}
                  >
                    {isVisible ? (
                      <EyeSlashFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    ) : (
                      <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none" />
                    )}
                  </button>
                }
                type={isVisible ? "text" : "password"}
                className="w-full mt-4"
                onChange={handlePasswordChange}
              />
              <a href="/reset-password" className="text-secondary self-end">
                Forgot Password?
              </a>
              <div className="flex justify-between">
                <a href="/">
                  <button
                    type="button"
                    className="btn-round bg-primary-light text-secondary border-secondary border py-3.5 px-8 w-fit self-center"
                  >
                    Cancel
                  </button>
                </a>
                <button
                  type="submit"
                  disabled={disable}
                  className={`btn-round bg-primary text-white py-3.5 px-8 w-fit self-center ${disable && 'opacity-50'}`}
                >
                  Log-In
                </button>
              </div>
            </div>
          </form>
          <div className="relative w-full flex flex-col items-center mt-12 sm:mt-0">
            <p className="mb-2">No Account? Start Here</p>
            <a href="#" onClick={createAccountHandler}>
              <button type="button" className="max-w-xs w-80 border-secondary border btn-round py-3.5 px-8">
                Create Account
              </button>
            </a>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

const fetchData = async (formData) => {
    try {
      const response = await axios.post('/login',formData);

      if (response.status !== 202) {
        throw new Error(response.status === 401 ? 'Invalid Credentials' : 'Server Error!');
      }

      return response
      // Handle the data as needed
    } catch (error) {
      const msg = error?.response?.data?.message
      toast.error(msg || 'Invalid Credentials!');
      return
    }
};
