import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { draftReport, publishReport } from "../../utils/axios";
import { toast } from "react-toastify"

export default function ReviewRecord({ app_id, url, html, payload, document }) {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const log = async () => {
    setLoading(true);
    const html_ = editorRef.current.getContent()
    if (!html_) {
      toast.warning("Empty Report can't be saved!")
      setLoading(false);
      return
    }
    if (editorRef.current) {
      try {
        await publishReport({ app_id, html: html_ })
        toast.success("Report has been published!")
        setLoading(false);
        window.location.reload()
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
        setLoading(false);
      }
    }
  };

  const draft = async () => {
    const html_ = editorRef.current.getContent()
    if (!html_) {
      toast.warning("Empty Report can't be saved!")
      return
    }
    const res = await draftReport({ app_id, html: html_ })
    if (res) {
      toast.success("Draft has been saved!")
      window.location.href = "/dashboard" + "?tab=" + (payload?.category === "review" ? "reviews" : "appointments")
    } else {
      toast.error("Error while saving draft!")
    }
  }
  return (
    <>
      <div className={'w-full flex justify-start items-center'}>
        <a href={"/dashboard" + "?tab=" + (payload?.category === "review" ? "reviews" : "appointments")} className={'underline'}>
          Back
        </a>
      </div>
      <br />
      <div className={'grid grid-cols-2'}>
        <div className={'col-span-1'}>
          <h1 className={"text-secondary text-3xl font-semibold"}>Report {url ? "View" : "Generation"}</h1>
          <p className={"text-secondary text-sm font-semibold"}>Appointment for the date: <strong className={'text-primary'}>{payload.app_date}</strong> </p>
        </div>
        {
          url ? (
            <></>
          ) : (
            <>
              <div className={"col-span-1 flex justify-end items-center"}>
                <a href={document} target={'_blank'} className={'text-secondary border border-secondary btn-round px-4 py-2'}>View Attached Document</a>
              </div>
            </>
          )
        }
      </div>
      <br />
      {url ? (
        <>
          <iframe src={url} className={"w-full min-h-[800px]"} />
        </>
      ) : (
        <>
          <Editor
            apiKey="1hkanixt6efvs6mji6zpktcsswciyfw888lsamyomvo81lxb"
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={html}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "preview",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "code",
                "help",
                "wordcount",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <div className={'flex justify-end mt-4'}>
            <div className={'flex gap-4'}>
              <button disabled={loading} onClick={draft} className={'btn-round border border-secondary text-secondary px-4 py-2'}>Save as Draft</button>
              <button disabled={loading} onClick={log} style={{cursor: loading ? 'progress' : 'pointer'}} className={'btn-round bg-primary text-white px-4 py-2'}>Publish Report</button>
            </div>
          </div>
        </>
      )}
    </>
  );
}