import axios from "axios"

const options = {
  headers: {
    'Content-Type': 'application/json',
  }
}

const multiPartOptions = {
  headers: {
    'Content-Type': 'multipart/form-data',
  }
}

export const getAllSlots = async () => await axios.get('/slots').then(res=> res.data).catch((error)=> console.error(error))
export const getAvailableSlots = async (start_date, end_date, user_id) => await axios.get(`/slots/available?start_date=${start_date}&end_date=${end_date}${user_id ? ('&user_id='+user_id) : ''}`).then(res=> res.data).catch((error)=> console.error(error))
export const setAvailableSlots = async (start_date, end_date, slots) => await axios.post(`/slots?start_date=${start_date}&end_date=${end_date}`, slots, options).then(res=> res.data).catch((error)=> console.error(error))
export const getBookedSlots = async (start_date, end_date) => await axios.get(`/slots/booked?start_date=${start_date}&end_date=${end_date}`).then(res=> res.data).catch((error)=> console.error(error))
export const getAppointments = async (dates) => await axios.get(`/appointments?dates=${dates}`).then(res=> res.data).catch((error)=> console.error(error))
export const getMyRecords = async () => await axios.get('/my-records').then(res=> res.data).catch((error)=> console.error(error))
export const uploadMyRecord = async ({ name, file }) => await axios.post(`/records`, {record: { name, file }}, multiPartOptions).then(res=> res.data).catch((error)=> console.error(error))
export const createReview = async (data) => await axios.post(`/reviews`, {review: data}, multiPartOptions).then(res=> res.data).catch((error)=> console.error(error))
export const getMyData = async () => await axios.get('/profile/me').then(res=> res.data).catch((error)=> console.error(error))
export const getMyPaymentMethods = async () => await axios.get('/my-payment-methods').then(res=> res.data).catch((error)=> console.error(error))
export const createAppointment = async (data) => await axios.post('/appointments', {appointment: data}, multiPartOptions).then(res=> res.data).catch((error)=> console.error(error))
export const getUpcoming3Appointments = async () => await axios.get('/appointments/patient/upcoming').then(res=> res.data).catch((error)=> console.error(error))
export const patientAppointments = async (page, doctorId, category, limit, sortBy, sortType) => await axios.get(`/appointments/patient_appointments?page=${page || 1}&limit=${limit || 10}${doctorId ? '&doctor_id='+doctorId : ''}${category ? '&category='+category : "" }${sortBy ? '&sort_by='+sortBy : ''}${sortType ? '&sort_type='+sortType : ''}`).then(res=> res.data).catch((error)=> console.error(error))
export const uniqueDoctors = async (page) => await axios.get(`/appointments/get_unique_doctors?page=${page || 1}&limit=15`).then(res=> res.data).catch((error)=> console.error(error))
export const updateUserData = async (data) => await axios.put('/users', data, multiPartOptions)
export const updatePhysicianData = async (data) => await axios.put('/users/physician', data, multiPartOptions).then(res=> res.data).catch((error)=> console.error(error))
export const createUser = async (data) => await axios.post('/users', data, multiPartOptions).then(res=> res.data).catch((error)=> console.error(error))
export const deactivateAccount = async () => await axios.delete('/users').then(res=> res.data).catch((error)=> console.error(error))
export const cancelAppointment = async (id) => await axios.delete('/appointments/'+id).then(res=> res.data).catch((error)=> console.error(error))
export const deleteCertification = async (id) => await axios.delete('/board_certifications/'+id).then(res=> res.data).catch((error)=> console.error(error))
export const deleteDocument = async (id) => await axios.delete('/documents/'+id).then(res=> res.data).catch((error)=> console.error(error))
export const deleteRecord = async (id) => await axios.delete('/records/'+id).then(res=> res.data)
export const updatePassword = async (id, data) => await axios.put(`/password-update/${id}`,data).then(res => res.data).catch((error) => console.error(error));
export const sendResetPassEmail = async () => await axios.post(`/password-reset`).then(res => res.data).catch((error) => console.error(error));
export const getRecordById = async (id) => await axios.get(`/records/${id}`).then(res=> res.data).catch((error)=> console.error(error))
export const updateAppointment = async (id, data) => await axios.put('/appointments/'+id, { appointment: data }, options);
export const uniquePatients = async (page) => await axios.get(`/appointments/get_unique_patients?page=${page || 1}&limit=15`).then(res=> res.data).catch((error)=> console.error(error))
export const publishReport = async (data) => await axios.post(`/documents/generate-pdf`, data);
export const draftReport = async (data) => await axios.post(`/documents/make-draft`, data).then(res => res.data).catch((error) => console.error(error));
export const specialities = async (page) => await axios.get(`/specialities`).then(res=> res.data).catch((error)=> console.error(error))
export const addPaymentInfo = async (data) => await axios.post(`/payment_methods`, data).then(res => res.data).catch((error) => console.error(error));
export const getPaymentInfo = async () => await axios.get(`/payment_methods`).then(res => res.data).catch((error) => console.error(error));
export const makeDefaultPaymentInfo = async (id) => await axios.patch(`/payment_methods/${id}/make_default`).then(res => res.data).catch((error) => console.error(error));
export const addPhysicianPaymentInfo = async () => await axios.post(`/payment_methods/add_doctor_payment`).then(res => res.data).catch((error) => console.error(error));
export const getPhysicianPaymentInfo = async () => await axios.get(`/payment_methods/doctor`).then(res => res.data).catch((error) => console.error(error));
export const getAvailableNotSlots = async (start_date, end_date, user_id) => await axios.get(`/slots/not_available?start_date=${start_date}&end_date=${end_date}${user_id ? ('&user_id='+user_id) : ''}`).then(res=> res.data).catch((error)=> console.error(error))
export const setAvailableNotSlots = async (start_date, end_date, slots) => await axios.post(`/slots?start_date=${start_date}&end_date=${end_date}`, slots, options).then(res=> res.data).catch((error)=> console.error(error))
export const getAppointmentsNotBooked = async (dates) => await axios.get(`/appointments_not_booked?dates=${dates}`).then(res=> res.data).catch((error)=> console.error(error))
export const GetReview = async (doctors_id) => 
  await axios.get(`/reviews/${doctors_id}`)
    .then(res => res.data)
    .catch(error => console.error(error));
export const deletePaymentMethod = async (data) => await axios.delete(`/payment_methods/:id?card_id=${data.id}`).then(res => res.data).catch((error) => console.log(error))