import React from "react";
import WeekDates from "../../components/Grid";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ScheduleMeeting from '../../components/ScheduleMeeting.jsx';
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { useSpring, animated } from "@react-spring/web";
import { Calendar } from 'primereact/calendar';

export const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  maxWidth: '60rem',
  minHeight: "37.5rem",
  height: 'fit-content',
  flexShrink: 0,
  borderRadius: "0.625rem",
  padding: '0.88rem 1.12rem'
};



const d = [
  {
    id: 1,

    physician: {
      id: 1,
      name: "Dr Obaid"
    },
    category: 'video',
    status: "check",
    date: "2024-01-31",
    slot: {
      id: 13,
      name: "9:30 am"
    },
  },
  {
    id: 1,
    physician: {
      id: 1,
      name: "Dr Obaid"
    },
    status: "draft",
    category: 'video',
    date: "2024-02-05",
    slot: {
      id: 13,
      name: "9:30 am"
    },
    patient: {
      id: 1,
      name: "Saad Mehmood",
      age: 29,
      gender: "male"
    },
    fee: 500,
    record: {
      title: "Saad-Mahmood-Reports.documents",
      link: "/Saad-Mahmood-Reports.documents"
    }
  },
  {
    id: 10,
    physician: {
      id: 1,
      name: "Dr. Ahmad Raza"
    },
    status: "check",
    category: 'review',
    date: "2024-02-04",
    slot: {
      id: 13,
      name: "9:30 am"
    },
    patient: {
      id: 1,
      name: "Saad Mehmood",
      age: 29,
      gender: "male"
    },
    fee: 500,
    record: {
      title: "Saad-Mahmood-Reports.documents",
      link: "/Saad-Mahmood-Reports.documents"
    }
  },
  {
    id: 1,

    physician: {
      id: 1,
      name: "Saad"
    },
    status: "cross",
    category: 'review',
    date: "2024-01-29",
    slot: {
      id: 13,
      name: "9:30 am"
    },
  },
];

const initialValue = dayjs();

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(props.day.format("YYYY-MM-DD")) >= 0;

  const isSameDate = (dt = new Date()) => {
    return props.disableddates?.some((disabledDate) =>
      dayjs(dt).isSame(dayjs(disabledDate), "day"),
    );
  };
  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? <div className="w-2 h-2 rounded bg-primary"></div> : undefined}
    >
      <PickersDay
        {...other}
        disabled={isSameDate(day)}
        style={{
          fontSize: "15px",
          lineHeight: "18px",
          fontWeight: 500,
        }}
        className={`!text-secondary ${other.selected && "selected-date"}`}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </Badge>
  );
}

export default function CalenderView() {
  const [dates, setDates] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(dayjs())

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handle2Open = () => setOpen2(true);
  const handle2Close = () => setOpen2(false);

  const onDateSelection = (event) => {
    setSelectedDate(dayjs(event.value))
    setDates(event.value)
  }

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      <div className="w-full flex flex-wrap justify-between pr-4">
        <div className="mt-2 heading-welcome">Welcome Back!</div>
        <div className={'flex gap-2'}>
          <button
            className="btn-round bg-white border border-primary text-primary py-2.5 px-4 w-fit self-center mt-4 mr-8"
            onClick={handle2Open}>Calendar
          </button>
          <button className="btn-round bg-primary text-white py-2.5 px-8 w-fit self-center mt-4 mr-8"
            onClick={handleOpen}>Manage Slots
          </button>
        </div>
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open2}
        onClose={handle2Close}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade
          }
        }}
      >
        <Fade in={open2}>
          <Box sx={{ ...style, maxWidth: '40rem' }}>
            <div className={'bg-white btn-round'}>
              <Calendar inline value={dates} onChange={onDateSelection} />
              <div className={'w-full flex p-4 justify-end'}>
                <button className="btn-round bg-white text-secondary border border-secondary py-2.5 px-4 w-fit self-center" onClick={handle2Close}>Close</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <div className="gap-2 grid grid-cols-12">
        <div className="col-span-12">
          <WeekDates selectedDate={selectedDate} />
        </div>
      </div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <ScheduleMeeting handleClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
